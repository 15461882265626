import { getAuth } from "../common/auth";
import topbar from "./image/top-bar.png";
import { Link } from "react-router-dom";
import logo from "./image/logo.png";
import axios from "axios";
import { useState } from "react";
import { ShowCategoryDesign } from "./HomeComponent/showCategoryDesign";

const white_logo = `${process.env.REACT_APP_URL_FRONT_TEST}/image/footer/logo-white.png`

const Footer = ({hideGreetingsSection,bannerThemeResponse})=>{
	const [subscribeBtn,setSubscribeBtn] = useState({
		text: "Subscribe",
		disabled: false
	});
	const [formData,setformData] = useState({
		email:""
	});
	const [formDataError,setformDataError] = useState({
		email:""
	});
	const [responseStatus,setResponseStatus] = useState(false);

	const handleSubmit = async (e)=>{
		e.preventDefault();
		setSubscribeBtn({
			text: "Please wait...",
			disabled: true
		});
		if(formData.email === ""){
			setformDataError({
				email: "Email is required!"
			});
			setTimeout(()=>{
				setformDataError({
					email: ""
				});
			},3000);
			return;
		}

		try{
			const response = await axios.post(process.env.REACT_APP_NEWS_LETTER,formData);
			if(response.data.status === 1){
				setResponseStatus(true);
			}else{
				setformDataError({
					email: response.data.message
				});
			}
		}catch(error){
			setformDataError({
				email: error.response.data.message
			});
		}finally{
			setSubscribeBtn({
				text: "Subscribe",
				disabled: false
			});
			setTimeout(()=>{
				setformDataError({
					email: ""
				});
			},3000);
		}
		
	}
	const handleFormData = (e)=>{
		const el = e.target;
		const name = el.name;
		const value = el.value;
		return setformData((oldData)=>({...oldData,[name]:value}));
	}
	const design = (
		<>
			{
				!hideGreetingsSection &&
				<ShowCategoryDesign bannerThemeResponse={bannerThemeResponse} />
			}
			<footer className="main_footer" style={{background:bannerThemeResponse?.footer_banner_color ? bannerThemeResponse?.footer_banner_color : "#02a3df"}}>
				<div className="cstm-container">
					<div className="inner_footer_content">
						<div className="upper_footers">
						 <div className="footer_top-left">
							<img src={white_logo} />
							{/* <p>Discover our card samples, then design your own. Personalize your message with our diverse templates. Start creating today!</p> */}
						</div>	
					    <div className="footer_top-center">
							<p style={{fontSize:"20px",marginBottom:"10px",color:"#fff"}}>Subscribe to Varnz for Regular Updates</p>
							{
								!responseStatus ? (
									<form className="footer_top-subscribe" onSubmit={handleSubmit}>
						       <input name="email" value={formData.email}  placeholder="example@mail.com*" type="email" required="required"  onChange={handleFormData} />
							   <p style={{color:"red"}}>{formDataError.email}</p>
							   <button className="submit-subscribe cstm-btn" type="submit" disabled={subscribeBtn.disabled}>{subscribeBtn.text}</button>
							</form>
								):(
								<h3>Thanks for signing up!</h3>
								)
							}
							
						</div>
						<div className="footer_socials">
							<ul>
								<li><a href="https://www.facebook.com/myvarnz" target="_blank" aria-label="click for facebook"><i class="fa-brands fa-facebook-f"></i></a></li>
								<li><a href="https://www.instagram.com/varnz.ai/" target="_blank" aria-label="click for instagranm"><i class="fa-brands fa-instagram"></i> </a></li>
								<li><a href="https://twitter.com/myvarnz" target="_blank" aria-label="click for twitter"><i class="fa-brands fa-twitter"></i> </a></li>
							</ul>
						</div>
						</div>
					</div>
				</div>
				<div className="bottom_footers">
				   <div className="cstm-container">
					<div className="copyrights">
						<p>Varnz.ai (c) 2024 | All rights reserved. 
							{/* By <a href="https://webgarh.com/" target="_blank" aria-label="click for webgarh solution">WebGarh Solutions</a> */}
							</p>
					</div>
					</div>
				</div>
			</footer>

		</>
	);
	return design;
}

export default Footer;