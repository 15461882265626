import axios from "axios";
import html2canvas from 'html2canvas';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import edit from "../../image/editt.png";
import download from "../../image/download_icon.png";
import share from "../../image/share.png";
import { useEffect, useState } from "react";
import domtoimage from 'dom-to-image';
import { Modal } from "react-bootstrap";



export const shareImageCreate = async (e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard) => {
    try {
        setSharePlease({
            text: "Please wait..",
            disabled: true
        });

        if (setDownloadCard) {
            setDownloadCard({
                download: false,
                share: true
            })
        }
        const elementToCapture = captureDivRef1.current;
        const canvas = await html2canvas(elementToCapture, { scale: 3,useCORS:true });
        const imgDataUrl = canvas.toDataURL('image/png');
        const blob = await fetch(imgDataUrl).then((res) => res.blob());
        const formData = new FormData();
        formData.append('title', showMessage.heading);
        formData.append('message', showMessage.message);
        formData.append('type', showTabs.type);
        formData.append('category', showTabs.category);
        formData.append('occasion', showTabs.occasion);
        formData.append('tabs', clickedTabs.tabs);
        formData.append('image', blob, 'image.png');
        const url = process.env.REACT_APP_SHARE_IMAGE_URL;
        const response = await axios.post(url, formData);
        setSharePlease({
            text: "Share",
            disabled: false
        });
        if (setDownloadCard) {
            setDownloadCard({
                download: false,
                share: false
            })
        }
        // Navigate(`/share?image=${response.data.data._id}`);
        const newTab = window.open(`/share?image=${response.data.data._id}`, '_blank');
        if (newTab) {
            newTab.focus(); // Focus on the new tab if it was successfully opened
        }
        return;
    } catch (err) {
        console.log(err);
    }
}

export const shareImageCreate1 = async (e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs) => {
    try {
        setSharePlease({
            text: "Please wait..",
            disabled: true
        });
        const elementToCapture = captureDivRef1.current;
        // const originalHeight = elementToCapture.offsetHeight;
        // const originalWidth = elementToCapture.offsetWidth;
        // elementToCapture.style.height = `${originalHeight}px`;
        // elementToCapture.style.width = `${originalWidth}px`;
        const canvas = await html2canvas(elementToCapture, { scale: 3 });
        const imgDataUrl = canvas.toDataURL('image/png');
        const blob = await fetch(imgDataUrl).then((res) => res.blob());
        const formData = new FormData();
        formData.append('title', showMessage.heading);
        formData.append('message', showMessage.message);
        formData.append('type', showTabs.type);
        formData.append('category', showTabs.category);
        formData.append('occasion', showTabs.occasion);
        formData.append('tabs', clickedTabs.tabs);
        formData.append('is_saved', 1);
        formData.append('image', blob, 'image.png');
        const url = process.env.REACT_APP_SHARE_IMAGE_URL;
        const response = await axios.post(url, formData);
        setSharePlease({
            text: "Share",
            disabled: false
        });
        // Navigate(`/share?image=${response.data.data._id}`);
        const newTab = window.open(`/share?image=${response.data.data._id}`, '_blank');
        if (newTab) {
            newTab.focus(); // Focus on the new tab if it was successfully opened
        }
        return;
    } catch (err) {
        console.log(err);
    }
}

// export const handleDownloadClicks = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom) => {
//     if (downloadText.disabled) {
//         return;
//     }

//     setDownloadText({
//         text: "Please wait..",
//         disabled: true
//     })

//     setTimeout(() => {
//         let elementToCapture = "";
//         elementToCapture = captureDivRef1.current;
//         const originalHeight = elementToCapture.offsetHeight;
//         const originalWidth = elementToCapture.offsetWidth;
//         const node = captureDivRef1.current;

//         const devicePixelRatio = window.devicePixelRatio || 1; // Get the device's pixel ratio
//         const scale = devicePixelRatio; // Set the scale based on the device's pixel ratio


//         if (is_dom) {
//             domtoimage.toPng(node,{ quality:1.0, width: elementToCapture.clientWidth * scale,
//                 height: elementToCapture.clientHeight * scale,
//                 style: { transform: 'scale('+scale+')', transformOrigin: 'top left'}})
//                 .then((dataUrl) => {
//                     const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
//                     let filename = "";
//                     if (title === "Others")
//                         filename = `varnz_message_${random4DigitNumber}.png`;
//                     else
//                         filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

//                     // Create a link element to trigger the download
//                     const a = document.createElement('a');
//                     a.href = dataUrl;
//                     a.download = filename;

//                     // Trigger the download
//                     document.body.appendChild(a);
//                     a.click();

//                     // Cleanup: remove the link element from the DOM
//                     document.body.removeChild(a);

//                     alert.success("Downloaded Successfully!", {
//                         timeout: 3000
//                     });

//                     // Reset the download button state if needed
//                     setDownloadText({
//                         text: "Download",
//                         disabled: false
//                     });

//                     // Perform any additional actions or data updates
//                     sendData("download-successfully");
//                 })
//                 .catch((error) => {
//                     console.error('Error capturing element:', error);
//                 });
//         }
//     }, 100)
// };


function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    try {
        const item = JSON.parse(itemStr);
        const now = new Date();

        // Compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item has expired, remove it from storage and return null
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    } catch (e) {
        // If JSON parsing fails, remove the corrupted item and return null
        localStorage.removeItem(key);
        console.error("Failed to parse item from localStorage:", e);
        return null;
    }
}
export const handleDownloadClick = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard, downloadSize, setShowInstagramPost, setIsDownload) => {
    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }
    setDownloadText({
        text: "Please wait..",
        disabled: true
    });
    // console.log('downloadText:', downloadText);
    // const [showPopup] = useState(isDownload);

    // setIsDownload(false);
    // const originalWidthStyle1 = captureDivRef1.current.offsetWidth;
    // const originalHeightStyle1 = captureDivRef1.current.offsetHeight;

    // console.log(originalWidthStyle1, originalHeightStyle1, "kjkjkjk")
    // const el = document.getElementById("sizeChange");
    if (downloadSize === "1") {
        setShowInstagramPost(true)
        // el.classList.add("insta-post")
    }


    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }

    // console.log("download check", downloadSize)
    setTimeout(() => {
        let elementToCapture = "";
        let recall = false;
        elementToCapture = captureDivRef1.current;

        // const originalWidthStyle = elementToCapture.offsetWidth;
        // const originalHeightStyle = elementToCapture.offsetHeight;

        elementToCapture.classList.add('instagram-size-1080');



        let originalImage;
        if (captureDivRef1.current.querySelector("div#image-div")) {
            // console.log("test22222",downloadedImage)
            originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
        }
        if (captureDivRef1.current.querySelector("img#image-div")) {
            // console.log("test33333333",downloadedImage)

            originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
        }

        if (downloadedImage) {

            if (captureDivRef1.current.querySelector("div#image-div")) {
                const divImage = elementToCapture.querySelector("div#image-div");
                divImage.style.backgroundImage = `url("${downloadedImage}")`;

                // // Execute a function after the image has finished loading
                // divImage.onload = function() {
                //     // Run your function here
                if (!recall) {
                    abcd();
                }
                // };
            }
            if (captureDivRef1.current.querySelector("img#image-div")) {
                const imgElement = elementToCapture.querySelector("img#image-div");
                imgElement.src = downloadedImage;
                // Execute a function after the image has finished loading
                imgElement.onload = function () {
                    // Run your function here
                    if (!recall) {
                        abcd();
                    }
                };

            }
        } else {
            abcd()
        }

        function abcd() {
            let width, height, owidth, oheight;
            owidth = elementToCapture.offsetWidth;
            oheight = elementToCapture.offsetHeight;
            // const originalWidthStyle = mainImageElement.style.width;
            // const originalHeightStyle = mainImageElement.style.minheight;
            // console.log(originalWidthStyle,originalHeightStyle,"originalHeightStyle")
            // mainImageElement.style.width = `${desiredWidth}px`;
            // mainImageElement.style.minheight = `${desiredMinHeight}px`;

            const paragraphElement = captureDivRef1.current.querySelector('#template-message');
            console.log(paragraphElement);
            
            let originalSize = "";


            // <option value="0">same size</option>
            // <option value="1">1080x1080</option>
            // <option value="2">1080x566</option>
            // <option value="3">1080x1350</option>


            if (downloadSize === '0') {
                width = elementToCapture.offsetWidth; // Use the current width of the element
                height = elementToCapture.offsetHeight; // Use the current height of the element
            }
            else if (downloadSize === '11') {
                width = 1080;
                height = 1080;
                if (paragraphElement) {
                    let originalSize = paragraphElement.style.fontSize;
                    if (originalSize === "") {
                        originalSize = "20px";
                    }
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                    // console.log(size2,"paragraphElement",paragraphElement,originalSize)

                }
            }
            else if (downloadSize === '2') {
                width = 1080;
                height = 566;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`;
                    // console.log(size2,"paragraphElement")

                }
            }
            else if (downloadSize === '3') {
                width = 1080;
                height = 1350;
                if (paragraphElement) {
                    const originalSize = paragraphElement.style.fontSize;
                    const size2 = (parseInt(originalSize) * 2)
                    paragraphElement.style.fontSize = `${size2}px`
                    // console.log(size2,"paragraphElement")

                }
            }

            // elementToCapture.style.width = `${width}px`;
            // elementToCapture.style.height = `${height}px`;
            // console.log("test11111111",downloadedImage,scale)
            // Calculate the scale factor to fit the element into the desired size
            // const scaleFactor = Math.min(width / elementToCapture.offsetWidth, height / elementToCapture.offsetHeight);
            html2canvas(elementToCapture, {
                // width: width,
                // height: height,
                scale: downloadSize === '0' ? scale : 1,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent",
                onclone: function (clonedDoc) {
                    const clonedElement = clonedDoc.getElementsByClassName('instagram-size-1080')[0];
                    const paragraphElement = clonedElement.querySelector('#template-message');
                    // console.log(paragraphElement, 'paragraphElement');
                    
                    if (downloadSize === "1") {
                        clonedElement.classList.add('insta-post');

                        if (paragraphElement) {
                            let originalSize = paragraphElement.style.fontSize;
                            if (originalSize === "") {
                                originalSize = "20px";
                            }
                            const size2 = (parseInt(originalSize) * 2)
                            paragraphElement.style.fontSize = `${size2}px`
                        }
                        clonedElement.style.width = `1080px`;
                        clonedElement.style.height = `1080px`;
                    }
                }
            }).then((canvas) => {

                let sessionData;
                if (window.location.pathname === "/") {
                    sessionData = JSON.parse(sessionStorage.getItem("formData"));
                } else {
                    sessionData = JSON.parse(sessionStorage.getItem("ai_cardData"));
                }


                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = "";

                // const formDataString = localStorage.getItem("formData");
                // const formData = JSON.parse(formDataString);
                // const occasion = formData.occasion;
                // const formattedOccasion = occasion.replace(/_/g, '-');
                // const formattedOccasion = (JSON.parse(sessionStorage.getItem("formData"))?.occasion || "").replace(/_/g, '-');
                const formattedOccasion = (sessionData?.occasion || "").replace(/_/g, '-');

                // ai pages data
                // const ab = (JSON.parse(sessionStorage.getItem("ai_cardData"))?.occasion || "").replace(/_/g, '-');
                // console.log(ab, 'ab');
                
                    
                if (title === "Others")
                    // filename = `varnz_message_${random4DigitNumber}.png`;
                    // Varnz-fathers-day-greeting-card-4773.jpg 
                    filename = `Varnz-${formattedOccasion}-greeting-card-${random4DigitNumber}.png`;
                else
                    // filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
                    filename = `Varnz-${formattedOccasion}-greeting-card-${random4DigitNumber}.png`;
                const dataURL = canvas.toDataURL('image/png');

                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;

                localStorage.setItem("dataURl", dataURL);
                localStorage.setItem("filename", filename);



                // store user email and user name for say with us popup
                const storedEmail = getItemWithExpiry('user_email');
                // const storedName = getItemWithExpiry('user_name');

                axios.get(process.env.REACT_APP_VISITED_USERS)
                    .then(response => {
                        // Check if any user in the response data matches the stored email and name
                        const userMatch = response.data.data.find(user =>
                            user.email === storedEmail
                        );

                        if (userMatch) {
                            a.click();
                            alert.success("Downloaded Successfully!", {
                                timeout: 3000
                            });
                            setIsDownload(false);
                        } else {
                            console.log("No data available for download");
                        }
                    })
                    .catch(error => {
                        console.error('Error fetching user records:', error);
                    });
                // elementToCapture.style.width = `${originalWidthStyle}px`;
                // elementToCapture.style.height = `${originalHeightStyle1}px`;
                // if(paragraphElement){
                // paragraphElement.style.fontSize = originalSize;

                // }
                elementToCapture.classList.remove('instagram-size-1080');
                setDownloadText({
                    text: "Download",
                    disabled: false
                });
                if (setIsDownload) {
                    setIsDownload(true);
                }
                setShowInstagramPost(false)
                if (setDownloadCard) {
                    setDownloadCard({
                        download: false,
                        share: false
                    })
                }

                // sendData("download-successfully")
                if (downloadedImage) {
                    if (captureDivRef1.current.querySelector("div#image-div")) {
                        captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = originalImage;
                    }
                    if (captureDivRef1.current.querySelector("img#image-div")) {
                        captureDivRef1.current.querySelector("img#image-div").src = originalImage;
                    }
                }
                recall = true;
            });
        }
    }, 100)

};

export const handlePrint = ({ e, captureDivRef1, setPrintPage }) => {
    if (captureDivRef1.current) {
        setPrintPage({
            text: "Please wait..",
            disabled: true
        });
        setTimeout(() => {
            html2canvas(captureDivRef1.current, { useCORS: true, scale: 2 }).then(canvas => {
                const imageData = canvas.toDataURL('image/png');

                // Create a new window to print
                const printWindow = window.open('', '_blank');
                printWindow.document.write(`
                  <html>
                    <head>
                      <title>Print</title>
                      <style>
                  body, html {
                    margin: 0;
                    padding: 0;
                    height: 100%;
                  }
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain; /* Ensure the image maintains aspect ratio */
                  }
                </style>
                    </head>
                    <body>
                      <img id="printImage" src="${imageData}" />
                    </body>
                  </html>
                `);
                printWindow.document.close();

                // Wait for the image to load before triggering printing
                const printImage = printWindow.document.getElementById('printImage');
                printImage.onload = () => {
                    printWindow.print();
                    setPrintPage({
                        text: "Print",
                        disabled: false
                    });
                };
            });
        }, 100)
    } else {
        console.error('captureDivRef1 is not initialized');
    }
}





export const Result_Overlay = ({ index, downloadText, setDownloadText, captureDivRef1, title, sendData, showMessage, showTabs, handleClick, data, alert, design, clickedTabs, setDownloadCard, isDisabled, setShowInstagramPost, bannerThemeResponse, setIsDownload }) => {
    const [sharePlease, setSharePlease] = useState({
        text: "Share",
        disabled: false
    });
    const [printPage, setPrintPage] = useState({
        text: "Print",
        disabled: false
    });
    const [size, setSize] = useState("0");
    const [showSize, setShowSize] = useState(false);
    const [isHovered, setIsHovered] = useState({});
    const handleMouseLeave = (index) => {
        setIsHovered({
            [index]: false
        })
    }
    const handleMouseEnter = (index) => {
        setIsHovered({
            [index]: true
        })
    }

    // console.log(isDisabled,"isDisabled")
    return (
        <>
            <div className="result_overlay_info new-action-btns" id={`social-btn_${index}`}>
                <div className="action-btns-left">
                    <div className="inner_performing_buttons greeting-card-share card-share" onClick={(e) => shareImageCreate(e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard)}>
                        <a href="javascript:void(0);"
                            style={{
                                cursor: sharePlease.disabled ? 'not-allowed' : 'pointer',
                                background: isHovered[`share-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`share-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            className="greeting-card-share"
                            onMouseEnter={(e) => { handleMouseEnter(`share-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`share-${index}`) }}
                        >

                            {
                                sharePlease.disabled ? (
                                    <i id="loader" class="fas fa-spinner loader"></i>

                                ) : (
                                    <img src={share} alt="Image" className="greeting-card-share" />
                                )
                            }
                            <span className="action-btn-tooltip">Share</span>
                        </a>
                    </div>
                    <div className="inner_performing_buttons edit message-edit edit-card" >
                        <Link
                            to={`/varnz-greeting-card-design-studio`} onClick={() => { handleClick(data, design, showTabs); localStorage.setItem("hompepage", "edit_page"); }}
                            className="message-edit"
                            target="_blank"
                            style={{
                                background: isHovered[`edit-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`edit-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`edit-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`edit-${index}`) }}
                        >
                            <img src={edit} alt="Image" className="message-edit" />
                            <span className="action-btn-tooltip">Edit</span>
                        </Link>
                    </div>
                    <div className="inner_performing_buttons edit message-edit edit-card " style={{ fontSize: "23px" }} >
                        <Link
                            to={`javascript:void();`} onClick={(e) => { handlePrint({ e, captureDivRef1, setPrintPage }) }}
                            className="message-edit"
                            style={{
                                background: isHovered[`print-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`print-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`print-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`print-${index}`) }}
                        >
                            {
                                printPage.disabled ? (
                                    <i id="loader" class="fas fa-spinner loader"></i>

                                ) : (
                                    <i class="fas fa-print"></i>
                                )
                            }

                            <span className="action-btn-tooltip">Print</span>
                        </Link>
                    </div>
                </div>
                <div className="action-btns-right">
                    {/* <div className="inner_performing_buttons greeting-card-share card-share" onClick={(e) => shareImageCreate(e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs,setDownloadCard)}>
                <a href="javascript:void(0);" style={{ cursor: sharePlease.disabled ? 'not-allowed' : 'pointer' }} className="greeting-card-share" >
                   <i class="fa-solid fa-arrows-rotate"></i>
                    <span>Regenerate</span>
                </a>
            </div> */}

                    {/* <div>
                <select 
                value={size} 
                onChange={handleOptionChange}
                >
                    <option value="">Select...</option>
                    <option value="0">same size</option>
                    <option value="1">1080x1080</option>
                    <option value="2">1080x566</option>
                    <option value="3">1080x1350</option>
                </select>
            </div> */}

                    <div className="dowbload-dropdown">
                        <div id="download-btn" className="greeting-card-download download-btn" onClick={(e) => {
                            if (isDisabled) {
                                return;
                            }
                            handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, size, setShowInstagramPost, setIsDownload)
                        }
                        }
                            style={{
                                cursor: downloadText.disabled ? 'not-allowed' : isDisabled ? 'not-allowed' : 'pointer',
                                background: isHovered[`dl-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                border: isHovered[`dl-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`dl-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`dl-${index}`) }}
                        >
                            <a style={{ cursor: downloadText.disabled ? 'not-allowed' : isDisabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" className="">
                                {
                                    downloadText.disabled ? (
                                        <i id="loader" class="fas fa-spinner loader"></i>
                                    ) : (
                                        <img src={download} alt="Image" className="greeting-card-download" />
                                    )
                                }
                            </a>
                            Download
                        </div>
                        <div className="download-arrow " onClick={() => setShowSize(!showSize)}
                            style={{
                                background: isHovered[`dl-${index}`] ? bannerThemeResponse?.button_hover_color : bannerThemeResponse?.button_main_color,
                                // border: isHovered[`dl-${index}`] ? `2px solid ${bannerThemeResponse?.button_hover_color}` : `2px solid ${bannerThemeResponse?.button_main_color}`
                            }}
                            onMouseEnter={(e) => { handleMouseEnter(`dl-${index}`) }}
                            onMouseLeave={(e) => { handleMouseLeave(`dl-${index}`) }}
                        ><i class="fa-solid fa-caret-down"></i></div>
                        <div className={`download-dropdown-option ${showSize && 'active'}`}>
                            <ul>

                                <li
                                    id="insta-post"
                                    onClick={(e) => {
                                        if (isDisabled) {
                                            return;
                                        }
                                        setShowSize(false);
                                        handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, "1", setShowInstagramPost, setIsDownload)
                                    }
                                    }>
                                    Instagram (1:1)
                                </li>
                                <li
                                    id="insta-post"
                                    onClick={(e) => {
                                        if (isDisabled) {
                                            return;
                                        }
                                        setShowSize(false);
                                        handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, size, setShowInstagramPost, setIsDownload)
                                    }
                                    }>
                                    Actual Size
                                </li>
                            </ul>
                        </div>

                    </div>

                </div>

            </div>
            {
                // downloadText.disabled
                downloadText.disabled &&
                <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
                    <div class="skype-loader">
                        <div class="dot">
                            <div class="first"></div>
                        </div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div>
            }

        </>
    )
}


export const Result_OverlayOld = ({ index, downloadText, setDownloadText, captureDivRef1, title, sendData, showMessage, showTabs, handleClick, data, alert, design, clickedTabs, setDownloadCard }) => {
    const [sharePlease, setSharePlease] = useState({
        text: "Share",
        disabled: false
    });
    const [size, setSize] = useState("same_size");
    const handleOptionChange = (event) => {
        console.log(event.target.value, "kkkkkkkkkkk")
        // if(event.target.value === "1080"){
        //     setSize({
        //         width: 1080,
        //         height: 1080
        //     })
        // }else{
        setSize(event.target.value);
        // }
    };
    return (
        <div className="result_overlay_info" id={`social-btn_${index}`}>
            <div id="download-btn" className="inner_performing_buttons greeting-card-download download-btn" onClick={(e) => handleDownloadClick(e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, "", "", data.image?.downloadedImage, setDownloadCard, size)
            }>
                <a style={{ cursor: downloadText.disabled ? 'not-allowed' : 'pointer' }} href="javascript:void(0);" className="greeting-card-download">
                    <img src={download} alt="Image" className="greeting-card-download" />
                    <p className="greeting-card-download">{downloadText.text}</p>
                </a>
            </div>
            <div>
                <select
                    value={size}
                    onChange={handleOptionChange}
                >
                    <option value="">Select...</option>
                    <option value="0">same size</option>
                    <option value="1">1080x1080</option>
                    <option value="2">1080x566</option>
                    <option value="3">1080x1350</option>
                </select>
            </div>
            <div className="inner_performing_buttons greeting-card-share card-share" onClick={(e) => shareImageCreate(e, captureDivRef1, showMessage, showTabs, setSharePlease, clickedTabs, setDownloadCard)}>
                <a href="javascript:void(0);" style={{ cursor: sharePlease.disabled ? 'not-allowed' : 'pointer' }} className="greeting-card-share" >
                    <img src={share} alt="Image" className="greeting-card-share" />
                    <p className="greeting-card-share">{sharePlease.text}</p>
                </a>
            </div>
            <div className="inner_performing_buttons edit message-edit edit-card" >
                <Link
                    to={`/edit`} onClick={() => { handleClick(data, design, showTabs); localStorage.setItem("hompepage", "edit_page"); }}
                    className="message-edit"
                    target="_blank"
                >
                    <img src={edit} alt="Image" className="message-edit" />
                    <p className="message-edit">Edit</p>
                </Link>
            </div>
        </div>
    )
}


export const handleDownloadClickOld = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage) => {
    if (downloadText.disabled) {
        return;
    }
    // alert(window.screen.width)
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 3;
    } else {
        scale = 5
    }
    setDownloadText({
        text: "Please wait..",
        disabled: true
    })

    setTimeout(() => {
        let elementToCapture = "";
        elementToCapture = captureDivRef1.current;


        let originalImage;
        if (captureDivRef1.current.querySelector("div#image-div")) {
            originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
        }
        if (captureDivRef1.current.querySelector("img#image-div")) {
            originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
        }

        if (downloadedImage) {

            if (captureDivRef1.current.querySelector("div#image-div")) {
                elementToCapture.querySelector("div#image-div").style.backgroundImage = `url("${downloadedImage}")`;
            }
            if (captureDivRef1.current.querySelector("img#image-div")) {
                elementToCapture.querySelector("img#image-div").src = downloadedImage;
            }
        }
        // const originalImage = captureDivRef1.current.querySelector("#image-div")?.style.backgroundImage;
        // if(downloadedImage){
        //     captureDivRef1.current.querySelector("#image-div").style.backgroundImage = `url("${downloadedImage}")`;
        // }
        // console.log(originalImage,"elelel")




        // const originalHeight = elementToCapture.offsetHeight;
        // const originalWidth = elementToCapture.offsetWidth;
        // elementToCapture.style.objectFit = "contain";
        // elementToCapture.style.height = `${originalHeight}px`;
        // elementToCapture.style.width = `${originalWidth}px`;
        // const templateHeading = elementToCapture.querySelector('.template-heading');
        // const templateMessage = elementToCapture.querySelector('.template-message');
        // original style
        // const originalHeadingFontSize = templateHeading.style.fontSize;
        // const oriignalMessageFontSize = templateMessage.style.fontSize;
        // apply style in template after download
        // templateHeading.style.fontSize = "70px";
        // templateMessage.style.fontSize = "50px";
        // Get the actual width and height of the div
        // const width = elementToCapture.offsetWidth;
        // const height = elementToCapture.offsetHeight;
        // const devicePixelRatio = window.devicePixelRatio || 1;

        // Calculate the width and height based on the device's pixel ratio
        // const width = elementToCapture.clientWidth * devicePixelRatio;
        // const height = elementToCapture.clientHeight * devicePixelRatio;

        if (is_dom && 1 === 2) {
            domtoimage.toPng(elementToCapture, { scale: 4, useCORS: true, quality: 1, })
                .then((dataUrl) => {
                    const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                    let filename = "";
                    if (title === "Others")
                        filename = `varnz_message_${random4DigitNumber}.png`;
                    else
                        filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

                    // Create a link element to trigger the download
                    const a = document.createElement('a');
                    a.href = dataUrl;
                    a.download = filename;

                    // Trigger the download
                    document.body.appendChild(a);
                    a.click();

                    // Cleanup: remove the link element from the DOM
                    document.body.removeChild(a);

                    alert.success("Downloaded Successfully!", {
                        timeout: 3000
                    });

                    // Reset the download button state if needed
                    setDownloadText({
                        text: "Download",
                        disabled: false
                    });

                    // Perform any additional actions or data updates
                    sendData("download-successfully");
                })
                .catch((error) => {
                    console.error('Error capturing element:', error);
                });
        } else {
            html2canvas(elementToCapture, { scale: scale, quality: 1, useCORS: true }).then((canvas) => {
                if (downloadedImage) {
                    if (captureDivRef1.current.querySelector("div#image-div")) {
                        captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = `url("${originalImage}")`;
                    }
                    if (captureDivRef1.current.querySelector("img#image-div")) {
                        captureDivRef1.current.querySelector("img#image-div").src = originalImage;
                    }
                }
                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = "";
                if (title === "Others")
                    filename = `varnz_message_${random4DigitNumber}.png`;
                else
                    filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;
                a.click();
                alert.success("Downloaded Successfully!", {
                    timeout: 3000
                });

                setDownloadText({
                    text: "Download",
                    disabled: false
                });
                sendData("download-successfully")
            });
        }







        // domtoimage.toPng(elementToCapture, { width, height, useCORS: true,quality: 1 })
        //     .then((dataUrl) => {
        //         const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
        //         let filename = "";
        //         if (title === "Others")
        //             filename = `varnz_message_${random4DigitNumber}.png`;
        //         else
        //             filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;

        //         // Create a link element to trigger the download
        //         const a = document.createElement('a');
        //         a.href = dataUrl;
        //         a.download = filename;

        //         // Trigger the download
        //         document.body.appendChild(a);
        //         a.click();

        //         // Cleanup: remove the link element from the DOM
        //         document.body.removeChild(a);

        //         alert.success("Downloaded Successfully!", {
        //             timeout: 3000
        //         });

        //         // Reset the download button state if needed
        //         setDownloadText({
        //             text: "Download",
        //             disabled: false
        //         });

        //         // Perform any additional actions or data updates
        //         sendData("download-successfully");
        //     })
        //     .catch((error) => {
        //         console.error('Error capturing element:', error);
        //     });
    }, 100)
};



// setTimeout(() => {
//     let elementToCapture = "";
//     elementToCapture = captureDivRef1.current;

//     let originalImage;
//     if(captureDivRef1.current.querySelector("div#image-div")){
//         originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
//     }
//     if(captureDivRef1.current.querySelector("img#image-div")){
//         originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
//     }

//     if(downloadedImage){

//         if(captureDivRef1.current.querySelector("div#image-div")){
//             elementToCapture.querySelector("div#image-div").style.backgroundImage = `url("${downloadedImage}")`;
//         }
//         if(captureDivRef1.current.querySelector("img#image-div")){
//             elementToCapture.querySelector("img#image-div").src = downloadedImage;
//         }
//     }

//     html2canvas(elementToCapture, { scale: scale, quality: 1, useCORS: true }).then((canvas) => {

//         const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
//         let filename = "";
//         if (title === "Others")
//             filename = `varnz_message_${random4DigitNumber}.png`;
//         else
//             filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
//         const dataURL = canvas.toDataURL('image/png');
//         const a = document.createElement('a');
//         a.href = dataURL;
//         a.download = `${filename}`;
//         a.click();
//         alert.success("Downloaded Successfully!", {
//             timeout: 3000
//         });


//         setDownloadText({
//             text: "Download",
//             disabled: false
//         });
//         sendData("download-successfully")
//     },100);
//     setTimeout(()=>{
//         if(downloadedImage){
//             if(captureDivRef1.current.querySelector("div#image-div")){
//                 captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = `url("${originalImage}")`;
//             }
//             if(captureDivRef1.current.querySelector("img#image-div")){
//                 captureDivRef1.current.querySelector("img#image-div").src = originalImage;
//             }
//         }
//     },500)
// }, 100)



export const handleDownloadClickOldNew = (e, downloadText, setDownloadText, captureDivRef1, title, sendData, alert, is_dom, scales, downloadedImage, setDownloadCard) => {
    if (downloadText.disabled) {
        return;
    }
    let scale = 1;
    if (window.screen.width > 900) {
        scale = scales || 2;
    } else if ((window.screen.width > 500)) {
        scale = 2;
    } else {
        scale = 2
    }
    setDownloadText({
        text: "Please wait..",
        disabled: true
    })

    if (setDownloadCard) {
        setDownloadCard({
            download: true,
            share: false
        })
    }

    // console.log("download check",scale)
    setTimeout(() => {
        let elementToCapture = "";
        let recall = false;
        elementToCapture = captureDivRef1.current;

        let originalImage;
        if (captureDivRef1.current.querySelector("div#image-div")) {
            console.log("test22222", downloadedImage)
            originalImage = captureDivRef1.current.querySelector("div#image-div")?.style.backgroundImage;
        }
        if (captureDivRef1.current.querySelector("img#image-div")) {
            console.log("test33333333", downloadedImage)

            originalImage = captureDivRef1.current.querySelector("img#image-div")?.src;
        }

        if (downloadedImage) {

            if (captureDivRef1.current.querySelector("div#image-div")) {
                const divImage = elementToCapture.querySelector("div#image-div");
                divImage.style.backgroundImage = `url("${downloadedImage}")`;

                // // Execute a function after the image has finished loading
                // divImage.onload = function() {
                //     // Run your function here
                if (!recall) {
                    abcd();
                }
                // };
            }
            if (captureDivRef1.current.querySelector("img#image-div")) {
                const imgElement = elementToCapture.querySelector("img#image-div");
                imgElement.src = downloadedImage;
                // Execute a function after the image has finished loading
                imgElement.onload = function () {
                    // Run your function here
                    if (!recall) {
                        abcd();
                    }
                };

            }
        } else {
            abcd()
        }

        function abcd() {
            console.log("test11111111", downloadedImage, scale)
            html2canvas(elementToCapture, {
                scale: scale,
                quality: 1,
                useCORS: true,
                x: -0.2,
                y: 0,
                backgroundColor: "transparent"
            }).then((canvas) => {

                const random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                let filename = "";
                if (title === "Others")
                    filename = `varnz_message_${random4DigitNumber}.png`;
                else
                    filename = `varnz_${title.toLowerCase().replace("Happy ", "")}_message_${random4DigitNumber}.png`;
                const dataURL = canvas.toDataURL('image/png');
                const a = document.createElement('a');
                a.href = dataURL;
                a.download = `${filename}`;
                a.click();
                alert.success("Downloaded Successfully!", {
                    timeout: 3000
                });


                setDownloadText({
                    text: "Download",
                    disabled: false
                });
                if (setDownloadCard) {
                    setDownloadCard({
                        download: false,
                        share: false
                    })
                }

                // sendData("download-successfully")
                if (downloadedImage) {
                    if (captureDivRef1.current.querySelector("div#image-div")) {
                        captureDivRef1.current.querySelector("div#image-div").style.backgroundImage = originalImage;
                    }
                    if (captureDivRef1.current.querySelector("img#image-div")) {
                        captureDivRef1.current.querySelector("img#image-div").src = originalImage;
                    }
                }
                recall = true;
            });
        }
    }, 100)

};