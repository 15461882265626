// import { FirstDesign } from "./designTemplates/firstDesign";
// import { SecondDesign } from "./designTemplates/secondDesign";

import { ForthDesign } from "./designTemplates/forthDesign";
import { ForthDesign2 } from "./designTemplates/forthDesign2";

import { FiftheenDesign } from "./designTemplates/fiftheenDesign";
import { FiftheenDesign2 } from "./designTemplates/fiftheenDesign2";
import { QuoteDesign1 } from "./designTemplates/quoteDesign1";
import { QuoteDesign2 } from "./designTemplates/quoteDesign2";
import { QuoteDesign3 } from "./designTemplates/quoteDesign3";
import { PoemDesign1 } from "./designTemplates/poemDesign1";
import { PoemDesign2 } from "./designTemplates/poemDesign2";
import { PoemDesign3 } from "./designTemplates/poemDesign3";
import { PoemDesign4 } from "./designTemplates/poemDesign4";
import { PoemDesign5 } from "./designTemplates/poemDesign5";
import { PoemDesign6 } from "./designTemplates/poemDesign6";
import { PoemDesign7 } from "./designTemplates/poemDesign7";
import { PoemDesign8 } from "./designTemplates/poemDesign8";
import { PoemDesign9 } from "./designTemplates/poemDesign9";
import { PoemDesign10 } from "./designTemplates/poemDesign10";
import { PoemDesign11 } from "./designTemplates/poemDesign11";
import { PoemDesign12 } from "./designTemplates/poemDesign12";
import { JokesDesign1 } from "./designTemplates/jokesDesign1";
import { JokesDesign2 } from "./designTemplates/jokesDesign2";
import { JokesDesign3 } from "./designTemplates/jokesDesign3";
import { JokesDesign12 } from "./designTemplates/jokesDesign12";

// import { JokesDesign4 } from "./designTemplates/jokesDesign4";
import { BirthdayDesign5 } from "./designTemplates/birthdayDesign5";
import { BirthdayDesign6 } from "./designTemplates/birthdayDesign6";
import { BirthdayDesign7 } from "./designTemplates/BirthdayDesign7";
import { BirthdayDesign8 } from "./designTemplates/BirthdayDesign8";
import { BirthdayDesign9 } from "./designTemplates/birthdayDesign9";
import { BirthdayDesign10 } from "./designTemplates/birthdayDesign10";
import { BirthdayDesign11 } from "./designTemplates/birthdayDesign11";
// import { FifthDesign } from "./designTemplates/fifthDesign";
// import { SeventhDesign } from "./designTemplates/ForthDesign";
// import { SixthDesign } from "./designTemplates/sixthDesign";
// import { EightDesign } from "./designTemplates/eightDesign";
// import { NinthDesign } from "./designTemplates/ninthDesign";
// import { ThreeDesign } from "./designTemplates/threeDesign";
// import { EleventhDesign } from "./designTemplates/eleventhDesign";
// import { TwelthDesign } from "./designTemplates/twelthDesign";
// import { ThirteenDesign } from "./designTemplates/thirteenDesign";
// import { FourteenDesign } from "./designTemplates/fourteenDesign";
// import { SixtennDesign } from "./designTemplates/sixteenDesign";
// import { TenthDesign } from "./designTemplates/tenthDesign";



import farewell from "./image/farewell.png";
import iconthanks from "./image/icon-thanks.png";
import lovecard from "./image/lovecard.png";
import darknesscard from "./image/darkness-card.png";
import aicards from "./image/ai-cards.png";
import abstract from "./image/abstract-icon.png";
import birthday_cake from "./image/birthday-cake.png";




export const commonTabs = {
    Love_Cards: {
        isSubtabs: false,
        icon: lovecard,
        subtabtabs: {},
        imageTemplate: [
            
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/3.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/4.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/5.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/6.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/7.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/8.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/9.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/10.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/11.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/12.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/13.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/14.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/15.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/16.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/17.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/love/18.jpg'
                },
                heading: {
                    color: "#fff",
                    size: "20px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
                message: {
                    color: "#080f48",
                    size: "18px",
                    fontFamily: "Wright Deco Thin",
                    fontWeight: "bold",
                    background: "#000",
                    opacity: 0.5
                },
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/1.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/2.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/3.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/4.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/5.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/6.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/7.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/8.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/flower/9.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/1.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/2.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/3.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/4.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/5.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/6.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/7.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/8.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/9.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/happy/10.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/1.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/2.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/3.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/4.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/5.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/6.jpg'
                }
            },

            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/10.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/11.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/12.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/13.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/nature/14.jpg'
                }
            }
        ],
    },
    Darkness_Cards: {
        isSubtabs: false,
        icon: darknesscard,
        subtabtabs: {},
        imageTemplate: [
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/1.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/2.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/3.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/4.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/5.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/6.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/7.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/8.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/sad/9.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/1.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/2.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/3.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/4.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/5.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/6.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/7.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/8.png'
                }
            },
            {
                isOwnDesign: true,
                design: FiftheenDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/9.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/10.jpg'
                }
            },
            {
                isOwnDesign: true,
                design: ForthDesign2,
                image: {
                    is_opaque: 'yes',
                    opacity: '0.6',
                    background: '#ddd',
                    backgroundCover: true,
                    link: 'varnzTemplates/common/horror/11.jpg'
                }
            }
        ],
    },
}

export const defaultProp = {
    isOwnDesign: true,
    design: FiftheenDesign2,
    image: {
        link: "varnzTemplates/birthday/1.jpg",
        is_opaque: "yes",
        opacity: "0.8",
        background: "#000",
        backgroundCover: true
    },
    heading: {     //  this is related heading / title   =>  color, size, fontFamily
        color: "#fff",
        size: "20px",
        fontFamily: "Wright Deco Thin",
        background: "#000",
        fontWeight: 'bold',
        opacity: 0.5
    },
    message: {   //  this is related message / title   =>  color, size, fontFamily
        color: "#080f48",
        size: "20",
        fontFamily: "Wright Deco Thin",
        fontWeight: 'bold',
        // background: "#000",
        opacity: 0.5
    },
    div: {
        background: "#fff",
        is_opaque: "yes",
        borderRadius: "13px",
        padding: "26px",
        fontFamily: "Exo",
        color: "#fff",
        opacity: "0.8",
        maxWidth: "90%"
    }
}

export const specialOccassion = {
    message: {
        wish_someone: {
            birthday: {
                Family: {
                    isSubtabs: false,
                    icon: farewell,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/1.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/2.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/3.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/4.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/5.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/6.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/7.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/8.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/9.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/10.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/11.webp'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/family/father/12.webp'
                            }
                        },
                    ],
                }
            }
        }
        
    }
}

const NewTemplate = [
    {
        birthday: {
            no_option: {
                birthday: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: JokesDesign1,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/1.jpg',
                                background: "#c9933d"
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: JokesDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/2.jpg',
                                background: "#ffdacc"
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: JokesDesign3,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/3.jpg',
                                background: "#d6ddfd"
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign5,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Elsie Swash Caps', cursive",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#72686b",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign6,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fffafa",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign7,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#426372",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign8,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#746254",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#68786f",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign9,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fff",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign10,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#936f4f",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: BirthdayDesign11,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fff",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: JokesDesign12,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Jokes/common/4.jpg',
                                // background: "#d6ddfd"
                            },
                            heading: {
                                color: "#cc295f",
                                size: "40",
                                fontFamily: "Bahianita"
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fff",
                                opacity: 0.5,
                                display: 'inline',
                                highlight: '#ac9e27',
                            },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
    
    
                       
                    ],
                }
            }
        },
    }
]

const poemArray = [
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design9.png'
        },
        heading: {
            color: "#381a09",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#381a09",
            size: "18px",
            fontFamily: "Ubuntu Mono",
            fontWeight: "400",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign10,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design10.png'
        },
        heading: {
            color: "#381a09",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#381a09",
            size: "18px",
            fontFamily: "Ubuntu Mono",
            fontWeight: "400",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design11.png'
        },
        heading: {
            color: "#381a09",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#381a09",
            size: "18px",
            fontFamily: "Ubuntu Mono",
            fontWeight: "400",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design12.png'
        },
        heading: {
            color: "#381a09",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#381a09",
            size: "18px",
            fontFamily: "Ubuntu Mono",
            fontWeight: "400",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/homepage/poem/peom-design1.png',
            backgroundFrame: 'image/homepage/poem/peom-design1.png'
        },
        heading: {
            color: "#000",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#000",
            size: "18px",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design2.png'
        },
        heading: {
            color: "#462819",
            size: "26",
            fontFamily: "Parisienne-Regular",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#462819",
            size: "18px",
            fontFamily: "Parisienne-Regular",
            fontWeight: "500",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design4.png'
        },
        heading: {
            color: "#832d22",
            size: "26",
            fontFamily: "Parisienne-Regular",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#832d22",
            size: "18px",
            fontFamily: "Satisfy",
            fontWeight: "500",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design5.png'
        },
        heading: {
            color: "#000",
            size: "26",
            fontFamily: "Parisienne-Regular",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#000",
            size: "18px",
            fontFamily: "Charmonman",
            fontWeight: "500",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design6.png'
        },
        heading: {
            color: "#050100",
            size: "26",
            fontFamily: "Parisienne-Regular",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#050100",
            size: "18px",
            fontFamily: "PlayfairDisplay",
            fontWeight: "500",
            background: "#c33b5a",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: 'image/homepage/poem/peom-design7.png'
        },
        heading: {
            color: "#707d4d",
            size: "26",
            fontFamily: "Parisienne-Regular",
            background: "#000",
            fontWeight: "bold",
            opacity: 0.5
        },
        message: {
            color: "#626a4c",
            size: "18px",
            fontFamily: "PlayfairDisplay",
            fontWeight: "500",
            background: "#c33b5a",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: PoemDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/homepage/poem/peom-design1.png',
            backgroundFrame: 'image/homepage/poem/peom-design8.png'
        },
        heading: {
            color: "#000",
            size: "26",
            fontFamily: "Parisienne-Regular",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#462819",
            size: "18px",
            fontFamily: "Ubuntu Mono",
            fontWeight: "400",
            background: "#000",
            opacity: 0.5
        },
    },
]

const jokeArray = [
    // new images
    {
        isOwnDesign: true,
        design: JokesDesign12,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'image/joke/joke-temp12-btm.png',
            backgroundFrame: "image/joke/joke-temp12-bg.png",
            link2: "image/joke/joke-temp12-top.png"
            // background: "#d6ddfd"
        },
        heading: {
            color: "#cc295f",
            size: "40",
            fontFamily: "Bahianita"
        },
        message: {
            color: "#000",
            size: "24px",
            fontFamily: "Wright Deco Thin",
            background: "#fff",
            opacity: 0.5,
            display: 'inline',
            highlight: '#ac9e27',
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    
    {
        isOwnDesign: true,
        design: JokesDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/joke/joketemp2-monky.png',
            backgroundFrame: '',
            background: "#ffdacc"
        },
        heading: {
            color: "#000",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#000",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Jokes/common/1.jpg',
            background: "#fdbcda"
        },
        heading: {
            color: "#545643",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#545643",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#fff",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "",
        //     opacity: "1.2"
        // }
    },
    
    {
        isOwnDesign: true,
        design: JokesDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: '/image/joke/joketemp3-monky.png',
            backgroundFrame: '',
            background: "#d6ddfd"
        },
        heading: {
            color: "#29532e",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#29532e",
        //     size: "26px",
        //     fontFamily: "Granjon LT Std",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#29532e",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/joke/joketemp4-monky.png',
            backgroundFrame: '',
            background: "#f7e37c"
        },
        heading: {
            color: "#000",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#000",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Jokes/common/1.jpg',
            background: "#d6ddfd"
        },
        heading: {
            color: "#545643",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#545643",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#fff",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "",
        //     opacity: "1.2"
        // }
    },
    
    {
        isOwnDesign: true,
        design: JokesDesign12,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'image/joke/joke-temp13-btm.png',
            backgroundFrame: "image/joke/joke-temp13-bg.png",
            link2: "image/joke/joke-temp13-top.png"
            // background: "#d6ddfd"
        },
        heading: {
            color: "#f7e37c",
            size: "40",
            fontFamily: "Bahianita"
        },
        message: {
            color: "#000",
            size: "24px",
            fontFamily: "Wright Deco Thin",
            background: "#fff",
            opacity: 0.5,
            display: 'inline',
            highlight: '#ac9e27',
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },{
        isOwnDesign: true,
        design: JokesDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Jokes/common/1.jpg',
            background: "#c9933d"
        },
        heading: {
            color: "#545643",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#545643",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#fff",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign12,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'image/joke/joke-temp14-btm.png',
            backgroundFrame: "image/joke/joke-temp14-bg.png",
            link2: "image/joke/joke-temp14-top.png"
            // background: "#d6ddfd"
        },
        heading: {
            color: "#cc295f",
            size: "40",
            fontFamily: "Bahianita"
        },
        message: {
            color: "#000",
            size: "24px",
            fontFamily: "Wright Deco Thin",
            background: "#fff",
            opacity: 0.5,
            display: 'inline',
            highlight: '#ac9e27',
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign12,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'image/joke/joke-temp15-btm.png',
            backgroundFrame: "image/joke/joke-temp15-bg.png",
            link2: "image/joke/joke-temp15-top.png"
            // background: "#d6ddfd"
        },
        heading: {
            color: "#cc295f",
            size: "40",
            fontFamily: "Bahianita"
        },
        message: {
            color: "#000",
            size: "24px",
            fontFamily: "Wright Deco Thin",
            background: "#fff",
            opacity: 0.5,
            display: 'inline',
            highlight: '#ac9e27',
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },{
        isOwnDesign: true,
        design: JokesDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Jokes/common/1.jpg',
            background: "#73acb3"
        },
        heading: {
            color: "#545643",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#545643",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#fff",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: '/image/joke/joketemp5-monky.png',
            backgroundFrame: '',
            background: "#cceab6"
        },
        heading: {
            color: "#29532e",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#29532e",
        //     size: "26px",
        //     fontFamily: "Granjon LT Std",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#29532e",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign12,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'image/joke/joke-temp16-btm.png',
            backgroundFrame: "image/joke/joke-temp16-bg.png",
            link2: "image/joke/joke-temp16-top.png"
            // background: "#d6ddfd"
        },
        heading: {
            color: "#f7e37c",
            size: "40",
            fontFamily: "Bahianita"
        },
        message: {
            color: "#000",
            size: "24px",
            fontFamily: "Wright Deco Thin",
            background: "#fff",
            opacity: 0.5,
            display: 'inline',
            highlight: '#ac9e27',
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: JokesDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Jokes/common/1.jpg',
            background: "#c9933d"
        },
        heading: {
            color: "#545643",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#545643",
        //     size: "20px",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#fff",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "",
        //     opacity: "1.2"
        // }
    }, 
    {
        isOwnDesign: true,
        design: JokesDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: '/image/joke/joketemp5-monky.png',
            backgroundFrame: '',
            background: "#cceab6"
        },
        heading: {
            color: "#29532e",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        // message: {
        //     color: "#29532e",
        //     size: "26px",
        //     fontFamily: "Granjon LT Std",
        //     background: "#324455",
        //     opacity: 0.5
        // },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#29532e",
        //     opacity: "1.2"
        // }
    },
    
    


    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign5,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Elsie Swash Caps', cursive",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#fff",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#72686b",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign6,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#000",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#fffafa",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign7,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#fff",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#426372",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign8,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#746254",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#fff",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#68786f",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign9,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#000",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#fff",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign10,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#fff",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#936f4f",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
    // {
    //     isOwnDesign: true,
    //     design: BirthdayDesign11,
    //     image: {
    //         is_opaque: 'yes',
    //         opacity: '0.6',
    //         background: '#cdebee',
    //         backgroundCover: true,
    //         link: 'varnzTemplates/Jokes/common/4.jpg',
    //         // background: "#d6ddfd"
    //     },
    //     heading: {
    //         color: "#000",
    //         size: "26px",
    //         fontFamily: "Granjon LT Std",
    //         background: "#324455",
    //         opacity: 0.5
    //     },
    //     message: {
    //         color: "#000",
    //         size: "24px",
    //         fontFamily: "Wright Deco Thin",
    //         background: "#fff",
    //         opacity: 0.5,
    //         display: 'inline',
    //         highlight: '#ac9e27',
    //     },
    //     // div: {
    //     //     background: "#ddd",
    //     //     is_opaque: "yes",
    //     //     borderRadius: "13px",
    //     //     padding: "",
    //     //     fontFamily: "Granjon LT Std",
    //     //     color: "#000",
    //     //     opacity: "1.2"
    //     // }
    // },
]
const quoteArray = [
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/1.jpg',
            backgroundFrame: "frame/background/template-quote.jpg"
        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            background: "#092e41",
            borderImage: "linear-gradient(90deg,#9f9107,#f6ff6a 28%,#fff 51%,#f6f666 69%,#949100)",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame: "frame/background/template-quote2.jpg"
        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:"",
            background: "#ffd0de"

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#ff427b",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/event/9.jpg',
            backgroundFrame:"image/event/11.jpg"
        },
        heading: {
            color: "#fff",
            size: "15",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#000",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            borderImage:"linear-gradient(90deg, rgb(120 137 128), rgb(232, 139, 143) 28%, rgb(253, 227, 228) 51%, rgb(232, 164, 167) 69%, rgb(131 141 133))",
            // background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/event/1.png',
            backgroundFrame: 'image/event/1.png'
        },
        heading: {
            color: "#000",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        message: {
            color: "#000",
            size: "15",
            fontFamily: "Wright Deco Thin",
            background: "#324455",
            opacity: 0.5,
            fontWeight: "bold"
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Truetypewriter PolyglOTT",
        //     color: "#000",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: QuoteDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#e2ee99b3',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:""

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#161200",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'varnzTemplates/Quotes/common/3.jpg',
            backgroundFrame:"image/event/3.png"
        },
        heading: {
            color: "#29532e",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            fontFamily: "Granjon LT Std",
            background: "#324455",
            opacity: 0.5,
            fontWeight: "bold"
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#29532e",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:"image/event/10.jpg"

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            background:"#434728",
            
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:"",
            background:"#00aae9"

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#2284ae",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Quotes/common/6.jpg',
            backgroundFrame: "image/event/2.png"
        },
        heading: {
            color: "#000",
            size: "26px",
            circleColor: "#000",
            fontFamily: "Granjon LT Std",
            background: "#324455",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            fontFamily: "Granjon LT Std",
            fontWeight:"bold",
            background: "#a0a0c8",
            opacity: 0.5,
            // borderImage: "linear-gradient(90deg, #eecae6 0%, #f6cfeb 28%, #ffffff 51%, #b3abde 69%, #545da4e6 100%)"
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/event/9.jpg',
            backgroundFrame:"image/event/9.jpg"
        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            background:"#8d5859",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            borderImage:"linear-gradient(90deg, rgb(71 111 89), rgb(232 139 143) 28%, rgb(253 227 228) 51%, rgb(232 164 167) 69%, rgb(98 141 104))",
            // background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:"",
            background:"#7edbe9b0"

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#0b92a6",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/event/9.jpg',
            backgroundFrame:"image/event/13.jpg"
        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            background:"#414141",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            borderImage:"linear-gradient(90deg, rgb(124 202 180), rgb(87 134 158) 28%, rgb(253, 227, 228) 51%, rgb(248 144 175 / 98%) 69%, rgb(81 159 168))",
            // background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#cdebee',
            backgroundCover: true,
            link: 'varnzTemplates/Quotes/common/3.jpg',
            backgroundFrame:"image/event/4.png"
        },
        heading: {
            color: "#29532e",
            size: "20px",
            fontFamily: "Truetypewriter PolyglOTT",
            background: "#324455",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            fontFamily: "Granjon LT Std",
            background: "#324455",
            opacity: 0.5,
            fontWeight: "bold"
        },
        // div: {
        //     background: "#ddd",
        //     is_opaque: "yes",
        //     borderRadius: "13px",
        //     padding: "",
        //     fontFamily: "Granjon LT Std",
        //     color: "#29532e",
        //     opacity: "1.2"
        // }
    },
    {
        isOwnDesign: true,
        design: QuoteDesign3,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'varnzTemplates/common/love/2.jpg',
            backgroundFrame:"image/event/10.jpg"

        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#fff",
            size: "15",
            background:"#434728",
            
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign1,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#ddd',
            backgroundCover: true,
            link: 'image/event/9.jpg',
            backgroundFrame:"image/event/14.jpg"
        },
        heading: {
            color: "#fff",
            size: "20px",
            fontFamily: "Wright Deco Thin",
            background: "#000",
            opacity: 0.5
        },
        message: {
            color: "#000",
            size: "15",
            background:"#fff",
            fontFamily: "Wright Deco Thin",
            fontWeight: "bold",
            borderImage:"linear-gradient(90deg, rgb(71 111 89), rgb(232 139 143) 28%, rgb(253 227 228) 51%, rgb(232 164 167) 69%, rgb(98 141 104))",
            // background: "#000",
            opacity: 0.5
        },
    },
    {
        isOwnDesign: true,
        design: QuoteDesign2,
        image: {
            is_opaque: 'yes',
            opacity: '0.6',
            background: '#fff',
            backgroundCover: true,
            link: 'varnzTemplates/Quotes/common/6.jpg',
            backgroundFrame: "image/event/6.png"
        },
        heading: {
            color: "#000",
            size: "26px",
            circleColor: "#000",
            fontFamily: "Granjon LT Std",
            background: "#324455",
            opacity: 0.5
        },
        message: {
            color: "#251010",
            size: "15",
            fontFamily: "Granjon LT Std",
            fontWeight:"bold",
            background: "#a0a0c8",
            opacity: 0.5,
        },
    },
]
export const imageTemplate = {
    message: {
        wish_someone: {
            birthday: {
                Birthday: {
                    isSubtabs: false,
                    icon: birthday_cake,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/birthday/17.jpg'
                            }
                        }
                    ],
                },
            },
            dusshera: {
                dusshera: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images add 
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                link: 'varnzTemplates/religious_holidays/dusshera/18.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/19.jpg'
                            },
                            heading: {
                                color: "#505822",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f78800",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cbd4c9a3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/20.jpg'
                            },
                            heading: {
                                color: "#420d2e",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ec7535",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#feebcf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/21.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Cookie",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Cookie",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/22.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ffffffcc",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/24.jpg'
                            },
                            heading: {
                                color: "#fd0019",
                                size: "25px",
                                fontFamily: "Courgette",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#df8300",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Courgette",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/25.jpg'
                            },
                            heading: {
                                color: "#f26220",
                                size: "35px",
                                fontFamily: "Tangerine",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Tangerine",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Tangerine",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/26.jpg'
                            },
                            heading: {
                                color: "#fac94c",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/27.jpg'
                            },
                            heading: {
                                color: "#a82e15",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a82e15",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                link: 'varnzTemplates/religious_holidays/dusshera/30.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdd575bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/31.jpg'
                            },
                            heading: {
                                color: "#280408",
                                size: "25px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#9a003c91",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Great Vibes",
                                link: 'varnzTemplates/religious_holidays/dusshera/11.jpg'
                            },
                            heading: {
                                color: "#FF0000",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdb26180",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Rochester",
                                link: 'varnzTemplates/religious_holidays/dusshera/12.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#e74d4c",
                                size: "22px",
                                fontFamily: "Rochester",
                                background: "#000",
                                fontWeight: "bold",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Griffy",
                                link: 'varnzTemplates/religious_holidays/dusshera/13.jpg'
                            },
                            heading: {
                                color: "#995016",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ab9559",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#441607bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "'Griffy'",
                                link: 'varnzTemplates/religious_holidays/dusshera/14.jpg'
                            },
                            heading: {
                                color: "#63152a",
                                size: "25px",
                                fontFamily: "'Griffy'",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "'Griffy'",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e9c37e9c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "'Griffy'",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Flavors",
                                link: 'varnzTemplates/religious_holidays/dusshera/15.jpg'
                            },
                            heading: {
                                color: "#4e0a04",
                                size: "25px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#4e0a04",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#eac69bc4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Parisienne",
                                link: 'varnzTemplates/religious_holidays/dusshera/16.jpg'
                            },
                            heading: {
                                color: "#dcc89f",
                                size: "25px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(235 116 41 / 81%)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Alex Brush",
                                link: 'varnzTemplates/religious_holidays/dusshera/17.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Alex Brush",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Alex Brush",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#1b15208c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Alex Brush",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Merienda",
                                link: 'varnzTemplates/religious_holidays/dusshera/1.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#b1162d",
                                size: "20px",
                                fontFamily: "Merienda",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#c27541c4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Charm",
                                link: 'varnzTemplates/religious_holidays/dusshera/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Charm",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#5e2f10",
                                size: "20px",
                                fontFamily: "Charm",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Charm",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "",
                                fontFamily: "Norican",
                                link: 'varnzTemplates/religious_holidays/dusshera/3.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "",
                                fontFamily: "Arizonia",
                                link: 'varnzTemplates/religious_holidays/dusshera/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "28px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "23px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Ceviche-One",
                                link: 'varnzTemplates/religious_holidays/dusshera/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e4a957c7",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Great Vibes",
                                link: 'varnzTemplates/religious_holidays/dusshera/6.jpg'
                            },
                            heading: {
                                color: "#FFF",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#FFF",
                                size: "22px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#3e363685",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Allura",
                                type: "patch",
                                link: 'varnzTemplates/religious_holidays/dusshera/7.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "28px",
                                fontFamily: "Allura",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "23px",
                                fontFamily: "Allura",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#56afc670",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Allura",
                                fontWeight: "bold",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Ceviche-One",
                                type: "",
                                link: 'varnzTemplates/religious_holidays/dusshera/8.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#620007b8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Griffy",
                                link: 'varnzTemplates/religious_holidays/dusshera/9.jpg'
                            },
                            heading: {
                                color: "#df5181",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d0b4b5bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Sofia",
                                link: 'varnzTemplates/religious_holidays/dusshera/10.jpg'
                            },
                            heading: {
                                color: "#004112",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            apology: {
                Apology: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/apology/11.jpg'
                            }
                        }
                    ],
                }
            },
            bhai_dooj: {
                bhai_dooj: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/1.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#53167e73",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Rochester",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#956e0042",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/10.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },




                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/5.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Cookie",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#4d7fb14f",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Cookie",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/6.jpg'
                            },
                            heading: {
                                color: "#ffb700",
                                size: "36px",
                                fontFamily: "Metal-Mania",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#322b2b63",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Metal-Mania",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/7.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Courgette",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Courgette",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/8.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "36px",
                                fontFamily: "Tangerine",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "30px",
                                fontFamily: "Tangerine",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#85726970",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Tangerine",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/9.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Rochester",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/12.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#8155573d",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/11.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Griffy",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000052",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/13.jpg'
                            },
                            heading: {
                                color: "#ba9a51",
                                size: "36px",
                                fontFamily: "Courgette",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ba9a51",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#a102144f",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Courgette",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },






                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/1.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/2.jpg'
                            },
                            heading: {
                                color: "#858521",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/4.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/5.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e2ab56de",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#67728ca3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/8.jpg'
                            },
                            heading: {
                                color: "#bf0f2f",
                                size: "23px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#403000",
                                size: "18px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d07f48d4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/9.jpg'
                            },
                            heading: {
                                color: "rgb(255 161 25)",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#32524b",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/10.jpg'
                            },
                            heading: {
                                color: "#9f3053",
                                size: "24px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#9f3053",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cfb0b2d9",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            karwa_chauth: {
                karwa_chauth: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/1.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/2.jpg'
                            },
                            heading: {
                                color: "#858521",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/4.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/5.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e2ab56de",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#67728ca3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/8.jpg'
                            },
                            heading: {
                                color: "#bf0f2f",
                                size: "23px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#403000",
                                size: "18px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d07f48d4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/9.jpg'
                            },
                            heading: {
                                color: "rgb(255 161 25)",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#32524b",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/10.jpg'
                            },
                            heading: {
                                color: "#9f3053",
                                size: "24px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#9f3053",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cfb0b2d9",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            diwali: {
                diwali: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/img1.jpg'
                            },
                            heading: {
                                color: "#ba9a51",
                                size: "36px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ba9a51",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/3.jpg'
                            },
                            heading: {
                                color: "#a57623",
                                showHeading: 'no',
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a57623",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px 10px 10px 10px",
                                fontFamily: "Wright Deco Thin",
                                color: "#a57623",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/2.jpg'
                            },
                            heading: {
                                color: "#a57623",
                                showHeading: 'no',
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a57623",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px 0px 0px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#a57623",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "40px",
                                fontFamily: "Novelia",
                                background: "#000",
                                opacity: 0.5,
                                wordSpacing: '14px',
                            },
                            message: {
                                color: "#fff",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/7.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#f0af56",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/28.jpg'
                            },
                            heading: {
                                color: "#ffc3ef",
                                size: "40px",
                                fontFamily: "Novelia",
                                background: "#000",
                                opacity: 0.5,
                                wordSpacing: '14px',
                                // showHeading: false,
                            },
                            message: {
                                color: "#ffc05f",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "235px 0 0 0",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },






                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/32.jpg'
                            },
                            heading: {
                                color: "#ffb72f",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no",
                            },
                            message: {
                                color: "#ddd85b",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/33.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f8ed72",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/34.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ed95ff",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/5.jpg'
                            },
                            heading: {
                                color: "#fdac2d",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fdac2d",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fdac2d",
                                size: "17px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#fdac2d",
                                opacity: 0.5
                            },
                            div: {
                                background: "",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "13px 60px 2px 60px",
                                marginTop: "74px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fdac2d",
                                opacity: "0.1",
                                lineHeight: '1.1'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/25.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "50px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/6.jpg'
                            },
                            heading: {
                                color: "#fdd835",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fdd835",
                                size: "17px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdd835",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/8.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/9.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b20042",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#b20042",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#f25620",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#f25620",
                                opacity: "0.1"
                            }
                        },

                        
                    ],
                }
            },
            cherished_love: {
                cherished_love: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/17.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/18.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/cherished_love/19.jpg'
                            }
                        }
                    ],
                }
            },
            childrens_day: {
                childrens_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/childrens_day/17.jpg'
                            }
                        }
                    ],
                }
            },
            christmas_day: {
                christmas_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/christmas_day/17.jpg'
                            }
                        }
                    ],
                }
            },

            festival: {
                festival: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/festival/8.jpg'
                            }
                        }
                    ],
                }
            },
            friendship_day: {
                friendship_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/17.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/friendship_day/18.jpg'
                            }
                        }
                    ],
                }
            },
            get_well_soon: {
                get_well_soon: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/get_well_soon/17.jpg'
                            }
                        }
                    ],
                }
            },
            halloween_day: {
                halloween_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Creepster',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/2.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Creepster",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Eater',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/9.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Eater",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Eater",
                                background: "#ddd",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Eater",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            div: {
                                background: "#494c336b",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Butcherman',
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/2.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Butcherman",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Flavors",
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/5.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                link: 'varnzTemplates/wish_someone/halloween_day/3.jpg',
                                type: 'patch'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Metal-Mania',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/7.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(22 23 29 / 85%)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#000",
                                opacity: "0.8"
                            }

                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Griffy',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/8.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                fontWeight: "bold",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Ceviche-One',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/1.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ecffa869",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '1',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Astloch',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/10.png'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Astloch",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Astloch",
                                background: "#000",
                                fontWeight: 'bold',
                                opacity: 0.5
                            },
                            div: {
                                background: "#0c1727b8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Astloch",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Risque',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/11.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#31203578",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Piedra',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/12.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Denk-One',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/13.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(235 137 72)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Denk-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/1.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Piedra',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Creepster',
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Creepster",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Risque",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/8.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Risque",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                fontWeight: 'bold',
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Astloch",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/9.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Astloch",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Astloch",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Astloch",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Jolly-Lodger",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/10.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Jolly-Lodger",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Ceviche-One",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/11.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#2f323991",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Frijole",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/12.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#423c3959",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Frijole",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Flavors",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/13.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000061",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            independence_day: {
                independence_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/independence_day/9.jpg'
                            }
                        }
                    ],
                }
            },
            new_year: {
                new_year: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/17.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/new_year/18.jpg'
                            }
                        }
                    ],
                }
            },
            republic_day: {
                republic_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/1.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Griffy",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Griffy",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/13.jpg'
                            },
                            heading: {
                                color: "#cb1500",
                                size: "30px",
                                fontFamily: "Rochester",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#02701b",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Rochester",
                                color: "#02701b",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/39.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "30px",
                                fontFamily: "Cookie",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "24px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Cookie",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/14.jpg'
                            },
                            heading: {
                                color: "#006815",
                                size: "26px",
                                fontFamily: "Norican",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#783d00",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddda8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Norican",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/19.jpg'
                            },
                            heading: {
                                color: "#006815",
                                size: "28px",
                                fontFamily: "Courgette",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f80018",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddd7a",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Courgette",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/61.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Metal-Mania",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Metal-Mania",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/21.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddd52",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavour",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/62.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "30px",
                                fontFamily: "Great Vibes",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "24px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Great Vibes",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/30.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "26px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Flavour",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/47.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Metal-Mania",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/53.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Griffy",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Griffy",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/55.jpg'
                            },
                            heading: {
                                color: "#701d01",
                                size: "28px",
                                fontFamily: "Rochester",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000036",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/56.jpg'
                            },
                            heading: {
                                color: "#0f4e00",
                                size: "28px",
                                fontFamily: "Charm",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#d75615",
                                size: "24px",
                                fontFamily: "Alex Brush",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#1b15208c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Charm",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/57.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000036",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/37.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "24px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddd52",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavour",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/38.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "30px",
                                fontFamily: "Cookie",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "24px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Cookie",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/59.jpg'
                            },
                            heading: {
                                color: "#a57000",
                                size: "28px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#46500d",
                                size: "23px",
                                fontFamily: "Ceviche-One",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/63.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "26px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ff381e",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Flavour",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/33.jpg'
                            },
                            heading: {
                                color: "#ff7c1e",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#FFF",
                                size: "22px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#3e363685",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/35.jpg'
                            },
                            heading: {
                                color: "#077c59",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#364400",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#3e363685",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/31.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/58.jpg'
                            },
                            heading: {
                                color: "#00089d",
                                size: "28px",
                                fontFamily: "Alex Brush",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Alex Brush",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000036",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Alex Brush",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/20.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "26px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#78010d",
                                size: "22px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000036",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Norican",
                                color: "#78010d",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/48.jpg'
                            },
                            heading: {
                                color: "#a57000",
                                size: "28px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#46500d",
                                size: "23px",
                                fontFamily: "Ceviche-One",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Charm",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/49.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "24px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#701b00",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000056",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Flavour",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/15.jpg'
                            },
                            heading: {
                                color: "#930600",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000036",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/2.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Metal-Mania",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#0000009c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Metal-Mania",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/3.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "24px",
                                fontFamily: "Norican",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#8f2d1f",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Norican",
                                color: "#8f2d1f",
                                opacity: "0.1"
                            }

                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/4.jpg'
                            },
                            heading: {
                                color: "#006815",
                                size: "24px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#783d00",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddda8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 20px 0px",
                                fontFamily: "Flavour",
                                color: "#783d00",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/5.jpg'
                            },
                            heading: {
                                color: "#f30015",
                                size: "28px",
                                fontFamily: "Alex Brush",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Alex Brush",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#0000009c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Alex Brush",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/7.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Flavour",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavour",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#dddddd52",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavour",
                                color: "#000",
                                opacity: "0.1"
                            }

                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/8.jpg'
                            },
                            heading: {
                                color: "#72000e",
                                size: "26px",
                                fontFamily: "Great Vibes",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "24px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#0000009c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/9.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "28px",
                                fontFamily: "Griffy",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f39a08",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#f39a08",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/10.jpg'
                            },
                            heading: {
                                color: "#00ff7b",
                                size: "26px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#0000009c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Norican",
                                color: "#78010d",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/11.jpg'
                            },
                            heading: {
                                color: "#cb1500",
                                size: "30px",
                                fontFamily: "Rochester",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#0000009c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/republic_day/50.jpg'
                            },
                            heading: {
                                color: "#009d1f",
                                size: "24px",
                                fontFamily: "Norican",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#110876",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#f39a08",
                                opacity: "0.1"
                            }

                        },

                        
                    ],
                }
            },
            thanks_giving: {
                thanks_giving: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/thanks_giving/16.jpg'
                            }
                        }
                    ],
                }
            },
            valentine_day: {
                valentine_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/14.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/15.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/valentine_day/17.jpg'
                            }
                        }
                    ],
                }
            },
            wedding_anniversary: {
                wedding_anniversary: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/11.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/12.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/13.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/14.jpg'
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/16.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/17.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/18.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/19.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/20.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/21.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/22.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/23.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/24.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/25.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/26.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/wedding_anniversary/27.jpg'
                            }
                        }
                    ],
                }
            },
            achievement: {
                achievement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/9.jpg'
                            }
                        }
                    ],
                }
            },
            appreciation: {
                appreciation: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/9.jpg'
                            }
                        }
                    ],
                }
            },
            baby_born: {
                baby_born: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/9.jpg'
                            }
                        }
                    ],
                }
            },
            encouragement: {
                encouragement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/9.jpg'
                            }
                        }
                    ],
                }
            },
            job_promotion: {
                job_promotion: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/9.jpg'
                            }
                        }
                    ],
                }
            },
            new_job: {
                new_job: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/9.jpg'
                            }
                        }
                    ],
                }
            },
            retirement: {
                retirement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/8.jpg'
                            }
                        }
                    ],
                }
            },
            work_anniversary: {
                work_anniversary: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/6.jpg'
                            }
                        }
                    ],
                }
            },
            Common: {
                Others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        }
                    ],
                }
            }
        },
        invitation: {
            baby_shower: {
                baby_shower: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            birthday_party: {
                birthday_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            bridal_shower: {
                bridal_shower: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            cocktail_party: {
                cocktail_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            convocation_party: {
                convocation_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            dinner_party: {
                dinner_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            farewell_party: {
                farewell_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            halloween_day: {
                halloween_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Joti-One",
                                type: "overlay",
                                link: 'varnzTemplates/invitation/halloween_day/1.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Joti-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Joti-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Joti-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Butcherman",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/2.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Butcherman",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Creepster",
                                type: "overlay",
                                link: 'varnzTemplates/invitation/halloween_day/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Creepster",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Risque",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#31203578",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Denk-One",
                                type: "patch",
                                link: 'varnzTemplates/invitation/halloween_day/5.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(235, 137, 72)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Denk-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Piedra",
                                type: "patch",
                                link: 'varnzTemplates/invitation/halloween_day/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e1d2d29e",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Piedra",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/7.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Risque",
                                type: "overlay",
                                link: 'varnzTemplates/invitation/halloween_day/8.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Astloch",
                                type: "overlay",
                                link: 'varnzTemplates/invitation/halloween_day/9.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Astloch",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Astloch",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Astloch",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Jolly-Lodger",
                                link: 'varnzTemplates/invitation/halloween_day/10.jpg',
                                minHeight: "650px",
                                type: 'overlay'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Jolly-Lodger",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Ceviche-One",
                                link: 'varnzTemplates/invitation/halloween_day/11.jpg',
                                minHeight: "650px",
                                type: 'overlay'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#2f323991",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Frijole",
                                link: 'varnzTemplates/invitation/halloween_day/12.jpg',
                                minHeight: "650px",
                                type: "patch"
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#423c3959",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Frijole",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Flavors",
                                link: 'varnzTemplates/invitation/halloween_day/13.jpg',
                                minHeight: "650px"
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000061",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },


                    ],
                }
            },
            kitty_party: {
                kitty_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            lunch_party: {
                lunch_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            opening_ceremony: {
                opening_ceremony: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            retirement_party: {
                retirement_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            reunion_party: {
                reunion_party: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            wedding_anniversary: {
                wedding_anniversary: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            wedding_invitation: {
                wedding_invitation: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/1.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                minHeight: "650px",
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/10.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/11.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/12.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/13.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/14.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/15.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/16.jpg',
                                minHeight: "650px"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/invitation/birthday_party/17.jpg',
                                minHeight: "650px"
                            }
                        }
                    ],
                }
            },
            holi: {
                holi: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/1.png',
                                downloadedImage: 'varnzTemplates/holi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/2.png',
                                downloadedImage: 'varnzTemplates/holi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/3.png',
                                downloadedImage: 'varnzTemplates/holi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/5.png',
                                downloadedImage: 'varnzTemplates/holi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/4.png',
                                downloadedImage: 'varnzTemplates/holi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/6.png',
                                downloadedImage: 'varnzTemplates/holi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/7.png',
                                downloadedImage: 'varnzTemplates/holi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/8.png',
                                downloadedImage: 'varnzTemplates/holi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/9.png',
                                downloadedImage: 'varnzTemplates/holi/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/10.png',
                                downloadedImage: 'varnzTemplates/holi/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/11.png',
                                downloadedImage: 'varnzTemplates/holi/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/12.png',
                                downloadedImage: 'varnzTemplates/holi/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/13.png',
                                downloadedImage: 'varnzTemplates/holi/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            international_womens_day: {
                Women: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/1.webp',
                                downloadedImage: `varnzTemplates/women/1.webp`,
                                "alt_tag": "ai generated international womens day greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/2.webp',
                                downloadedImage: `varnzTemplates/women/2.webp`,
                                "alt_tag": "international womens day card"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/3.webp',
                                downloadedImage: `varnzTemplates/women/3.webp`,
                                "alt_tag": "international womens day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/5.webp',
                                downloadedImage: `varnzTemplates/women/5.webp`,
                                "alt_tag": "free ai international womens day card maker"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/4.webp',
                                downloadedImage: `varnzTemplates/women/4.webp`,
                                "alt_tag": "ai generated international womens day cards"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/6.webp',
                                downloadedImage: `varnzTemplates/women/6.webp`,
                                "alt_tag": "happy international womens day cards 2024"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/7.webp',
                                downloadedImage: `varnzTemplates/women/7.webp`,
                                "alt_tag": "happy international womens day"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/8.webp',
                                downloadedImage: `varnzTemplates/women/8.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/9.webp',
                                downloadedImage: `varnzTemplates/women/9.webp`,
                                "alt_tag": "free ai generated international womens day cards"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/10.webp',
                                downloadedImage: `varnzTemplates/women/10.webp`,
                                "alt_tag": "international womens day ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/11.webp',
                                downloadedImage: `varnzTemplates/women/11.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/12.webp',
                                downloadedImage: `varnzTemplates/women/12.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/13.webp',
                                downloadedImage: `varnzTemplates/women/13.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/14.webp',
                                downloadedImage: `varnzTemplates/women/14.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/15.webp',
                                downloadedImage: `varnzTemplates/women/15.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/16.webp',
                                downloadedImage: `varnzTemplates/women/16.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/17.webp',
                                downloadedImage: `varnzTemplates/women/17.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/18.webp',
                                downloadedImage: `varnzTemplates/women/18.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/19.webp',
                                downloadedImage: `varnzTemplates/women/19.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                    ],
                }
            },
            Common: {
                Others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        }
                    ],
                }
            }
        },
        congratulatory: {
            achievement: {
                achievement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/achievement/9.jpg'
                            }
                        }
                    ],
                }
            },
            appreciation: {
                appreciation: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/appreciation/9.jpg'
                            }
                        }
                    ],
                }
            },
            baby_born: {
                baby_born: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/baby_born/9.jpg'
                            }
                        }
                    ],
                }
            },
            encouragement: {
                encouragement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/encouragement/9.jpg'
                            }
                        }
                    ],
                }
            },
            job_promotion: {
                job_promotion: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/job_promotion/9.jpg'
                            }
                        }
                    ],
                }
            },
            new_job: {
                new_job: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/new_job/9.jpg'
                            }
                        }
                    ],
                }
            },
            retirement: {
                retirement: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/retirement/8.jpg'
                            }
                        }
                    ],
                }
            },
            work_anniversary: {
                work_anniversary: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/congratulatory/work_anniversary/6.jpg'
                            }
                        }
                    ],
                }
            },
            Common: {
                Others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        }
                    ],
                }
            }
        },
        religious_holidays: {
            halloween_day: {
                halloween_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Creepster',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/2.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Creepster",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Eater',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/9.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Eater",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Eater",
                                background: "#ddd",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Eater",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            div: {
                                background: "#494c336b",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Butcherman',
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/2.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Butcherman",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Butcherman",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Flavors",
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/5.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#fff",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                link: 'varnzTemplates/wish_someone/halloween_day/3.jpg',
                                type: 'patch'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Metal-Mania',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/7.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(22 23 29 / 85%)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#000",
                                opacity: "0.8"
                            }

                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Griffy',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/8.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                fontWeight: "bold",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                // opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Ceviche-One',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/1.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ecffa869",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '1',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Astloch',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/10.png'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Astloch",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Astloch",
                                background: "#000",
                                fontWeight: 'bold',
                                opacity: 0.5
                            },
                            div: {
                                background: "#0c1727b8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Astloch",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Risque',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/11.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Risque",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#31203578",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Piedra',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/12.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Denk-One',
                                type: 'patch',
                                link: 'varnzTemplates/wish_someone/halloween_day/13.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Denk-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(235 137 72)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Denk-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Flavors',
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/1.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Piedra',
                                type: 'overlay',
                                link: 'varnzTemplates/wish_someone/halloween_day/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: 'Creepster',
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Creepster",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Creepster",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Piedra",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "17px",
                                fontFamily: "Piedra",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e1d2d29e",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Piedra",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Risque",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/8.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Risque",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Risque",
                                background: "#000",
                                fontWeight: 'bold',
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Risque",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Astloch",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/9.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Astloch",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Astloch",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Astloch",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Jolly-Lodger",
                                type: 'overlay',
                                link: 'varnzTemplates/invitation/halloween_day/10.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Jolly-Lodger",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Jolly-Lodger",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Ceviche-One",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/11.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#2f323991",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Frijole",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/12.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Frijole",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#423c3959",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Frijole",
                                color: "#000",
                                opacity: "0.8"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Flavors",
                                type: 'patch',
                                link: 'varnzTemplates/invitation/halloween_day/13.jpg',
                            },
                            heading: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "17px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000061",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            bhai_dooj: {
                bhai_dooj: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/1.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#53167e73",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Rochester",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#956e0042",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/10.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/15.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/16.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/17.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/18.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/19.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/20.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/21.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/22.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/23.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },

                      
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/5.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Cookie",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#4d7fb14f",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Cookie",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/6.jpg'
                            },
                            heading: {
                                color: "#ffb700",
                                size: "36px",
                                fontFamily: "Metal-Mania",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#322b2b63",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Metal-Mania",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/7.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Courgette",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Courgette",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/8.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "36px",
                                fontFamily: "Tangerine",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "30px",
                                fontFamily: "Tangerine",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#85726970",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Tangerine",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/9.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Rochester",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/12.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fff",
                                size: "22px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#8155573d",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/11.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "36px",
                                fontFamily: "Griffy",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#00000052",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wish_someone/bhai_Dooj/13.jpg'
                            },
                            heading: {
                                color: "#ba9a51",
                                size: "36px",
                                fontFamily: "Courgette",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ba9a51",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#a102144f",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px 0px 30px 0px",
                                fontFamily: "Courgette",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },






                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/1.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/2.jpg'
                            },
                            heading: {
                                color: "#858521",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/4.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/5.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e2ab56de",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#67728ca3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/8.jpg'
                            },
                            heading: {
                                color: "#bf0f2f",
                                size: "23px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#403000",
                                size: "18px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d07f48d4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/9.jpg'
                            },
                            heading: {
                                color: "rgb(255 161 25)",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#32524b",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/10.jpg'
                            },
                            heading: {
                                color: "#9f3053",
                                size: "24px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#9f3053",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cfb0b2d9",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            all_saints_day: {
                all_saints_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/9.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/10.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/all_saints_day/11.jpg'
                            }
                        },
                        //   {
                        //     image: {
                        //       is_opaque: 'yes',
                        //       opacity: '0.6',
                        //       background: '#ddd',
                        //       backgroundCover: true,
                        //       link: 'varnzTemplates/religious_holidays/all_saints_day/12.jpg'
                        //     }
                        //   } ,
                        //   {
                        //     image: {
                        //       is_opaque: 'yes',
                        //       opacity: '0.6',
                        //       background: '#ddd',
                        //       backgroundCover: true,
                        //       link: 'varnzTemplates/religious_holidays/all_saints_day/13.jpg'
                        //     }
                        //   }
                    ],
                }
            },
            birth_of_the_guru_granth_sahib: {
                birth_of_the_guru_granth_sahib: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/birth_of_the_guru_granth_sahib/8.jpg'
                            }
                        }
                    ],
                }
            },
            bodhi_day: {
                bodhi_day: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/bodhi_day/9.jpg'
                            }
                        }
                    ],
                }
            },
            dhan_teras: {
                dhan_teras: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/8.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dhan_teras/9.jpg'
                            }
                        }
                    ],
                }
            },

            diwali: {
                diwali: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/img1.jpg'
                            },
                            heading: {
                                color: "#ba9a51",
                                size: "36px",
                                fontFamily: "Sofia",
                                background: "#fe002a",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ba9a51",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "200px 0px 80px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#ba9a51",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/3.jpg'
                            },
                            heading: {
                                color: "#a57623",
                                showHeading: 'no',
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a57623",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px 10px 10px 10px",
                                fontFamily: "Wright Deco Thin",
                                color: "#a57623",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,

                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/2.jpg'
                            },
                            heading: {
                                color: "#a57623",
                                showHeading: 'no',
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a57623",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px 0px 0px 0px",
                                fontFamily: "Wright Deco Thin",
                                color: "#a57623",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/4.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "40px",
                                fontFamily: "Novelia",
                                background: "#000",
                                opacity: 0.5,
                                wordSpacing: '14px',
                            },
                            message: {
                                color: "#fff",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/7.jpg'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#f0af56",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0px",
                                paddingTop: '5px',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/28.jpg'
                            },
                            heading: {
                                color: "#ffc3ef",
                                size: "40px",
                                fontFamily: "Novelia",
                                background: "#000",
                                opacity: 0.5,
                                wordSpacing: '14px',
                                // showHeading: false,
                            },
                            message: {
                                color: "#ffc05f",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "235px 0 0 0",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/35.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/36.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/37.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/29.jpg'
                            },
                            heading: {
                                color: "#fedea1",
                                size: "39px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/22.jpg'
                            },
                            heading: {
                                color: "#04e300",
                                size: "40px",
                                fontFamily: "Novelia",
                                background: "#000",
                                opacity: 0.5,
                                wordSpacing: '14px',
                            },
                            message: {
                                color: "#fceb65",
                                size: "17px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/31.jpg'
                            },
                            heading: {
                                color: "#ffc3ef",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no",
                            },
                            message: {
                                color: "#ffc05f",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/38.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/39.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/40.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/41.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/42.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/43.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/44.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/45.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/46.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/47.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/48.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/49.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/50.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/51.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/52.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/53.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/54.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/55.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/56.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/57.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/58.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },



                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/32.jpg'
                            },
                            heading: {
                                color: "#ffb72f",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no",
                            },
                            message: {
                                color: "#ddd85b",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/33.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f8ed72",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/34.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ed95ff",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/5.jpg'
                            },
                            heading: {
                                color: "#fdac2d",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#fdac2d",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#fdac2d",
                                size: "17px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#fdac2d",
                                opacity: 0.5
                            },
                            div: {
                                background: "",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "13px 60px 2px 60px",
                                marginTop: "74px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fdac2d",
                                opacity: "0.1",
                                lineHeight: '1.1'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/25.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "50px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/6.jpg'
                            },
                            heading: {
                                color: "#fdd835",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fdd835",
                                size: "17px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdd835",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/8.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b32e3c",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/9.jpg'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#b20042",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#b20042",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/diwali/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#f25620",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#f25620",
                                opacity: "0.1"
                            }
                        },

                        
                    ],
                }
            },
            dusshera: {
                dusshera: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images add 
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                link: 'varnzTemplates/religious_holidays/dusshera/18.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/19.jpg'
                            },
                            heading: {
                                color: "#505822",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#f78800",
                                size: "22px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cbd4c9a3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/20.jpg'
                            },
                            heading: {
                                color: "#420d2e",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ec7535",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#feebcf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/21.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Cookie",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Cookie",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Cookie",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/22.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ffffffcc",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/24.jpg'
                            },
                            heading: {
                                color: "#fd0019",
                                size: "25px",
                                fontFamily: "Courgette",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#df8300",
                                size: "20px",
                                fontFamily: "Courgette",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Courgette",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/25.jpg'
                            },
                            heading: {
                                color: "#f26220",
                                size: "35px",
                                fontFamily: "Tangerine",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Tangerine",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Tangerine",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/26.jpg'
                            },
                            heading: {
                                color: "#fac94c",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Griffy",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/27.jpg'
                            },
                            heading: {
                                color: "#a82e15",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#a82e15",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                link: 'varnzTemplates/religious_holidays/dusshera/30.jpg'
                            },
                            heading: {
                                color: "#e51615",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdd575bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/dusshera/31.jpg'
                            },
                            heading: {
                                color: "#280408",
                                size: "25px",
                                fontFamily: "Metal-Mania",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Metal-Mania",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#9a003c91",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Metal-Mania",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Great Vibes",
                                link: 'varnzTemplates/religious_holidays/dusshera/11.jpg'
                            },
                            heading: {
                                color: "#FF0000",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fdb26180",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Rochester",
                                link: 'varnzTemplates/religious_holidays/dusshera/12.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#e74d4c",
                                size: "22px",
                                fontFamily: "Rochester",
                                background: "#000",
                                fontWeight: "bold",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Griffy",
                                link: 'varnzTemplates/religious_holidays/dusshera/13.jpg'
                            },
                            heading: {
                                color: "#995016",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#ab9559",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#441607bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "'Griffy'",
                                link: 'varnzTemplates/religious_holidays/dusshera/14.jpg'
                            },
                            heading: {
                                color: "#63152a",
                                size: "25px",
                                fontFamily: "'Griffy'",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "'Griffy'",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e9c37e9c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "'Griffy'",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Flavors",
                                link: 'varnzTemplates/religious_holidays/dusshera/15.jpg'
                            },
                            heading: {
                                color: "#4e0a04",
                                size: "25px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#4e0a04",
                                size: "20px",
                                fontFamily: "Flavors",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#eac69bc4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Flavors",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Parisienne",
                                link: 'varnzTemplates/religious_holidays/dusshera/16.jpg'
                            },
                            heading: {
                                color: "#dcc89f",
                                size: "25px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "rgb(235 116 41 / 81%)",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#000",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Alex Brush",
                                link: 'varnzTemplates/religious_holidays/dusshera/17.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Alex Brush",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Alex Brush",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#1b15208c",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Alex Brush",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Merienda",
                                link: 'varnzTemplates/religious_holidays/dusshera/1.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#b1162d",
                                size: "20px",
                                fontFamily: "Merienda",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#c27541c4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Charm",
                                link: 'varnzTemplates/religious_holidays/dusshera/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Charm",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#5e2f10",
                                size: "20px",
                                fontFamily: "Charm",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Charm",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "",
                                fontFamily: "Norican",
                                link: 'varnzTemplates/religious_holidays/dusshera/3.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "",
                                fontFamily: "Arizonia",
                                link: 'varnzTemplates/religious_holidays/dusshera/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "28px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "23px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Ceviche-One",
                                link: 'varnzTemplates/religious_holidays/dusshera/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e4a957c7",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Great Vibes",
                                link: 'varnzTemplates/religious_holidays/dusshera/6.jpg'
                            },
                            heading: {
                                color: "#FFF",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#FFF",
                                size: "22px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#3e363685",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Allura",
                                type: "patch",
                                link: 'varnzTemplates/religious_holidays/dusshera/7.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "28px",
                                fontFamily: "Allura",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "23px",
                                fontFamily: "Allura",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#56afc670",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Allura",
                                fontWeight: "bold",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                fontFamily: "Ceviche-One",
                                type: "",
                                link: 'varnzTemplates/religious_holidays/dusshera/8.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "25px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Ceviche-One",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#620007b8",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Ceviche-One",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "patch",
                                fontFamily: "Griffy",
                                link: 'varnzTemplates/religious_holidays/dusshera/9.jpg'
                            },
                            heading: {
                                color: "#df5181",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d0b4b5bf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                type: "overlay",
                                fontFamily: "Sofia",
                                link: 'varnzTemplates/religious_holidays/dusshera/10.jpg'
                            },
                            heading: {
                                color: "#004112",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },

            hanukkah_starts: {
                hanukkah_starts: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/hanukkah_starts/7.jpg'
                            }
                        }
                    ],
                }
            },
            karwa_chauth: {
                karwa_chauth: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/1.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/2.jpg'
                            },
                            heading: {
                                color: "#858521",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "Arizonia",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Arizonia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/4.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Norican",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/5.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e2ab56de",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Great Vibes",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/6.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#fff",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#67728ca3",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/8.jpg'
                            },
                            heading: {
                                color: "#bf0f2f",
                                size: "23px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#403000",
                                size: "18px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d07f48d4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/9.jpg'
                            },
                            heading: {
                                color: "rgb(255 161 25)",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#32524b",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/karwa_chauth/10.jpg'
                            },
                            heading: {
                                color: "#9f3053",
                                size: "24px",
                                fontFamily: "Parisienne",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#9f3053",
                                size: "20px",
                                fontFamily: "Parisienne",
                                fontWeight: "bold",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#cfb0b2d9",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Parisienne",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            mahalaya_amavasya: {
                mahalaya_amavasya: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/mahalaya_amavasya/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/mahalaya_amavasya/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/mahalaya_amavasya/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/mahalaya_amavasya/4.jpg'
                            }
                        }
                    ],
                }
            },
            navaratri_begins: {
                navaratri_begins: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/1.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Sofia",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Sofia",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/2.jpg'
                            },
                            heading: {
                                color: "#858521",
                                size: "25px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Griffy",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Griffy",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/3.jpg'
                            },
                            heading: {
                                color: "#fff",
                                size: "30px",
                                fontFamily: "Arizonia",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "22px",
                                fontFamily: "'Lora', serif",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "'Lora', serif",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/4.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Norican",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "'Lora', serif",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "'Lora', serif",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/5.jpg'
                            },
                            heading: {
                                color: "#9d5a06",
                                size: "25px",
                                fontFamily: "Great Vibes",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Great Vibes",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#e2ab56de",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "'Lora', serif",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/6.jpg'
                            },
                            heading: {
                                color: "#bf0f2f",
                                size: "23px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#403000",
                                size: "18px",
                                fontFamily: "Merienda",
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#d07f48d4",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Merienda",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/navaratri_begins/7.jpg'
                            },
                            heading: {
                                color: "#003612",
                                size: "25px",
                                fontFamily: "Rochester",
                                background: "#000",
                                opacity: 0.5
                            },
                            message: {
                                color: "#003612",
                                size: "20px",
                                fontFamily: "Rochester",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#ddd",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "2px",
                                fontFamily: "Rochester",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            sharad_purnima: {
                sharad_purnima: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sharad_purnima/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sharad_purnima/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sharad_purnima/3.jpg'
                            }
                        }
                    ],
                }
            },
            shmini_atzeret: {
                shmini_atzeret: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/shmini_atzeret/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/shmini_atzeret/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/shmini_atzeret/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/shmini_atzeret/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/shmini_atzeret/5.jpg'
                            }
                        }
                    ],
                }
            },
            simchat_torah: {
                simchat_torah: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/simchat_torah/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/simchat_torah/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/simchat_torah/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/simchat_torah/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/simchat_torah/5.jpg'
                            }
                        }
                    ],
                }
            },
            sukkot_ends: {
                sukkot_ends: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/1.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/2.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/3.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/4.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/5.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/6.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/7.jpg'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/religious_holidays/sukkot_ends/8.jpg'
                            }
                        }
                    ],
                }
            },
            Common: {
                Others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                link: "varnzTemplates/birthday/1.jpg",
                                is_opaque: "yes",
                                opacity: "0.6",
                                background: "#ddd",
                                backgroundCover: true
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            message: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "BrixtonRg",
                                background: "#324455",
                                opacity: 0.5
                            },
                            div: {
                                background: "#58cddf",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "26px",
                                fontFamily: "Exo",
                                color: "#ff1029",
                                opacity: "1.2"
                            }
                        }
                    ],
                }
            }
        },
        not_in_the_list: {
            // not_in_the_list: {

            // }
        }
    },
    quote: {
        good_morning: {
            no_option: {
                good_morning: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            },
        },
        motivational: {
            no_option: {
                motivational: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        love: {
            no_option: {
                love: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        thankyou: {
            no_option: {
                thankyou: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        encouraging: {
            no_option: {
                encouraging: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        leadership: {
            no_option: {
                leadership: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        birthday: {
            no_option: {
                birthday: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        friendship: {
            no_option: {
                friendship: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        funny: {
            no_option: {
                funny: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        gratitude: {
            no_option: {
                gratitude: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        quotes_about_change: {
            no_option: {
                quotes_about_change: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        success: {
            no_option: {
                success: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        happiness: {
            no_option: {
                happiness: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        loneliness: {
            no_option: {
                Loneliness: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        inspirational: {
            no_option: {
                inspirational: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        },
        others: {
            no_option: {
                others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: quoteArray
                }
            }
        }
        
    },
    poem: {
        love: {
            no_option: {
                love: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        nature: {
            no_option: {
                nature: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        social_and_political: {
            no_option: {
                social_and_political: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        epic: {
            no_option: {
                epic: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        haiku_and_japanese: {
            no_option: {
                haiku_and_japanese: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        lyrical: {
            no_option: {
                lyrical: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        sonnet: {
            no_option: {
                sonnet: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        free_verse: {
            no_option: {
                free_verse: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        confessional: {
            no_option: {
                confessional: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        religious_and_spiritual: {
            no_option: {
                religious_and_spiritual: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        humorous: {
            no_option: {
                humorous: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        war_and_peace: {
            no_option: {
                war_and_peace: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        feminist: {
            no_option: {
                feminist: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        erotic: {
            no_option: {
                erotic: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        experimental_and_surreal: {
            no_option: {
                experimental_and_surreal: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        nostalgic_and_reflective: {
            no_option: {
                nostalgic_and_reflective: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        },
        others: {
            no_option: {
                others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: poemArray
                }
            }
        }
    },
    sarcastic_letter: {
        birthday: {
            no_option: {
                birthday: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        complaint: {
            no_option: {
                complaint: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        thank_you: {
            no_option: {
                thank_you: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        apology: {
            no_option: {
                thank_you: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },

        love: {
            no_option: {
                love: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        recommendation: {
            no_option: {
                recommendation: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        condolence: {
            no_option: {
                condolence: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },

        invitation: {
            no_option: {
                invitation: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        holiday: {
            no_option: {
                holiday: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],

                }
            }
        },
        compliment: {
            no_option: {
                compliment: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        protest: {
            no_option: {
                protest: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }
        },
        others: {
            no_option: {
                others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        // new images
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/1.jpg'
                            },
                            heading: {
                                color: "#545643",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#545643",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#fff",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/2.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "20px",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Truetypewriter PolyglOTT",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/3.jpg'
                            },
                            heading: {
                                color: "#29532e",
                                size: "20px",
                                fontFamily: "Truetypewriter PolyglOTT",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#29532e",
                            //     size: "26px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#29532e",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#cdebee',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/4.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5,
                            //     display: 'inline',
                            //     highlight: '#ac9e27',
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/5.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                            // message: {
                            //     color: "#000",
                            //     size: "24px",
                            //     fontFamily: "Granjon LT Std",
                            //     background: "#324455",
                            //     opacity: 0.5
                            // },
                            // div: {
                            //     background: "#ddd",
                            //     is_opaque: "yes",
                            //     borderRadius: "13px",
                            //     padding: "",
                            //     fontFamily: "Granjon LT Std",
                            //     color: "#000",
                            //     opacity: "1.2"
                            // }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/Sarcastic/common/6.jpg'
                            },
                            heading: {
                                color: "#000",
                                size: "26px",
                                circleColor: "#000",
                                fontFamily: "Granjon LT Std",
                                background: "#324455",
                                opacity: 0.5
                            },
                        },
                       
                    ],
                }
            }

        }
    },
    joke: {
        birthday: {
            no_option: {
                birthday: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        // cat: {},
        // cat_puns: {},
        christmas: {
            no_option: {
                christmas: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        coffee: {
            no_option: {
                coffee: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        computer: {
            no_option: {
                computer: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        corny: {
            no_option: {
                corny: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        customer_service: {
            no_option: {
                customer_service: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        daily_life: {
            no_option: {
                daily_life: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        love: {
            no_option: {
                love: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        one_liners: {
            no_option: {
                one_liners: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        political: {
            no_option: {
                political: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        santa: {
            no_option: {
                santa: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        weather: {
            no_option: {
                weather: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        weight_loss: {
            no_option: {
                weight_loss: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        },
        others: {
            no_option: {
                others: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: jokeArray
                }
            }
        }
    }
}

export const AI_CARDS = {
    message: {
        wish_someone: {
            christmas_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    icon: aicards,
                    subtabtabs: {},
                    isNew: true,
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/1.png',
                                background_image: 'varnzTemplates/background_image/background_image.png'
                            },
                            heading: {
                                color: "#080f48",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-1.png",
                                padding: "20px",
                                backgroundSize: "100% 100%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/2.png',
                            },
                            heading: {
                                color: "#080f48",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-2.png",
                                padding: "20px",
                                backgroundSize: "50% 50%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-3.png",
                                padding: "20px",
                                backgroundSize: "50% 50%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-4.png",
                                padding: "70px 60px",
                                backgroundSize: "50% 50%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-5.png",
                                padding: "8px",
                                backgroundSize: "100% 100%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            },
                            isFrame: true,
                            frame: {
                                frame_image: "frame/ai-frame-5.png",
                                padding: "8px",
                                backgroundSize: "100% 100%",
                                backgrundColor: "#fff"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/16.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/17.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/18.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/19.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/20.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/21.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/christmas/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            thanks_giving: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/thanksgiving/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            birthday: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/25.png",
                              link: "varnzTemplates/birthday/medium/25.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/26.png",
                              link: "varnzTemplates/birthday/medium/26.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/27.png",
                              link: "varnzTemplates/birthday/medium/27.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/28.png",
                              link: "varnzTemplates/birthday/medium/28.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/29.png",
                              link: "varnzTemplates/birthday/medium/29.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/30.png",
                              link: "varnzTemplates/birthday/medium/30.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/31.png",
                              link: "varnzTemplates/birthday/medium/31.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/32.png",
                              link: "varnzTemplates/birthday/medium/32.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/33.png",
                              link: "varnzTemplates/birthday/medium/33.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/34.png",
                              link: "varnzTemplates/birthday/medium/34.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/35.png",
                              link: "varnzTemplates/birthday/medium/35.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/36.png",
                              link: "varnzTemplates/birthday/medium/36.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/37.png",
                              link: "varnzTemplates/birthday/medium/37.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/38.png",
                              link: "varnzTemplates/birthday/medium/38.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/39.png",
                              link: "varnzTemplates/birthday/medium/39.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/40.png",
                              link: "varnzTemplates/birthday/medium/40.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/41.png",
                              link: "varnzTemplates/birthday/medium/41.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/42.png",
                              link: "varnzTemplates/birthday/medium/42.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/43.png",
                              link: "varnzTemplates/birthday/medium/43.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/44.png",
                              link: "varnzTemplates/birthday/medium/44.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                          {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                              is_opaque: "yes",
                              opacity: "0.6",
                              background: "#ddd",
                              backgroundCover: true,
                              downloadedImage: "varnzTemplates/birthday/45.png",
                              link: "varnzTemplates/birthday/medium/45.png"
                            },
                            heading: {
                              color: "#ff0064",
                              size: "25px",
                              fontFamily: "Wright Deco Thin",
                              background: "#000",
                              opacity: 0.5,
                              showHeading: "no"
                            },
                            message: {
                              color: "#080f48",
                              size: "18px",
                              fontFamily: "Wright Deco Thin",
                              fontWeight: "bold",
                              background: "#000",
                              opacity: 0.5
                            },
                            div: {
                              background: "#fff",
                              is_opaque: "yes",
                              borderRadius: "13px",
                              padding: "20px",
                              fontFamily: "Wright Deco Thin",
                              color: "#fff",
                              opacity: "0.1"
                            }
                          },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/1.png',
                                link: 'varnzTemplates/birthday/medium/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/2.png',
                                link: 'varnzTemplates/birthday/medium/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/3.png',
                                link: 'varnzTemplates/birthday/medium/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/5.png',
                                link: 'varnzTemplates/birthday/medium/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/4.png',
                                link: 'varnzTemplates/birthday/medium/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/6.png',
                                link: 'varnzTemplates/birthday/medium/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/7.png',
                                link: 'varnzTemplates/birthday/medium/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/8.png',
                                link: 'varnzTemplates/birthday/medium/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/9.png',
                                link: 'varnzTemplates/birthday/medium/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/10.png',
                                link: 'varnzTemplates/birthday/medium/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/11.png',
                                link: 'varnzTemplates/birthday/medium/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/12.png',
                                link: 'varnzTemplates/birthday/medium/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/13.png',
                                link: 'varnzTemplates/birthday/medium/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/15.png',
                                link: 'varnzTemplates/birthday/medium/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/14.png',
                                link: 'varnzTemplates/birthday/medium/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/17.png',
                                link: 'varnzTemplates/birthday/medium/17.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/16.png',
                                link: 'varnzTemplates/birthday/medium/16.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/18.png',
                                link: 'varnzTemplates/birthday/medium/18.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/19.png',
                                link: 'varnzTemplates/birthday/medium/19.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/20.png',
                                link: 'varnzTemplates/birthday/medium/20.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/21.png',
                                link: 'varnzTemplates/birthday/medium/21.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/22.png',
                                link: 'varnzTemplates/birthday/medium/22.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/23.png',
                                link: 'varnzTemplates/birthday/medium/23.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                downloadedImage: 'varnzTemplates/birthday/24.png',
                                link: 'varnzTemplates/birthday/medium/24.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        

                    ],
                }
            },
            new_year: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    icon: aicards,
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/16.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/17.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/18.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/19.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/20.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/21.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/22.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/23.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/24.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/25.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/new_year/26.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            republic_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    icon: aicards,
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/republic_day/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ]
                }
            },
            valentine_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/1.png',
                                downloadedImage: 'varnzTemplates/valentine/1.png',
                                alt_tag: "valentine day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/2.png',
                                downloadedImage: 'varnzTemplates/valentine/2.png',

                                alt_tag: "happy valentine day image"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/3.png',
                                downloadedImage: 'varnzTemplates/valentine/3.png',

                                alt_tag: "happy valentine day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/4.png',
                                downloadedImage: 'varnzTemplates/valentine/4.png',

                                alt_tag: "happy valentine day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/5.png',
                                downloadedImage: 'varnzTemplates/valentine/5.png',

                                alt_tag: "valentine's day cards for couples"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/6.png',
                                downloadedImage: 'varnzTemplates/valentine/6.png',

                                "alt_tag": "heart shape valentine images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/7.png',
                                downloadedImage: 'varnzTemplates/valentine/7.png',

                                "alt_tag": "happy valentine day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/8.png',
                                downloadedImage: 'varnzTemplates/valentine/8.png',

                                "alt_tag": "happy valentine day 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/9.png',
                                downloadedImage: 'varnzTemplates/valentine/9.png',

                                "alt_tag": "heart shape valentine greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/10.png',
                                downloadedImage: 'varnzTemplates/valentine/10.png',

                                "alt_tag": "heart shape valentine greeting card 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/11.png',
                                downloadedImage: 'varnzTemplates/valentine/11.png',

                                "alt_tag": "happy valentine day greeting card 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/12.png',
                                downloadedImage: 'varnzTemplates/valentine/12.png',

                                "alt_tag": "happy valentine day 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/13.png',
                                downloadedImage: 'varnzTemplates/valentine/13.png',
                                "alt_tag": "valentine day wishes for lover"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/14.png',
                                downloadedImage: 'varnzTemplates/valentine/14.png',
                                "alt_tag": "valentine day greeting cards for 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/15.png',
                                downloadedImage: 'varnzTemplates/valentine/15.png',
                                "alt_tag": "ai generated valentines cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/16.png',
                                downloadedImage: 'varnzTemplates/valentine/16.png',
                                "alt_tag": "heart shape valentine greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/17.png',
                                downloadedImage: 'varnzTemplates/valentine/17.png',
                                "alt_tag": "valentine day greeting cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/18.png',
                                downloadedImage: 'varnzTemplates/valentine/18.png',
                                "alt_tag": "valentine day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/19.png',
                                downloadedImage: 'varnzTemplates/valentine/19.png',
                                "alt_tag": "happy valentine day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/20.png',
                                downloadedImage: 'varnzTemplates/valentine/20.png',
                                "alt_tag": "ai generated valentine cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/21.png',
                                downloadedImage: 'varnzTemplates/valentine/21.png',
                                "alt_tag": "ai generated valentines cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/22.png',
                                downloadedImage: 'varnzTemplates/valentine/22.png',
                                "alt_tag": "valentine day greeting cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/23.png',
                                downloadedImage: 'varnzTemplates/valentine/23.png',
                                "alt_tag": "ai generated valentine's cards for couple"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/24.png',
                                downloadedImage: 'varnzTemplates/valentine/24.png',
                                "alt_tag": "happy valentine day 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/25.png',
                                downloadedImage: 'varnzTemplates/valentine/25.png',
                                "alt_tag": "happy valentine day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/26.png',
                                downloadedImage: 'varnzTemplates/valentine/26.png',
                                "alt_tag": "ai generated happy valentine day 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/valentine/medium/27.png',
                                downloadedImage: 'varnzTemplates/valentine/27.png',
                                "alt_tag": "Happy valentine day "
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            rose_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/12.png',
                                downloadedImage: 'varnzTemplates/rose_day/12.png',
                                "alt_tag": "ai generated rose day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/13.png',
                                downloadedImage: 'varnzTemplates/rose_day/13.png',
                                "images": "varnzTemplates/rose_day/medium/13.png",
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/1.png',
                                downloadedImage: 'varnzTemplates/rose_day/1.png',
                                "alt_tag": "ai generated rose day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/2.png',
                                downloadedImage: 'varnzTemplates/rose_day/2.png',
                                "alt_tag": "rose day greetings card 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/3.png',
                                downloadedImage: 'varnzTemplates/rose_day/3.png',
                                "alt_tag": "rose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/4.png',
                                downloadedImage: 'varnzTemplates/rose_day/4.png',
                                "alt_tag": "happy rose day 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/5.png',
                                downloadedImage: 'varnzTemplates/rose_day/5.png',
                                "alt_tag": "rose day greetings images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/6.png',
                                downloadedImage: 'varnzTemplates/rose_day/6.png',
                                "alt_tag": "happy rose day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/7.png',
                                downloadedImage: 'varnzTemplates/rose_day/7.png',
                                "alt_tag": "rose day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/8.png',
                                downloadedImage: 'varnzTemplates/rose_day/8.png',
                                "alt_tag": "rose day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/9.png',
                                downloadedImage: 'varnzTemplates/rose_day/9.png',
                                "alt_tag": "rose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/10.png',
                                downloadedImage: 'varnzTemplates/rose_day/10.png',
                                "alt_tag": "ai generated rose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/rose_day/medium/11.png',
                                downloadedImage: 'varnzTemplates/rose_day/11.png',
                                "alt_tag": "ai generated rose day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            propose_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/1.png',
                                downloadedImage: 'varnzTemplates/propose_day/1.png',
                                "alt_tag": "ai generated propose day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/2.png',
                                downloadedImage: 'varnzTemplates/propose_day/2.png',
                                "alt_tag": "propose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/3.png',
                                downloadedImage: 'varnzTemplates/propose_day/3.png',
                                "alt_tag": "ai generated propose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/4.png',
                                downloadedImage: 'varnzTemplates/propose_day/4.png',
                                "alt_tag": "propose day greetings images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/5.png',
                                downloadedImage: 'varnzTemplates/propose_day/5.png',
                                "alt_tag": "happy propose day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/6.png',
                                downloadedImage: 'varnzTemplates/propose_day/6.png',
                                "alt_tag": "propose day wishes card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/7.png',
                                downloadedImage: 'varnzTemplates/propose_day/7.png',
                                "alt_tag": "propose day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/8.png',
                                downloadedImage: 'varnzTemplates/propose_day/8.png',
                                "alt_tag": "ai generated propose day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/9.png',
                                downloadedImage: 'varnzTemplates/propose_day/9.png',
                                "alt_tag": "ai generated propose day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/propose_day/medium/10.png',
                                downloadedImage: 'varnzTemplates/propose_day/10.png',
                                "alt_tag": "propose day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            chocolate_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/1.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/1.png',
                                "alt_tag": "chocolate day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/2.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/2.png',
                                "alt_tag": "happy chocolate day image"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/3.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/3.png',
                                "alt_tag": "chocolate day card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/4.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/4.png',
                                "alt_tag": "happy chocolate day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/5.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/5.png',
                                "alt_tag": "chocolate day cards for couples"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/6.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/6.png',
                                "alt_tag": "happy chocolate day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/7.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/7.png',
                                "alt_tag": "chocolate day wishes for lover"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/8.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/8.png',
                                "alt_tag": "heart shape chocolates for couples"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/9.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/9.png',
                                "alt_tag": "ai generated chocolates day cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/10.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/10.png',
                                "alt_tag": "chocolates day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/11.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/11.png',
                                "alt_tag": "chocolates day greeting cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/12.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/12.png',
                                "alt_tag": "chocolates day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/13.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/13.png',
                                "alt_tag": "happy chocolates day"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/14.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/14.png',
                                "alt_tag": "ai generated chocolates day cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/15.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/15.png',
                                "alt_tag": "ai generated chocolates day cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/16.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/16.png',
                                "alt_tag": "chocolates day greeting cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/17.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/17.png',
                                "alt_tag": "ai generated chocolates day cards for couple"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/18.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/18.png',
                                "alt_tag": "ai generated chocolates day cards"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/chocolate_day/medium/19.png',
                                downloadedImage: 'varnzTemplates/chocolate_day/19.png',
                                "alt_tag": "chocolates day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }

                    ],
                }
            },
            teddy_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/1.png',
                                "alt_tag": "ai generated teddy day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/2.png',
                                "alt_tag": "teddy day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/3.png',
                                "alt_tag": "ai generated teddy day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/4.png',
                                "alt_tag": "teddy day greeting images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/5.png',
                                "alt_tag": "happy teddy day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/6.png',
                                "alt_tag": "teddy day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/7.png',
                                "alt_tag": "teddy day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/8.png',
                                "alt_tag": "ai generated teddy day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/9.png',
                                "alt_tag": "ai generated teddy day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/teddy_day/10.png',
                                "alt_tag": "teddy day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            promise_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/1.png',
                                "alt_tag": "promise day greetings images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/2.png',
                                "alt_tag": "happy promise day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/3.png',
                                "alt_tag": "promise day greetings card 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/4.png',
                                "alt_tag": "promise day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/5.png',
                                "alt_tag": "ai generated promise day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/6.png',
                                "alt_tag": "ai generated promise day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/7.png',
                                "alt_tag": "promise day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/8.png',
                                "alt_tag": "ai generated promise day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/9.png',
                                "alt_tag": "ai generated promise day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/promise_day/10.png',
                                "alt_tag": "promise day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            hug_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/1.png',
                                "alt_tag": "hug day greetings images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/2.png',
                                "alt_tag": "happy hug day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/3.png',
                                "alt_tag": "hug day greetings card 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/4.png',
                                "alt_tag": "hug day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/5.png',
                                "alt_tag": "ai generated hug day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/6.png',
                                "alt_tag": "ai generated hug day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/7.png',
                                "alt_tag": "ai generated hug day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/8.png',
                                "alt_tag": "hug day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/9.png',
                                "alt_tag": "ai generated hug day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/hug_day/10.png',
                                "alt_tag": "hug day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            kiss_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/1.png',
                                "alt_tag": "ai generated kiss day greeting card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/2.png',
                                "alt_tag": "ai generated kiss day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/3.png',
                                "alt_tag": "kiss day greeting images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/4.png',
                                "alt_tag": "happy kiss day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/5.png',
                                "alt_tag": "kiss day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/6.png',
                                "alt_tag": "ai generated kiss day images"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/7.png',
                                "alt_tag": "kiss day greetings 2024"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/8.png',
                                "alt_tag": "ai generated kiss day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/9.png',
                                "alt_tag": "kiss day greeting card online"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/kiss_day/10.png',
                                "alt_tag": "kiss day greetings card"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            baby_born: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/baby_born/medium/2.png',
                        //         link: 'varnzTemplates/baby_born/14.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/29.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
























                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/1.png',
                                link: 'varnzTemplates/baby_born/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/2.png',
                                link: 'varnzTemplates/baby_born/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/3.png',
                                link: 'varnzTemplates/baby_born/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/4.png',
                                link: 'varnzTemplates/baby_born/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/5.png',
                                link: 'varnzTemplates/baby_born/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/6.png',
                                link: 'varnzTemplates/baby_born/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/7.png',
                                link: 'varnzTemplates/baby_born/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/8.png',
                                link: 'varnzTemplates/baby_born/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/9.png',
                                link: 'varnzTemplates/baby_born/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/10.png',
                                link: 'varnzTemplates/baby_born/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/11.png',
                                link: 'varnzTemplates/baby_born/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/12.png',
                                link: 'varnzTemplates/baby_born/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/baby_born/medium/13.png',
                                link: 'varnzTemplates/baby_born/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            work_anniversary: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/1.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/2.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/3.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/4.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/5.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/6.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/7.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/8.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/9.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/10.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/11.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/12.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/13.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/13.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/14.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/14.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/15.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/15.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/16.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/16.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/17.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/17.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/18.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/18.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/19.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/19.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/20.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/20.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/medium/21.png',
                                downloadedImage: 'varnzTemplates/work_anniversary/21.png',

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        }
                    ],
                }
            },
            happy_lohri: {
                AI_CARDS: {
                    isSubtabs: false,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/20.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/17.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/18.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/19.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/lohri/16.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ]
                }
            },
            diwali: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/1.png',
                                downloadedImage: 'varnzTemplates/diwali/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/2.png',
                                downloadedImage: 'varnzTemplates/diwali/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/3.png',
                                downloadedImage: 'varnzTemplates/diwali/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/5.png',
                                downloadedImage: 'varnzTemplates/diwali/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/4.png',
                                downloadedImage: 'varnzTemplates/diwali/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/6.png',
                                downloadedImage: 'varnzTemplates/diwali/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/7.png',
                                downloadedImage: 'varnzTemplates/diwali/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/8.png',
                                downloadedImage: 'varnzTemplates/diwali/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/9.png',
                                downloadedImage: 'varnzTemplates/diwali/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/10.png',
                                downloadedImage: 'varnzTemplates/diwali/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/11.png',
                                downloadedImage: 'varnzTemplates/diwali/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/12.png',
                                downloadedImage: 'varnzTemplates/diwali/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/13.png',
                                downloadedImage: 'varnzTemplates/diwali/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/15.png',
                                downloadedImage: 'varnzTemplates/diwali/15.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/14.png',
                                downloadedImage: 'varnzTemplates/diwali/14.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },


                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/16.png',
                                downloadedImage: 'varnzTemplates/diwali/16.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/17.png',
                                downloadedImage: 'varnzTemplates/diwali/17.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/18.png',
                                downloadedImage: 'varnzTemplates/diwali/18.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/19.png',
                                downloadedImage: 'varnzTemplates/diwali/19.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/20.png',
                                downloadedImage: 'varnzTemplates/diwali/20.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/21.png',
                                downloadedImage: 'varnzTemplates/diwali/21.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/23.png',
                                downloadedImage: 'varnzTemplates/diwali/23.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/22.png',
                                downloadedImage: 'varnzTemplates/diwali/22.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/diwali/medium/24.png',
                                downloadedImage: 'varnzTemplates/diwali/24.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            bhai_dooj: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#fff',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/1.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/2.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/3.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/4.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/5.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/6.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/7.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/8.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/9.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/bhai_dooj/10.png'
                            },
                            heading: {
                                color: "#f0af56",
                                size: "30px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: 'no',
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "0 12px 0 12px",
                                paddingTop: '',
                                fontFamily: "Wright Deco Thin",
                                color: "#f0af56",
                                opacity: "0.1",
                                maxWidth: '90%'
                            }
                        },



                    ],
                }
            },
            wedding_anniversary: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/28.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/28.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/29.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/29.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/30.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/30.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/31.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/31.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/32.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/32.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/33.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/33.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/34.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/34.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/35.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/35.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/36.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/36.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/37.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/37.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/38.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/38.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/39.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/39.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/40.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/40.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/41.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/41.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/42.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/42.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/43.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/43.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/44.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/44.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/45.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/45.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/46.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/46.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/47.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/47.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/48.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/48.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/49.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/49.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/50.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/50.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/51.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/51.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/52.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/52.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/53.webp',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/53.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },










                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/22.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/22.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/23.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/23.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/24.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/24.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/25.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/25.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/26.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/26.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/27.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/27.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/1.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/2.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/3.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/9.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/4.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/6.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/7.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/8.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/5.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/10.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/11.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/12.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/13.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/15.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/15.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/14.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/14.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/17.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/17.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/16.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/16.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/18.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/18.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/19.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/19.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/20.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/20.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/wedding_anniversary/medium/21.png',
                                downloadedImage: 'varnzTemplates/wedding_anniversary/21.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            holi: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/1.png',
                                downloadedImage: 'varnzTemplates/holi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/2.png',
                                downloadedImage: 'varnzTemplates/holi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/3.png',
                                downloadedImage: 'varnzTemplates/holi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/5.png',
                                downloadedImage: 'varnzTemplates/holi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/4.png',
                                downloadedImage: 'varnzTemplates/holi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/6.png',
                                downloadedImage: 'varnzTemplates/holi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/7.png',
                                downloadedImage: 'varnzTemplates/holi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/8.png',
                                downloadedImage: 'varnzTemplates/holi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/9.png',
                                downloadedImage: 'varnzTemplates/holi/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/10.png',
                                downloadedImage: 'varnzTemplates/holi/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/11.png',
                                downloadedImage: 'varnzTemplates/holi/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/12.png',
                                downloadedImage: 'varnzTemplates/holi/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/holi/medium/13.png',
                                downloadedImage: 'varnzTemplates/holi/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            world_water_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/1.webp',
                                downloadedImage: `varnzTemplates/water/1.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/2.webp',
                                downloadedImage: `varnzTemplates/water/2.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/3.webp',
                                downloadedImage: `varnzTemplates/water/3.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/5.webp',
                                downloadedImage: `varnzTemplates/water/5.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/4.webp',
                                downloadedImage: `varnzTemplates/water/4.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/6.webp',
                                downloadedImage: `varnzTemplates/water/6.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/7.webp',
                                downloadedImage: `varnzTemplates/water/7.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/8.webp',
                                downloadedImage: `varnzTemplates/water/8.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/water/medium/9.webp',
                                downloadedImage: `varnzTemplates/water/9.webp`
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            international_womens_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/1.webp',
                                downloadedImage: `varnzTemplates/women/1.webp`,
                                "alt_tag": "ai generated international womens day greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/2.webp',
                                downloadedImage: `varnzTemplates/women/2.webp`,
                                "alt_tag": "international womens day card"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/3.webp',
                                downloadedImage: `varnzTemplates/women/3.webp`,
                                "alt_tag": "international womens day greetings"
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/5.webp',
                                downloadedImage: `varnzTemplates/women/5.webp`,
                                "alt_tag": "free ai international womens day card maker"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/4.webp',
                                downloadedImage: `varnzTemplates/women/4.webp`,
                                "alt_tag": "ai generated international womens day cards"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/6.webp',
                                downloadedImage: `varnzTemplates/women/6.webp`,
                                "alt_tag": "happy international womens day cards 2024"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/7.webp',
                                downloadedImage: `varnzTemplates/women/7.webp`,
                                "alt_tag": "happy international womens day"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/8.webp',
                                downloadedImage: `varnzTemplates/women/8.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/9.webp',
                                downloadedImage: `varnzTemplates/women/9.webp`,
                                "alt_tag": "free ai generated international womens day cards"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/10.webp',
                                downloadedImage: `varnzTemplates/women/10.webp`,
                                "alt_tag": "international womens day ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/11.webp',
                                downloadedImage: `varnzTemplates/women/11.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/12.webp',
                                downloadedImage: `varnzTemplates/women/12.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/13.webp',
                                downloadedImage: `varnzTemplates/women/13.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/14.webp',
                                downloadedImage: `varnzTemplates/women/14.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/15.webp',
                                downloadedImage: `varnzTemplates/women/15.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/16.webp',
                                downloadedImage: `varnzTemplates/women/16.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/17.webp',
                                downloadedImage: `varnzTemplates/women/17.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/18.webp',
                                downloadedImage: `varnzTemplates/women/18.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/women/medium/19.webp',
                                downloadedImage: `varnzTemplates/women/19.webp`,
                                "alt_tag": "international womens day card ai greetings"

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            earth_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/1.webp',
                                downloadedImage: 'varnzTemplates/earth/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/2.webp',
                                downloadedImage: 'varnzTemplates/earth/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/3.webp',
                                downloadedImage: 'varnzTemplates/earth/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/5.webp',
                                downloadedImage: 'varnzTemplates/earth/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/4.webp',
                                downloadedImage: 'varnzTemplates/earth/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/6.webp',
                                downloadedImage: 'varnzTemplates/earth/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/7.webp',
                                downloadedImage: 'varnzTemplates/earth/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/8.webp',
                                downloadedImage: 'varnzTemplates/earth/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/9.webp',
                                downloadedImage: 'varnzTemplates/earth/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/earth/medium/10.webp',
                                downloadedImage: 'varnzTemplates/earth/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            eid: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/1.webp',
                        //         downloadedImage: 'varnzTemplates/eid/1.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: ForthDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/2.webp',
                        //         downloadedImage: 'varnzTemplates/eid/2.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/3.webp',
                        //         downloadedImage: 'varnzTemplates/eid/3.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/5.webp',
                        //         downloadedImage: 'varnzTemplates/eid/5.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/4.webp',
                        //         downloadedImage: 'varnzTemplates/eid/4.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },

                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/6.webp',
                        //         downloadedImage: 'varnzTemplates/eid/6.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/7.webp',
                        //         downloadedImage: 'varnzTemplates/eid/7.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/8.webp',
                        //         downloadedImage: 'varnzTemplates/eid/8.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: ForthDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/9.webp',
                        //         downloadedImage: 'varnzTemplates/eid/9.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: ForthDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/10.webp',
                        //         downloadedImage: 'varnzTemplates/eid/10.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         link: 'varnzTemplates/eid/medium/11.webp',
                        //         downloadedImage: 'varnzTemplates/eid/11.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/12.webp',
                                downloadedImage: 'varnzTemplates/eid/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/13.webp',
                                downloadedImage: 'varnzTemplates/eid/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/14.webp',
                                downloadedImage: 'varnzTemplates/eid/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/15.webp',
                                downloadedImage: 'varnzTemplates/eid/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/16.webp',
                                downloadedImage: 'varnzTemplates/eid/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/eid/medium/17.webp',
                                downloadedImage: 'varnzTemplates/eid/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            mahashivratri: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/1.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/2.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/3.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/5.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/4.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/6.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/7.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/8.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/9.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mahashivratri/medium/10.webp',
                                downloadedImage: 'varnzTemplates/mahashivratri/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            easter_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/1.webp',
                                downloadedImage: 'varnzTemplates/easter/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/2.webp',
                                downloadedImage: 'varnzTemplates/easter/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/3.webp',
                                downloadedImage: 'varnzTemplates/easter/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/5.webp',
                                downloadedImage: 'varnzTemplates/easter/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/4.webp',
                                downloadedImage: 'varnzTemplates/easter/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/6.webp',
                                downloadedImage: 'varnzTemplates/easter/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/7.webp',
                                downloadedImage: 'varnzTemplates/easter/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/8.webp',
                                downloadedImage: 'varnzTemplates/easter/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/easter/medium/9.webp',
                                downloadedImage: 'varnzTemplates/easter/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            navratri: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/11.webp',
                                downloadedImage: 'varnzTemplates/navratri/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/12.webp',
                                downloadedImage: 'varnzTemplates/navratri/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/13.webp',
                                downloadedImage: 'varnzTemplates/navratri/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/14.webp',
                                downloadedImage: 'varnzTemplates/navratri/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/15.webp',
                                downloadedImage: 'varnzTemplates/navratri/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/1.webp',
                                downloadedImage: 'varnzTemplates/navratri/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/2.webp',
                                downloadedImage: 'varnzTemplates/navratri/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/3.webp',
                                downloadedImage: 'varnzTemplates/navratri/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/5.webp',
                                downloadedImage: 'varnzTemplates/navratri/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/4.webp',
                                downloadedImage: 'varnzTemplates/navratri/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/6.webp',
                                downloadedImage: 'varnzTemplates/navratri/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/7.webp',
                                downloadedImage: 'varnzTemplates/navratri/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/8.webp',
                                downloadedImage: 'varnzTemplates/navratri/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/9.webp',
                                downloadedImage: 'varnzTemplates/navratri/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/navratri/medium/10.webp',
                                downloadedImage: 'varnzTemplates/navratri/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            ram_navami: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/1.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/2.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/3.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/5.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/4.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/6.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/7.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/8.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/ram_navmi/medium/9.webp',
                                downloadedImage: 'varnzTemplates/ram_navmi/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            mother_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/12.webp',
                                downloadedImage: 'varnzTemplates/mother_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/13.webp',
                                downloadedImage: 'varnzTemplates/mother_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/14.webp',
                                downloadedImage: 'varnzTemplates/mother_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/15.webp',
                                downloadedImage: 'varnzTemplates/mother_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/16.webp',
                                downloadedImage: 'varnzTemplates/mother_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/17.webp',
                                downloadedImage: 'varnzTemplates/mother_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/1.webp',
                                downloadedImage: 'varnzTemplates/mother_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/2.webp',
                                downloadedImage: 'varnzTemplates/mother_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/3.webp',
                                downloadedImage: 'varnzTemplates/mother_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/5.webp',
                                downloadedImage: 'varnzTemplates/mother_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/4.webp',
                                downloadedImage: 'varnzTemplates/mother_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/6.webp',
                                downloadedImage: 'varnzTemplates/mother_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/7.webp',
                                downloadedImage: 'varnzTemplates/mother_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/8.webp',
                                downloadedImage: 'varnzTemplates/mother_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/9.webp',
                                downloadedImage: 'varnzTemplates/mother_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/10.webp',
                                downloadedImage: 'varnzTemplates/mother_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/mother_day/medium/11.webp',
                                downloadedImage: 'varnzTemplates/mother_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            father_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/1.webp',
                                downloadedImage: 'varnzTemplates/father_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/2.webp',
                                downloadedImage: 'varnzTemplates/father_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/3.webp',
                                downloadedImage: 'varnzTemplates/father_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/5.webp',
                                downloadedImage: 'varnzTemplates/father_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/4.webp',
                                downloadedImage: 'varnzTemplates/father_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/6.webp',
                                downloadedImage: 'varnzTemplates/father_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/7.webp',
                                downloadedImage: 'varnzTemplates/father_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/8.webp',
                                downloadedImage: 'varnzTemplates/father_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/9.webp',
                                downloadedImage: 'varnzTemplates/father_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/10.webp',
                                downloadedImage: 'varnzTemplates/father_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/11.webp',
                                downloadedImage: 'varnzTemplates/father_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/12.webp',
                                downloadedImage: 'varnzTemplates/father_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/13.webp',
                                downloadedImage: 'varnzTemplates/father_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/14.webp',
                                downloadedImage: 'varnzTemplates/father_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/15.webp',
                                downloadedImage: 'varnzTemplates/father_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/16.webp',
                                downloadedImage: 'varnzTemplates/father_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/17.webp',
                                downloadedImage: 'varnzTemplates/father_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/18.webp',
                                downloadedImage: 'varnzTemplates/father_day/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/19.webp',
                                downloadedImage: 'varnzTemplates/father_day/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/20.webp',
                                downloadedImage: 'varnzTemplates/father_day/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/21.webp',
                                downloadedImage: 'varnzTemplates/father_day/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/22.webp',
                                downloadedImage: 'varnzTemplates/father_day/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/23.webp',
                                downloadedImage: 'varnzTemplates/father_day/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/24.webp',
                                downloadedImage: 'varnzTemplates/father_day/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/25.webp',
                                downloadedImage: 'varnzTemplates/father_day/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/26.webp',
                                downloadedImage: 'varnzTemplates/father_day/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/27.webp',
                                downloadedImage: 'varnzTemplates/father_day/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/28.webp',
                                downloadedImage: 'varnzTemplates/father_day/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/29.webp',
                                downloadedImage: 'varnzTemplates/father_day/29.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/30.webp',
                                downloadedImage: 'varnzTemplates/father_day/30.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/31.webp',
                                downloadedImage: 'varnzTemplates/father_day/31.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/32.webp',
                                downloadedImage: 'varnzTemplates/father_day/32.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/33.webp',
                                downloadedImage: 'varnzTemplates/father_day/33.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/34.webp',
                                downloadedImage: 'varnzTemplates/father_day/34.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/35.webp',
                                downloadedImage: 'varnzTemplates/father_day/35.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/36.webp',
                                downloadedImage: 'varnzTemplates/father_day/36.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/37.webp',
                                downloadedImage: 'varnzTemplates/father_day/37.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/38.webp',
                                downloadedImage: 'varnzTemplates/father_day/38.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/39.webp',
                                downloadedImage: 'varnzTemplates/father_day/39.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/40.webp',
                                downloadedImage: 'varnzTemplates/father_day/40.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/41.webp',
                                downloadedImage: 'varnzTemplates/father_day/41.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/42.webp',
                                downloadedImage: 'varnzTemplates/father_day/42.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/43.webp',
                                downloadedImage: 'varnzTemplates/father_day/43.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/44.webp',
                                downloadedImage: 'varnzTemplates/father_day/44.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/45.webp',
                                downloadedImage: 'varnzTemplates/father_day/45.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/46.webp',
                                downloadedImage: 'varnzTemplates/father_day/46.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/47.webp',
                                downloadedImage: 'varnzTemplates/father_day/47.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/48.webp',
                                downloadedImage: 'varnzTemplates/father_day/48.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/49.webp',
                                downloadedImage: 'varnzTemplates/father_day/49.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/50.webp',
                                downloadedImage: 'varnzTemplates/father_day/50.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/51.webp',
                                downloadedImage: 'varnzTemplates/father_day/51.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/52.webp',
                                downloadedImage: 'varnzTemplates/father_day/52.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/father_day/medium/53.webp',
                                downloadedImage: 'varnzTemplates/father_day/53.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            friendship_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/1.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/2.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/3.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/5.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/4.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/6.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/7.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/8.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/9.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/10.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/11.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/12.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/13.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/14.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/15.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/16.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/17.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/18.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/19.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/20.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/21.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/22.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/23.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/24.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/25.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/26.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/27.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/28.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/friendship_day/medium/29.webp',
                                downloadedImage: 'varnzTemplates/friendship_day/29.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            world_labour_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labour_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/friendship_day/medium/1.webp',
                        //         link: 'varnzTemplates/labour_day/1.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/2.webp',
                        //         link: 'varnzTemplates/labour_day/2.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/3.webp',
                        //         link: 'varnzTemplates/labour_day/3.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/5.webp',
                        //         link: 'varnzTemplates/labour_day/5.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/4.webp',
                        //         link: 'varnzTemplates/labour_day/4.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/labour_day/medium/6.webp',
                                link: 'varnzTemplates/labour_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/7.webp',
                        //         link: 'varnzTemplates/labour_day/7.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/8.webp',
                        //         link: 'varnzTemplates/labour_day/8.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/labour_day/medium/9.webp',
                                link: 'varnzTemplates/labour_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/labour_day/medium/10.webp',
                                link: 'varnzTemplates/labour_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/labour_day/medium/10.webp',
                                link: 'varnzTemplates/labour_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: ForthDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/labour_day/medium/10.webp',
                        //         link: 'varnzTemplates/labour_day/12.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                    ],
                }
            },
            world_no_tobacco_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
















                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/tabacoo_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/2.webp',
                                link: 'varnzTemplates/tabacoo_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/3.webp',
                                link: 'varnzTemplates/tabacoo_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/5.webp',
                                link: 'varnzTemplates/tabacoo_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/tabacoo_day/4.webp'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/6.webp',
                                link: 'varnzTemplates/tabacoo_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/7.webp',
                                link: 'varnzTemplates/tabacoo_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/8.webp',
                                link: 'varnzTemplates/tabacoo_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/9.webp',
                                link: 'varnzTemplates/tabacoo_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/tabacoo_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            world_environment_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/enviroment/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/enviroment/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/2.webp',
                                link: 'varnzTemplates/enviroment/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/3.webp',
                                link: 'varnzTemplates/enviroment/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/5.webp',
                                link: 'varnzTemplates/enviroment/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/4.webp',
                                link: 'varnzTemplates/enviroment/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/6.webp',
                                link: 'varnzTemplates/enviroment/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/7.webp',
                                link: 'varnzTemplates/enviroment/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/8.webp',
                                link: 'varnzTemplates/enviroment/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/9.webp',
                                link: 'varnzTemplates/enviroment/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/enviroment/medium/10.webp',
                                link: 'varnzTemplates/enviroment/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            yoga_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/yoga/medium/10.webp',
                        //         link: 'varnzTemplates/yoga/17.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/29.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/30.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/31.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/32.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/33.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/34.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/35.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/36.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/37.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/38.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/39.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/40.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/41.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/42.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },








                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/tabacoo_day/medium/10.webp',
                                link: 'varnzTemplates/yoga/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/yoga/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/2.webp',
                                link: 'varnzTemplates/yoga/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/3.webp',
                                link: 'varnzTemplates/yoga/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/5.webp',
                                link: 'varnzTemplates/yoga/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/4.webp',
                                link: 'varnzTemplates/yoga/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/6.webp',
                                link: 'varnzTemplates/yoga/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/7.webp',
                                link: 'varnzTemplates/yoga/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/8.webp',
                                link: 'varnzTemplates/yoga/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/9.webp',
                                link: 'varnzTemplates/yoga/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/yoga/medium/10.webp',
                                link: 'varnzTemplates/yoga/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            dalai_lama_birthday: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/9.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/10.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/10.webp',
                                link: 'varnzTemplates/dalai_lama_birthday/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            buddha_purnima: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },










                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/buddha_purnima/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/buddha_purnima/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/buddha_purnima/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                        //         link: 'varnzTemplates/buddha_purnima/5.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/buddha_purnima/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/buddha_purnima/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        // {
                        //     isOwnDesign: true,
                        //     design: FiftheenDesign2,
                        //     image: {
                        //         is_opaque: 'yes',
                        //         opacity: '0.6',
                        //         background: '#ddd',
                        //         backgroundCover: true,
                        //         // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                        //         link: 'varnzTemplates/buddha_purnima/7.webp'

                        //     },
                        //     heading: {
                        //         color: "#ff0064",
                        //         size: "25px",
                        //         fontFamily: "Wright Deco Thin",
                        //         background: "#000",
                        //         opacity: 0.5,
                        //         showHeading: "no"
                        //     },
                        //     message: {
                        //         color: "#080f48",
                        //         size: "18px",
                        //         fontFamily: "Wright Deco Thin",
                        //         fontWeight: 'bold',
                        //         background: "#000",
                        //         opacity: 0.5
                        //     },
                        //     div: {
                        //         background: "#fff",
                        //         is_opaque: "yes",
                        //         borderRadius: "13px",
                        //         padding: "20px",
                        //         fontFamily: "Wright Deco Thin",
                        //         color: "#fff",
                        //         opacity: "0.1"
                        //     }
                        // },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/buddha_purnima/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_laughter_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_laughter_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_laughter_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_laughter_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_laughter_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_laughter_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_laughter_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_laughter_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_laughter_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_laughter_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/world_laughter_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/world_laughter_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            akshaya_tritiya: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/akshaya_tritiya/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/akshaya_tritiya/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/akshaya_tritiya/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/akshaya_tritiya/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/akshaya_tritiya/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/akshaya_tritiya/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/akshaya_tritiya/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/akshaya_tritiya/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/akshaya_tritiya/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            international_no_diet_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/international_no_diet_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/international_no_diet_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/international_no_diet_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/international_no_diet_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/international_no_diet_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_no_diet_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/international_no_diet_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/international_no_diet_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/international_no_diet_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            maharana_pratap_jayanti: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/maharana_pratap_jayanti/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            World_athletics_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/World_athletics_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/World_athletics_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/World_athletics_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/World_athletics_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/World_athletics_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/World_athletics_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/World_athletics_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/14.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/15.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/World_athletics_day/16.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            rabindranath_tagore_jayanti: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/11.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/12.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/13.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no" 
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/14.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/rabindranath_tagore_jayanti/15.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            lord_parshuram_jayanti: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/lord_parshuram_jayanti/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            national_technology_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/national_technology_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/national_technology_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/national_technology_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/national_technology_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/national_technology_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_technology_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/7.webp',
                                link: 'varnzTemplates/national_technology_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/8.webp',
                                link: 'varnzTemplates/national_technology_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            international_nurses_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/international_nurses_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/international_nurses_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/international_nurses_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/international_nurses_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/international_nurses_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_nurses_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            international_day_of_families: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/international_day_of_families/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/international_day_of_families/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/international_day_of_families/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/international_day_of_families/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/international_day_of_families/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_day_of_families/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_day_of_families/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_day_of_families/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            national_brother_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/national_brother_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/national_brother_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/national_brother_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/national_brother_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/national_brother_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_brother_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_brother_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_brother_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            africa_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/africa_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/africa_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/africa_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/africa_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/africa_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/africa_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/africa_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/africa_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_bicycle_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_bicycle_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_bicycle_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_bicycle_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_bicycle_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_bicycle_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_bicycle_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_bicycle_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_bicycle_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            national_day_of_sweden: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/national_day_of_sweden/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/national_day_of_sweden/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/national_day_of_sweden/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/national_day_of_sweden/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/national_day_of_sweden/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_day_of_sweden/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_day_of_sweden/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_day_of_sweden/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/national_day_of_sweden/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_day_against_child_labour: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_day_against_child_labour/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_blood_donor_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_blood_donor_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_blood_donor_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_blood_donor_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_blood_donor_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_blood_donor_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_blood_donor_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_blood_donor_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_blood_donor_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_music_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_music_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_music_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_music_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_music_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_music_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_music_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_music_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_music_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            eid_al_adha: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/21.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/22.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/23.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/24.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/25.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/26.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/27.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/28.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/29.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/30.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/31.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/32.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/33.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/34.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },























                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_adha/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_adha/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/eid_al_adha/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/eid_al_adha/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/eid_al_adha/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_adha/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_adha/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_adha/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_adha/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_adha/10.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            doctors_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/doctors_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/doctors_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/doctors_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/doctors_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/doctors_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/doctors_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/doctors_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/doctors_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/doctors_day/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            canada_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/canada_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/canada_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/canada_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/canada_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/canada_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/canada_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/canada_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/canada_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/canada_day/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            independence_day_usa: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/independence_day_usa/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/independence_day_usa/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/independence_day_usa/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/independence_day_usa/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            puri_rath_yatra: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/puri_rath_yatra/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/puri_rath_yatra/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/puri_rath_yatra/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/puri_rath_yatra/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/puri_rath_yatra/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/puri_rath_yatra/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/puri_rath_yatra/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/puri_rath_yatra/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/puri_rath_yatra/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            islamic_new_year: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/islamic_new_year/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/islamic_new_year/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/islamic_new_year/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/islamic_new_year/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/islamic_new_year/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/islamic_new_year/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/islamic_new_year/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/islamic_new_year/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            muharram_day_of_ashura: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/muharram_day_of_ashura/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            guru_purnima: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/guru_purnima/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/guru_purnima/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/guru_purnima/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/guru_purnima/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/guru_purnima/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/guru_purnima/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/guru_purnima/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/guru_purnima/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            nag_panchmi: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/nag_panchmi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/nag_panchmi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/nag_panchmi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/nag_panchmi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/nag_panchmi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/nag_panchmi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/nag_panchmi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/nag_panchmi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/nag_panchmi/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            youth_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/youth_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/youth_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/youth_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/youth_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/youth_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/youth_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/youth_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/youth_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/youth_day/9.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            happy_janamsahtmi: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/happy_janamsahtmi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            raksha_bandhan: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/raksha_bandhan/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/raksha_bandhan/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/raksha_bandhan/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/raksha_bandhan/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/raksha_bandhan/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/raksha_bandhan/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/raksha_bandhan/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                        
                    ],
                }
            },
            ganesh_chathurthi: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/ganesh_chathurthi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        
                    ],
                }
            },
            dahi_handi: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/dahi_handi/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/dahi_handi/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/dahi_handi/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/dahi_handi/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/dahi_handi/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dahi_handi/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dahi_handi/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dahi_handi/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                    ],
                }
            },
            teacher_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/teacher_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/teacher_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/teacher_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/teacher_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/teacher_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/teacher_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/teacher_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/teacher_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            independence_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/independence_day/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/independence_day/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/independence_day/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/independence_day/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/independence_day/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/13.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/14.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/15.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/16.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/17.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/18.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/19.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/independence_day/20.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            labor_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/labor_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/labor_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/labor_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/labor_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/labor_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/labor_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/labor_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/labor_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            onam: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/onam/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/onam/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/onam/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/onam/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/onam/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/onam/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/onam/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/onam/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_peace_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_peace_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_peace_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_peace_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_peace_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_peace_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_peace_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_peace_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_peace_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            bisexuality_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/bisexuality_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/bisexuality_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/bisexuality_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/bisexuality_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/bisexuality_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bisexuality_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bisexuality_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bisexuality_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            mahatma_gandhi_jayanti: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/mahatma_gandhi_jayanti/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            dussehra: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/dussehra/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/dussehra/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/dussehra/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/dussehra/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/dussehra/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dussehra/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dussehra/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/dussehra/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            sukkot: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/sukkot/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/sukkot/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/sukkot/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/sukkot/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/sukkot/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/sukkot/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/sukkot/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/sukkot/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            karwa_chauth: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/karwa_chauth/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/karwa_chauth/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/karwa_chauth/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/karwa_chauth/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/karwa_chauth/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/karwa_chauth/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/karwa_chauth/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/karwa_chauth/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            halloween: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/halloween/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/halloween/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/halloween/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/halloween/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/halloween/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/halloween/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/halloween/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/halloween/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            all_saints_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/all_saints_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/all_saints_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/all_saints_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/all_saints_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/all_saints_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/all_saints_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/all_saints_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/all_saints_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            chhath_puja: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/chhath_puja/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/chhath_puja/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/chhath_puja/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/chhath_puja/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/chhath_puja/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/chhath_puja/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/chhath_puja/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/chhath_puja/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            international_men_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/international_men_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/international_men_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/international_men_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/international_men_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/international_men_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_men_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_men_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/international_men_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            children_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/children_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/children_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/children_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/children_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/children_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/children_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/children_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/children_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            world_aids_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/world_aids_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/world_aids_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/world_aids_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/world_aids_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/world_aids_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_aids_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_aids_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/world_aids_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            human_rights_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/human_rights_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/human_rights_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/human_rights_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/human_rights_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/human_rights_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/human_rights_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/human_rights_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/human_rights_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            hanukkah: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/hanukkah/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/hanukkah/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/hanukkah/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/hanukkah/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/hanukkah/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hanukkah/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hanukkah/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hanukkah/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            bodhi_day: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/bodhi_day/1.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/bodhi_day/2.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/bodhi_day/3.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/bodhi_day/4.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/bodhi_day/5.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bodhi_day/6.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bodhi_day/7.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/bodhi_day/8.png'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            hindi_diwas: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/hindi_diwas/1.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/hindi_diwas/2.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/hindi_diwas/3.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/hindi_diwas/4.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/hindi_diwas/5.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hindi_diwas/6.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hindi_diwas/7.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hindi_diwas/8.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hindi_diwas/9.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/hindi_diwas/10.jpg'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
            eid_al_maladh: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    icon: aicards,
                    subtabtabs: {},
                    imageTemplate: [
                        
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/friendship_day/medium/1.webp',
                                link: 'varnzTemplates/eid_al_maladh/1.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/2.webp',
                                link: 'varnzTemplates/eid_al_maladh/2.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/3.webp',
                                link: 'varnzTemplates/eid_al_maladh/3.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/5.webp',
                                link: 'varnzTemplates/eid_al_maladh/4.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/4.webp',
                                link: 'varnzTemplates/eid_al_maladh/5.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/6.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/7.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/8.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/9.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/10.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign2,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/11.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                // link: 'varnzTemplates/dalai_lama_birthday/medium/6.webp',
                                link: 'varnzTemplates/eid_al_maladh/12.webp'

                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
        },
        congratulatory: {
            work_anniversary: {
                AI_CARDS: {
                    isSubtabs: false,
                    isNew: true,
                    subtabtabs: {},
                    imageTemplate: [
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/1.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/2.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/3.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/9.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/4.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/6.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/7.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/8.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/5.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/10.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/11.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/12.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/13.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/15.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/14.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },

                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/17.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/16.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/18.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/19.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: ForthDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/20.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                        {
                            isOwnDesign: true,
                            design: FiftheenDesign,
                            image: {
                                is_opaque: 'yes',
                                opacity: '0.6',
                                background: '#ddd',
                                backgroundCover: true,
                                link: 'varnzTemplates/work_anniversary/21.png'
                            },
                            heading: {
                                color: "#ff0064",
                                size: "25px",
                                fontFamily: "Wright Deco Thin",
                                background: "#000",
                                opacity: 0.5,
                                showHeading: "no"
                            },
                            message: {
                                color: "#080f48",
                                size: "18px",
                                fontFamily: "Wright Deco Thin",
                                fontWeight: 'bold',
                                background: "#000",
                                opacity: 0.5
                            },
                            div: {
                                background: "#fff",
                                is_opaque: "yes",
                                borderRadius: "13px",
                                padding: "20px",
                                fontFamily: "Wright Deco Thin",
                                color: "#fff",
                                opacity: "0.1"
                            }
                        },
                    ],
                }
            },
        }
    }
}

export const AI_ValentineDay = {
    rose_day: [
        {
            "images": "varnzTemplates/rose_day/medium/1.png",
            "alt_tag": "ai generated rose day greetings card"
        },
        {
            "images": "varnzTemplates/rose_day/medium/2.png",
            "alt_tag": "rose day greetings card 2024"
        },
        {
            "images": "varnzTemplates/rose_day/medium/3.png",
            "alt_tag": "rose day greeting card online"
        },
        {
            "images": "varnzTemplates/rose_day/medium/4.png",
            "alt_tag": "happy rose day 2024"
        },
        {
            "images": "varnzTemplates/rose_day/medium/5.png",
            "alt_tag": "rose day greetings images"
        },
        {
            "images": "varnzTemplates/rose_day/medium/6.png",
            "alt_tag": "happy rose day images"
        },
        {
            "images": "varnzTemplates/rose_day/medium/7.png",
            "alt_tag": "rose day greetings card"
        },
        {
            "images": "varnzTemplates/rose_day/medium/8.png",
            "alt_tag": "rose day greetings"
        },
        {
            "images": "varnzTemplates/rose_day/medium/9.png",
            "alt_tag": "rose day greeting card online"
        },
        {
            "images": "varnzTemplates/rose_day/medium/10.png",
            "alt_tag": "ai generated rose day greeting card online"
        },
        {
            "images": "varnzTemplates/rose_day/medium/11.png",
            "alt_tag": "ai generated rose day greeting card"
        },
        {
            "images": "varnzTemplates/rose_day/medium/12.png",
            "alt_tag": "ai generated rose day images"
        },
        {
            "images": "varnzTemplates/rose_day/medium/13.png",
            "alt_tag": "happy rose day"
        }
    ],
    propose_day: [
        {
            "images": "varnzTemplates/propose_day/medium/1.png",
            "alt_tag": "ai generated propose day greetings card"
        },
        {
            "images": "varnzTemplates/propose_day/medium/2.png",
            "alt_tag": "propose day greeting card online"
        },
        {
            "images": "varnzTemplates/propose_day/medium/3.png",
            "alt_tag": "ai generated propose day greeting card online"
        },
        {
            "images": "varnzTemplates/propose_day/medium/4.png",
            "alt_tag": "propose day greetings images"
        },
        {
            "images": "varnzTemplates/propose_day/medium/5.png",
            "alt_tag": "happy propose day images"
        },
        {
            "images": "varnzTemplates/propose_day/medium/6.png",
            "alt_tag": "propose day wishes card"
        },
        {
            "images": "varnzTemplates/propose_day/medium/7.png",
            "alt_tag": "propose day greeting card online"
        },
        {
            "images": "varnzTemplates/propose_day/medium/8.png",
            "alt_tag": "ai generated propose day greeting card"
        },
        {
            "images": "varnzTemplates/propose_day/medium/9.png",
            "alt_tag": "ai generated propose day images"
        },
        {
            "images": "varnzTemplates/propose_day/medium/10.png",
            "alt_tag": "propose day greetings"
        }
    ],
    chocolate_day: [
        {
            "images": "varnzTemplates/chocolate_day/medium/1.png",
            "alt_tag": "chocolate day greetings"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/2.png",
            "alt_tag": "happy chocolate day image"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/3.png",
            "alt_tag": "chocolate day card"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/4.png",
            "alt_tag": "happy chocolate day"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/5.png",
            "alt_tag": "chocolate day cards for couples"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/6.png",
            "alt_tag": "happy chocolate day"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/7.png",
            "alt_tag": "chocolate day wishes for lover"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/8.png",
            "alt_tag": "heart shape chocolates for couples"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/9.png",
            "alt_tag": "ai generated chocolates day cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/10.png",
            "alt_tag": "chocolates day greeting card"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/11.png",
            "alt_tag": "chocolates day greeting cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/12.png",
            "alt_tag": "chocolates day greetings"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/13.png",
            "alt_tag": "happy chocolates day"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/14.png",
            "alt_tag": "ai generated chocolates day cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/15.png",
            "alt_tag": "ai generated chocolates day cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/16.png",
            "alt_tag": "chocolates day greeting cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/17.png",
            "alt_tag": "ai generated chocolates day cards for couple"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/18.png",
            "alt_tag": "ai generated chocolates day cards"
        },
        {
            "images": "varnzTemplates/chocolate_day/medium/19.png",
            "alt_tag": "chocolates day greeting card"
        }
    ],
    teddy_day: [
        {
            "images": "varnzTemplates/teddy_day/medium/1.png",
            "alt_tag": "ai generated teddy day greetings card"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/2.png",
            "alt_tag": "teddy day greeting card online"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/3.png",
            "alt_tag": "ai generated teddy day greeting card online"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/4.png",
            "alt_tag": "teddy day greeting images"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/5.png",
            "alt_tag": "happy teddy day images"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/6.png",
            "alt_tag": "teddy day greetings card"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/7.png",
            "alt_tag": "teddy day greeting card online"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/8.png",
            "alt_tag": "ai generated teddy day greeting card"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/9.png",
            "alt_tag": "ai generated teddy day images"
        },
        {
            "images": "varnzTemplates/teddy_day/medium/10.png",
            "alt_tag": "teddy day greetings"
        }
    ],
    promise_day: [
        {
            "images": "varnzTemplates/promise_day/medium/1.png",
            "alt_tag": "promise day greetings images"
        },
        {
            "images": "varnzTemplates/promise_day/medium/2.png",
            "alt_tag": "happy promise day images"
        },
        {
            "images": "varnzTemplates/promise_day/medium/3.png",
            "alt_tag": "promise day greetings card 2024"
        },
        {
            "images": "varnzTemplates/promise_day/medium/4.png",
            "alt_tag": "promise day greeting card online"
        },
        {
            "images": "varnzTemplates/promise_day/medium/5.png",
            "alt_tag": "ai generated promise day greeting card"
        },
        {
            "images": "varnzTemplates/promise_day/medium/6.png",
            "alt_tag": "ai generated promise day greetings card"
        },
        {
            "images": "varnzTemplates/promise_day/medium/7.png",
            "alt_tag": "promise day greeting card online"
        },
        {
            "images": "varnzTemplates/promise_day/medium/8.png",
            "alt_tag": "ai generated promise day greeting card online"
        },
        {
            "images": "varnzTemplates/promise_day/medium/9.png",
            "alt_tag": "ai generated promise day images"
        },
        {
            "images": "varnzTemplates/promise_day/medium/10.png",
            "alt_tag": "promise day greetings"
        }
    ],
    hug_day: [
        {
            "images": "varnzTemplates/hug_day/medium/1.png",
            "alt_tag": "hug day greetings images"
        },
        {
            "images": "varnzTemplates/hug_day/medium/2.png",
            "alt_tag": "happy hug day images"
        },
        {
            "images": "varnzTemplates/hug_day/medium/3.png",
            "alt_tag": "hug day greetings card 2024"
        },
        {
            "images": "varnzTemplates/hug_day/medium/4.png",
            "alt_tag": "hug day greeting card online"
        },
        {
            "images": "varnzTemplates/hug_day/medium/5.png",
            "alt_tag": "ai generated hug day greeting card"
        },
        {
            "images": "varnzTemplates/hug_day/medium/6.png",
            "alt_tag": "ai generated hug day greeting card online"
        },
        {
            "images": "varnzTemplates/hug_day/medium/7.png",
            "alt_tag": "ai generated hug day images"
        },
        {
            "images": "varnzTemplates/hug_day/medium/8.png",
            "alt_tag": "hug day greetings"
        },
        {
            "images": "varnzTemplates/hug_day/medium/9.png",
            "alt_tag": "ai generated hug day greetings card"
        },
        {
            "images": "varnzTemplates/hug_day/medium/10.png",
            "alt_tag": "hug day greeting card online"
        }
    ],
    kiss_day: [
        {
            "images": "varnzTemplates/kiss_day/medium/1.png",
            "alt_tag": "ai generated kiss day greeting card"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/2.png",
            "alt_tag": "ai generated kiss day greeting card online"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/3.png",
            "alt_tag": "kiss day greeting images"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/4.png",
            "alt_tag": "happy kiss day images"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/5.png",
            "alt_tag": "kiss day greeting card online"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/6.png",
            "alt_tag": "ai generated kiss day images"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/7.png",
            "alt_tag": "kiss day greetings 2024"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/8.png",
            "alt_tag": "ai generated kiss day greetings card"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/9.png",
            "alt_tag": "kiss day greeting card online"
        },
        {
            "images": "varnzTemplates/kiss_day/medium/10.png",
            "alt_tag": "kiss day greetings card"
        }

    ],
    valentine_day: [
        {
            "images": "varnzTemplates/valentine/medium/1.png",
            "alt_tag": "valentine day greetings"
        },
        {
            "images": "varnzTemplates/valentine/medium/2.png",
            "alt_tag": "happy valentine day image"
        },
        {
            "images": "varnzTemplates/valentine/medium/3.png",
            "alt_tag": "heart shape valentine greeting card"
        },
        {
            "images": "varnzTemplates/valentine/medium/4.png",
            "alt_tag": "happy valentine day"
        },
        {
            "images": "varnzTemplates/valentine/medium/5.png",
            "alt_tag": "valentine's day cards for couples"
        },
        {
            "images": "varnzTemplates/valentine/medium/6.png",
            "alt_tag": "heart shape valentine images"
        },
        {
            "images": "varnzTemplates/valentine/medium/7.png",
            "alt_tag": "happy valentine day"
        },
        {
            "images": "varnzTemplates/valentine/medium/8.png",
            "alt_tag": "happy valentine day 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/9.png",
            "alt_tag": "heart shape valentine greeting card"
        },
        {
            "images": "varnzTemplates/valentine/medium/10.png",
            "alt_tag": "heart shape valentine greeting card 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/11.png",
            "alt_tag": "happy valentine day greeting card 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/12.png",
            "alt_tag": "happy valentine day 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/13.png",
            "alt_tag": "valentine day wishes for lover"
        },
        {
            "images": "varnzTemplates/valentine/medium/14.png",
            "alt_tag": "valentine day greeting cards for 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/15.png",
            "alt_tag": "ai generated valentines cards"
        },
        {
            "images": "varnzTemplates/valentine/medium/16.png",
            "alt_tag": "heart shape valentine greeting card"
        },
        {
            "images": "varnzTemplates/valentine/medium/17.png",
            "alt_tag": "valentine day greeting cards"
        },
        {
            "images": "varnzTemplates/valentine/medium/18.png",
            "alt_tag": "valentine day greetings"
        },
        {
            "images": "varnzTemplates/valentine/medium/19.png",
            "alt_tag": "happy valentine day"
        },
        {
            "images": "varnzTemplates/valentine/medium/20.png",
            "alt_tag": "ai generated valentine cards"
        },
        {
            "images": "varnzTemplates/valentine/medium/21.png",
            "alt_tag": "ai generated valentines cards"
        },
        {
            "images": "varnzTemplates/valentine/medium/22.png",
            "alt_tag": "valentine day greeting cards"
        },
        {
            "images": "varnzTemplates/valentine/medium/23.png",
            "alt_tag": "ai generated valentine's cards for couple"
        },
        {
            "images": "varnzTemplates/valentine/medium/24.png",
            "alt_tag": "happy valentine day 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/25.png",
            "alt_tag": "happy valentine day"
        },
        {
            "images": "varnzTemplates/valentine/medium/26.png",
            "alt_tag": "ai generated happy valentine day 2024"
        },
        {
            "images": "varnzTemplates/valentine/medium/27.png",
            "alt_tag": "Happy valentine day "
        }
    ]
}



// export const commonTabs = {
//     Love: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/1.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/2.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/3.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/4.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/5.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/6.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/7.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/8.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/9.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/10.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/11.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/12.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/13.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/14.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/15.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/16.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/17.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/love/18.jpg'
//                 },
//                 heading: {
//                     color: "#fff",
//                     size: "20px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//                 message: {
//                     color: "#fff",
//                     size: "18px",
//                     fontFamily: "BrixtonRg",
//                     background: "#000",
//                     opacity: 0.5
//                 },
//             }
//         ],
//     },
//     Abstract: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: ForthDesign,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     // background: '#00000036',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/9.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/10.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/11.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/abstract/12.jpg'
//                 }
//             }
//         ],
//     },
//     Aesthetic: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/4.png'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/5.png'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/9.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/10.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/11.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/12.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/13.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/aesthetic/14.jpg'
//                 }
//             }
//         ],
//     },
//     Flower: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/flower/9.jpg'
//                 }
//             }
//         ],
//     },
//     Happy: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/9.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/happy/10.jpg'
//                 }
//             }
//         ],
//     },
//     Horror: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/8.png'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/9.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/10.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/horror/11.jpg'
//                 }
//             }
//         ],
//     },

//     Nature: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/6.jpg'
//                 }
//             },
//             //   { isOwnDesign:true,
//             //     design: FiftheenDesign2,
//             //     image: {
//             //       is_opaque: 'yes',
//             //       opacity: '0.6',
//             //       background: '#ddd',
//             //       backgroundCover: true,
//             //       link: 'varnzTemplates/common/nature/7.jpg'
//             //     }
//             //   } ,
//             // {
//             //     image: {
//             //         isOwnDesign: true,
//             //         design: FiftheenDesign2,
//             //         is_opaque: 'yes',
//             //         opacity: '0.6',
//             //         background: '#ddd',
//             //         backgroundCover: true,
//             //         link: 'varnzTemplates/common/nature/8.jpg'
//             //     }
//             // },
//             // {
//             //     image: {
//             //         isOwnDesign: true,
//             //         design: FiftheenDesign2,
//             //         is_opaque: 'yes',
//             //         opacity: '0.6',
//             //         background: '#ddd',
//             //         backgroundCover: true,
//             //         link: 'varnzTemplates/common/nature/9.jpg'
//             //     }
//             // },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/10.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/11.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/12.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/13.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/nature/14.jpg'
//                 }
//             }
//         ],
//     },
//     Refreshing: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/refreshing/9.jpg'
//                 }
//             }
//         ],
//     },
//     loneliness: {
//         isSubtabs: false,
//         subtabtabs: {},
//         imageTemplate: [
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/1.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/2.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/3.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/4.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/5.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/6.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/7.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/8.jpg'
//                 }
//             },
//             {
//                 isOwnDesign: true,
//                 design: FiftheenDesign2,
//                 image: {
//                     is_opaque: 'yes',
//                     opacity: '0.6',
//                     background: '#ddd',
//                     backgroundCover: true,
//                     link: 'varnzTemplates/common/sad/9.jpg'
//                 }
//             }
//         ],
//     },
// }
