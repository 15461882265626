import branding from "../image/refer-frnd.png";
import { useState, useRef, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { Result_Overlay } from './commonFunction';
import MasonryDesignComponent from "../masonryDesignComponent";
import Masonry from 'react-masonry-component';
import height from "./commonFunction/height";
const masonryOptions = {
	transitionDuration: 0
};
const imagesLoadedOptions = { background: '.my-bg-image-el' }
const backgroundFrame = "/frame/background/background-frame1.png";


export const FiftheenDesign = ({ data, templateData, title, index, handleClick, clickedTabs, sendData, showTabs, message, shareImage, color, defaultImage, overlay,setDownloadCard,inlineHandleClick,pouupContent,handleMessageChange,bannerThemeResponse,setIsDownload}) => {

	const titleStyle = {
		color: `${data?.heading?.color}`,
		fontStyle: `${data?.heading?.fontStyle}`,
		fontSize: `${data?.heading?.size}px`,
		fontWeight: `${data?.heading?.fontWeight}`,
		fontFamily: `${data?.heading?.fontFamily}`,
		textDecoration: `${data?.heading?.textDecoration}`,
		textAlign: `${data?.heading?.textAlign}`
	}
	const messageStyle = {
		whiteSpace: 'pre-wrap',
		color: `${data?.message?.color}`,
		fontSize: `${data?.message?.size}px`,
		fontWeight: `${data?.message?.fontWeight}`,
		lineHeight: "1.5",
		textAlign: `${data?.message?.textAlign}`,
		fontFamily: `${data?.message?.fontFamily}`,
		fontStyle: `${data?.message?.fontStyle}`,
		textDecoration: `${data?.message?.textDecoration}`
	}

	let showMessage = '';
	if (!shareImage) {
		showMessage = JSON.parse(templateData);
	} else {
		showMessage = templateData
	}
	const captureDivRef1 = useRef(null);
	const alert = useAlert();
	const [downloadText, setDownloadText] = useState({
		text: "Download",
		disabled: false
	});
	const [imageHeight, setImageHeight] = useState('500px');
	const [imageWidth, setImageWidth] = useState('500px');
	const [imageLoaded, setImageLoaded] = useState(true);
	const [showInstagramPost,setShowInstagramPost] = useState(false)

	const handleImageLoad = () => {
        // setImageLoaded(true);
    };
	useEffect(() => {
		if (data.image.link) {
			const backgroundImage = new Image();
			backgroundImage.src = data.image.link ? data.image.link : "500px";
			backgroundImage.onload = () => {
				setImageHeight(backgroundImage.naturalHeight);
			};
		}
	}, [data.image.link]);


	const design = (
		<>
			{/* <Masonry options={masonryOptions} elementType={'div'}> */}
			{/* <MasonryDesignComponent> */}
			<div className="result_items fidtheen-design" >

				{
					!shareImage && <Result_Overlay clickedTabs={clickedTabs} index={index} downloadText={downloadText} setDownloadText={setDownloadText} captureDivRef1={captureDivRef1} title={title} sendData={sendData} showMessage={showMessage} showTabs={showTabs} handleClick={handleClick} data={data} alert={alert} design={"FiftheenDesign"} setDownloadCard={setDownloadCard} isDisabled={!imageLoaded} setShowInstagramPost={setShowInstagramPost} bannerThemeResponse={bannerThemeResponse} setIsDownload={setIsDownload}  />
				}
				{
					data.isFrame ? (
						<div ref={captureDivRef1} className="inner-border-div"
							style={{ padding: `${data?.frame?.padding ? data?.frame?.padding : ""}`, backgroundImage: `${data?.frame?.frame_image ? `url(${data?.frame?.frame_image})` : ""}`, backgroundSize: `${data?.frame?.backgroundSize ? data?.frame?.backgroundSize : ""}` }}
						>
							
							<div className='inner_result_data design_one' style={{ backgroundColor: `${data?.div?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}>
								<div className="result-template-img">
									{/* <div className="template-overlay" style={{background: '#000000b5'}}></div>  */}
									{
										overlay && <div className="template-overlay" style={{ background: '#000', opacity: `${data?.image?.opacity}` }}></div>
									}
									<p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
									{/* <div className="forth-design-image" id="image-div" style={{ backgroundImage: `url(${data.image.link || defaultImage})` }} >

									</div> */}
									<img src={`${data.image.link || defaultImage}`} className='rounded-image fourth-img' width="500px" alt={data.image?.alt_tag} />
								</div>
								<div className="result-inner" style={{ maxWidth: data?.div?.background ? '90%' : '', borderRadius: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderRadius}`, padding: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.padding}`, fontFamily: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.fontFamily}`, color: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.color}`, opacity: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.is_opaque === "yes" ? data?.div?.opacity : 'inherit'}`, borderColor: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderColor}` }}>

									{/* {data?.heading?.showHeading ==='no'? '' :<h3 style={{ color: color ? color : `${data?.heading?.color}`, fontSize: `${data?.heading?.size}`, fontWeight: `${data?.heading?.fontWeight}` }} className="template-heading">{title === "Othersa" ? null : showMessage.heading}</h3>} */}

									<p style={messageStyle} className="template-message">{showMessage.message}</p>
								</div>
							</div>
						</div>
					) : (
						<div ref={captureDivRef1} className='inner_result_data design_one' style={{ backgroundColor: `${data?.div?.background}`, backgroundSize: clickedTabs?.tabs === "NewTemplates" ? '' : `${data.image.backgroundCover && "cover"}` }}>
							<div className={`editor-page edit-page-bg ${pouupContent === "background_image" && 'active'}`} onClick={()=>{inlineHandleClick("background_image")}}><img src={"image/edit-page/eidt-page-bg.png"} /></div>
							<div className="edit-background-frame" style={{ backgroundImage: `url(${data.image?.backgroundFrame?.includes("storage") ? `${process.env.REACT_APP_URL_LOCAL}/${data.image?.backgroundFrame}` : data.image?.backgroundFrame  || backgroundFrame})` }}></div>
							<div className="result-template-img">
						  	 <div className={`editor-page edit-page-image ${pouupContent === "image_edit" && 'active'}`} onClick={()=>{inlineHandleClick("image_edit")}}><img src={"image/edit-page/eidt-page-image.png"} /></div>
						   	 <div className={`result-template-inner-img ${showInstagramPost && 'insta-post'}`}>
								{/* <div className="template-overlay" style={{background: '#000000b5'}}></div>  */}
								{
									overlay && <div className="template-overlay" style={{ background: '#000', opacity: `${data?.image?.opacity}` }}></div>
								}
								{/* <p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p> */}
								{/* <div className="forth-design-image" id="image-div" style={{ backgroundImage: `url(${data.image.link || defaultImage})` }}>

								</div> */}
								{!imageLoaded && <div style={{ width: "100%", height: "100%" }} className="please-wait new-please-wait">
                                                            <div class="skype-loader">
                                                                <div class="dot">
                                                                    <div class="first"></div>
                                                                </div>
                                                                <div class="dot"></div>
                                                                <div class="dot"></div>
                                                                <div class="dot"></div>
                                                            </div>
                                                            {/* <p>Please wait...</p> */}
                                                        </div>}
								<img src={`${data.image.link || defaultImage}`} className='rounded-image fourth-img' width="500px"  alt={data.image?.alt_tag} id="image-div" onLoad={handleImageLoad} />
  							   </div>
							</div>
							<div className="result-inner" style={{ maxWidth: data?.div?.background ? '90%' : '', borderRadius: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderRadius}`, padding: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.padding}`, fontFamily: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.fontFamily}`, color: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.color}`, opacity: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.is_opaque === "yes" ? data?.div?.opacity : 'inherit'}`, borderColor: clickedTabs?.tabs === "NewTemplates" ? '' : `${data?.div?.borderColor}` }}>

								{/* {data?.heading?.showHeading ==='no'? '' :<h3 style={{ color: color ? color : `${data?.heading?.color}`, fontSize: `${data?.heading?.size}`, fontWeight: `${data?.heading?.fontWeight}`,fontFamily: `${data?.heading?.fontFamily}` }} className="template-heading">{title === "Othersa" ? null : showMessage.heading}</h3>} */}

								<p style={messageStyle} className="template-message" id="template-message" contentEditable={pouupContent === "message_edit" && true} onBlur={handleMessageChange}>{showMessage.message}</p>
								<div className={`editor-page edit-page-text ${pouupContent === "message_edit" && 'active'}`} onClick={()=>{inlineHandleClick("message_edit")}}><img src={"image/edit-page/eidt-page-text.png"} /></div>
							</div>
							<p className="branding-logo "><img src={branding} alt="branding" />Created with Varnz.ai</p>
						</div>
					)
				}
			</div>
		</>
	);
	return design;
}