import React, { useState, createContext, lazy, Suspense } from "react";
import { Provider as Reduxx } from "react-redux";
import storage from "./storage";
import { useNavigate, useLocation, Redirect } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import './assets/all.min.css';
import "./bootstrap.css";
import "./App.css";
import "./Main/Components/chat/Css/responsiveMobile.css";
import "react-toastify/dist/ReactToastify.css";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import axios from "axios";
import { ChooseLayout as ChooseLayoutCorporate } from "./Main/Components/landingpage/chooseLayoutCorporate";

import "./Main/Components/signup/Css/business.css";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import auth from "./components/services/auth";
import { unauthenticateConfig } from "./Main/Components/common/Config";
import { positions, Provider, transitions } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import "./Main/Components/Invitation/scss/varnznewstyle.css";
import "././Main/Components/maincss/maincss1/maincss/mainstyle.css";
import "./Main/Components/signup/Css/sign-up.css";
// import varnlaod from "./Main/Components/Invitation/images/Varnz_Logo_v01.png"
import AuthGuard from "./Main/guard/AuthGuard";
import { Sitemap } from "./sitemap/sitemap";
import ShareImage from "./Main/Components/landingpage/share";
export const AuthContext = createContext();

const Homepage1 = lazy(() => import("./Main/Components/landingpage/Home1"));
const UserType = lazy(() => import("./Main/Components/userType/userType"));
const UserTypeNew = lazy(() => import("./Main/Components/userType/userTypeNew"));
const BusinessProfile = lazy(() => import("./Main/Components/businessProfile/businessProfile"));
const MultiStepForm = lazy(() => import("./Main/Components/multiStepForm/multiStepForm"));
const Diwali = lazy(() => import("./Main/Components/landingpage/diwaliInvitation"));
const Bhai_dooj = lazy(() => import("./Main/Components/landingpage/bhai-ddoj"));
const Thanksgiving = lazy(() => import("./Main/Components/landingpage/thanksgiving"));
const SavedTemplates = lazy(() => import("./Main/Components/landingpage/SavedTemplates"));
const SavedTemplatesNew = lazy(() => import("./Main/Components/landingpage/SavedTemplatesNew"));
const Christmas = lazy(() => import("./Main/Components/landingpage/Christmas"));
const LabourDay = lazy(() => import("./Main/Components/landingpage/labour_day"));
const TabacooDay = lazy(() => import("./Main/Components/landingpage/tabacoo_day"));
const YogaDay = lazy(() => import("./Main/Components/landingpage/yoga_day"));
const EnviromentDay = lazy(() => import("./Main/Components/landingpage/enviroment_day"));
const DalaiLambaBirthday = lazy(() => import("./Main/Components/landingpage/dalai_lama_birthday"));
const BuddaPurnima = lazy(() => import("./Main/Components/landingpage/buddha_purnima"));
const WorldLaughterDay = lazy(() => import("./Main/Components/landingpage/world_laughter_day"));
const AkshayaTritiya = lazy(() => import("./Main/Components/landingpage/akshaya_tritiya"));

const Afreica_day = lazy(() => import("./Main/Components/landingpage/africa_day"));
const Athlic_day = lazy(() => import("./Main/Components/landingpage/athlic_day"));
const Brother_day = lazy(() => import("./Main/Components/landingpage/brother_day"));
const Family_cards = lazy(() => import("./Main/Components/landingpage/family_cards"));
const No_diet = lazy(() => import("./Main/Components/landingpage/no_diet"));
const Nurse_day = lazy(() => import("./Main/Components/landingpage/nurse_day"));
const Pratap_jatyanti = lazy(() => import("./Main/Components/landingpage/pratap_jatyanti"));
const National_technology = lazy(() => import("./Main/Components/landingpage/national_technology"));
const Tagore_jayanti = lazy(() => import("./Main/Components/landingpage/tagore_jayanti"));
const Lord_parsuram = lazy(() => import("./Main/Components/landingpage/lord_parsuram"));
const WorldBycycle = lazy(() => import("./Main/Components/landingpage/bycycle_day"));
const WorldEnviromentDay = lazy(() => import("./Main/Components/landingpage/enviroment_day"));
const SwedenDay = lazy(() => import("./Main/Components/landingpage/Sweden_day"));
const ChildLabour = lazy(() => import("./Main/Components/landingpage/Child_labour_day"));
const MusicDay = lazy(() => import("./Main/Components/landingpage/music_day"));
const BloodDonorDay = lazy(() => import("./Main/Components/landingpage/blood_donor_day"));
const DoctorDay = lazy(() => import("./Main/Components/landingpage/doctor_day"));
const CanadaDay = lazy(() => import("./Main/Components/landingpage/canada_day"));
const Independence_day_usa = lazy(() => import("./Main/Components/landingpage/independence _day_usa"));
const PuriRathYatra = lazy(() => import("./Main/Components/landingpage/puri_rath_yatra"));
const IslamicNewYear = lazy(() => import("./Main/Components/landingpage/islamic_new_year"));
const MuharramDayofAshura = lazy(() => import("./Main/Components/landingpage/muharram_day_of_ashura"));
const GuruPurnima = lazy(() => import("./Main/Components/landingpage/guru_purnima"));
const FriendshipDay = lazy(() => import("./Main/Components/landingpage/friendship_day"));
const GaneshChathurthi = lazy(() => import("./Main/Components/landingpage/ganesh_chathurthi"));
const NagPanchmi = lazy(() => import("./Main/Components/landingpage/nag_panchmi"));
const YouthDay = lazy(() => import("./Main/Components/landingpage/youth_day"));
const IndependenceDay = lazy(() => import("./Main/Components/landingpage/independence_day"));
const RakshaBandhan = lazy(() => import("./Main/Components/landingpage/raksha_bandhan"));
const HappyJanamsahtmi = lazy(() => import("./Main/Components/landingpage/happy_janamsahtmi"));
const DahiHandi = lazy(() => import("./Main/Components/landingpage/dahi_handi"));
const TeacherDay = lazy(() => import("./Main/Components/landingpage/teacher_day"));

const Onam = lazy(() => import("./Main/Components/landingpage/Onam"));
const HindiDiwas = lazy(() => import("./Main/Components/landingpage/hindi_diwas"));
const Id_e_Milad = lazy(() => import("./Main/Components/landingpage/eid-e-milad"));
const World_peaceDay = lazy(() => import("./Main/Components/landingpage/world_peace_day"));
const Bisexuality_Day = lazy(() => import("./Main/Components/landingpage/International-Celebrate-Bisexuality-Day"));
const MahatmaGandi = lazy(() => import("./Main/Components/landingpage/Mahatma-Gandhi-Jayanti"));
const Dussehra = lazy(() => import("./Main/Components/landingpage/Dussehra"));
const Sukkot = lazy(() => import("./Main/Components/landingpage/sukkot"));
const Karwa_Chauth = lazy(() => import("./Main/Components/landingpage/Karwa-Chauth"));
const Halloween = lazy(() => import("./Main/Components/landingpage/Halloween"));
const All_Saints = lazy(() => import("./Main/Components/landingpage/All-Saints"));
const Chhath_Puja = lazy(() => import("./Main/Components/landingpage/Chhath-Puja"));
const Mens_day = lazy(() => import("./Main/Components/landingpage/International-Mens-Day"));
const Childre_day = lazy(() => import("./Main/Components/landingpage/World-Childrens-day"));
const Aids_day = lazy(() => import("./Main/Components/landingpage/World-AIDS-Day"));
const HumanRightsDay = lazy(() => import("./Main/Components/landingpage/Human-Rights-Day"));
const Hanukkah = lazy(() => import("./Main/Components/landingpage/Hanukkah"));
const BodhiDay = lazy(() => import("./Main/Components/landingpage/Bodhi-Day"));
const Labor = lazy(() => import("./Main/Components/landingpage/labor_day"));

const AIPage = lazy(() => import("./Main/Components/landingpage/AIPage/AiPage"));
const AIPagePreview = lazy(() => import("./Main/Components/landingpage/AIPage/AiPage_preview"));


















const NewYear = lazy(() => import("./Main/Components/landingpage/NewYear"));
const RepublicDay = lazy(() => import("./Main/Components/landingpage/republicDay"));
const Lohri = lazy(() => import("./Main/Components/landingpage/Lohri"));
const Birthday = lazy(() => import("./Main/Components/landingpage/Birthday"));
const WorkAnniversary = lazy(() => import("./Main/Components/landingpage/WorkAnniversary"));
const WeddingAnniversary = lazy(() => import("./Main/Components/landingpage/WeddingAnniversary"));
const ValentineDay = lazy(() => import("./Main/Components/landingpage/valentineDay"));
const DefaultTemplates = lazy(() => import("./Main/Components/landingpage/allTemplate"));
const Aicards = lazy(() => import("./Main/Components/landingpage/Aicards"));
const Blogdetails = lazy(() => import("./Main/Components/landingpage/Blogdetails"));
const Blog = lazy(() => import("./Main/Components/landingpage/Blog"));
const BlogCategory = lazy(() => import("./Main/Components/landingpage/BlogCategory"));
const Babyborn = lazy(() => import("./Main/Components/landingpage/Babyborn"));
const BusinessEditTemplate = lazy(() => import("./Main/Components/landingpage/businessEditTemplate/businessEditTemplate"));


const FriendListMessage = lazy(() => import("./Main/Components/Invitation/FriendListMessage"));
const Notification = lazy(() => import("./Main/Components/Invitation/Notification"));
const CustomPrompt = lazy(() => import("./Main/Components/chat/CustomPrompt"));
const FavoriteList = lazy(() => import("./Main/Components/chat/favorite/FavoriteList"));
const PaymentHistory = lazy(() => import("./Main/Components/Invitation/paymentHistory"));
const MessageHistory = lazy(() => import("./Main/Components/Invitation/messageHistory"));
const Notificationfree = lazy(() => import("./Main/Components/common/Notificationfree"));
const Templates = lazy(() => import("./Main/Components/templates/templates"));
const BusinessLandingPage = lazy(() => import("./Main/Components/businessLandingPage/business"));
const QuoteLandingPage = lazy(() => import("./Main/Components/quoteLandingPage/quoteLandingPage"));
const PoemLandingPage = lazy(() => import("./Main/Components/poemLandingPage/poemLandingPage"));
const JokeLandingPage = lazy(() => import("./Main/Components/jokeLandingPage/jokeLandingPage"));
const SarcasticLandingPage = lazy(() => import("./Main/Components/sarcasticLandingPage/sarcasticLandingPage"));
const GreetingLandingPage = lazy(() => import("./Main/Components/greetingLandingPage/greetingLandingPage"));
const PersonalPage = lazy(() => import("./Main/Components/greetingLandingPage/personalPage"));




const BlogPreview = lazy(() => import("./Main/Components/landingpage/PreviewBlog"));
const ChatComponent = lazy(() => import("./Main/Components/chat/ChatComponent"));
const Completeprofile1 = lazy(() => import("./Main/Components/signup/CompleteProfile1"));
const Layout = lazy(() => import("./Main/Components/Invitation/Layout"));
const Layout2 = lazy(() => import("./Main/Components/Invitation/Layout2"));
const Friend = lazy(() => import("./Main/Components/Invitation/friends"));
const Invitation = lazy(() => import("./Main/Components/Invitation/Invitation"));
const Reminders = lazy(() => import("./Main/Components/Invitation/Reminders"));
const Profile = lazy(() => import("./Main/Components/Invitation/Profile"));
const Forgot = lazy(() => import("./Main/Components/signup/Forgot"));
const CreatePassword = lazy(() => import("./Main/Components/signup/Createpassword"));
const Passwordupdate = lazy(() => import("./Main/Components/signup/Passwordupdate"));
const OtpForgotPassword = lazy(() => import("./Main/Components/signup/OtpForgotPassword"));
const Sucesscompleteprofile = lazy(() => import("./Main/Components/signup/Sucesscompleteprofile"));
const SignUp = lazy(() => import("./components/pages/SignUp"));
const Login = lazy(() => import("./components/pages/Login"));
const Otp = lazy(() => import("./components/pages/Otp"));
const ChooseLayout = lazy(() => import("./Main/Components/landingpage/chooseLayout"));
const Edit = lazy(() => import("./Main/Components/landingpage/eidts-old"));
const Edit1 = lazy(() => import("./Main/Components/landingpage/eidts-old copy"));
const NotFound = lazy(() => import("./components/pages/NotFound"));
const Holi = lazy(() => import("./Main/Components/landingpage/Holi"));
const WomenDay = lazy(() => import("./Main/Components/landingpage/women_day"));
const WomenDayNew = lazy(() => import("./Main/Components/landingpage/women_day_new"));
const WaterDay = lazy(() => import("./Main/Components/landingpage/water_days"));
const EarthDay = lazy(() => import("./Main/Components/landingpage/earth_day"));
const MahaShivRatri = lazy(() => import("./Main/Components/landingpage/maha_shivaratri"));
const Easterday = lazy(() => import("./Main/Components/landingpage/Easter_day"));
const Eid = lazy(() => import("./Main/Components/landingpage/Eid"));
const RamNavami = lazy(() => import("./Main/Components/landingpage/Ram_navami"));
const Navratri = lazy(() => import("./Main/Components/landingpage/navratri"));
const Mother_day = lazy(() => import("./Main/Components/landingpage/mother_day"));
const Father_day = lazy(() => import("./Main/Components/landingpage/father_day"));
const ValantineDayNew = lazy(() => import("./Main/Components/landingpage/valantineDayNew"));
const Friendship_day = lazy(() => import("./Main/Components/landingpage/friendship-day"));
const InlineedittingNew = lazy(() => import("./Main/Components/landingpage/InlineEditor"));

const MaintenancePage = lazy(() => import("./Main/Components/landingpage/MaintenancePage"));


// const WaterDay = lazy(() => import("./Main/Components/landingpage/women_day  "));




const USER_DETAILS = process.env.REACT_APP_SEND_USER_DETAILS;

export default function App() {

  const seachQuery = useSearchParams()[0];
  const referenceNum = seachQuery.get("reference");
  const [isLoading, setLoading] = useState(true);
  const [NotificationNum, setNotificationNum] = useState();
  const [ganderImage, setganderImage] = useState("");
  const Navigate = useNavigate();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [UserDetailsAll, setUserDetailsAll] = useState();
  const [Conditionfreemessage, setConditionfreemessage] = useState("");
  const [id, setId] = useState("")
  const [data, setData] = useState({});
  const senData1 = (res) => {
    setData(res)
  }
  const sendData = (data) => {
    setId(data)
  }

  // const CustomAlertTemplate = ({ style, options, message, close }) => {
  //   return (
  //     <div id={`custom-alert ${options.id}`} className={`custom-alert ${options.class}`} style={{ ...style, background: options.color }}>
  //       {options.selector && <span>{options.selector}</span>}
  //       {message}
  //       <button onClick={close}>Close</button>
  //     </div>
  //   );
  // };


  // const Loader = () => {
  //   const l = (
  //     <>
  //       <div className="Varnz_laod">
  //         <img src={varnlaod} alt="" />
  //       </div>
  //     </>
  //   );
  //   return l;
  // }
  const location = useLocation();


  const { pathname } = useLocation();
  useEffect(() => {
    if (auth.isLogin()) {
      const getUser = async () => {
        try {
          var alll = JSON.parse(localStorage.getItem("guitar_user"));
          toast.loading("Please wait..");
          const datasend = {
            uid: alll.uid,
          };
          const response = await axios.post(
            USER_DETAILS,
            datasend,
            unauthenticateConfig()
          );
          setLoading(false);
          toast.dismiss();
          setUserDetailsAll(response.data.data);
          let datsssa = {
            email: alll.email,
            uid: alll.uid,
            dynamicUserToken: response.data.data.social_login === true ? response.data.token : response.data.token,
            refCode: response.data.data.referral_code,
            is_profile_complete: response.data.data.is_profile_complete,
            gender: response.data.data.gender,
            social_login: response.data.data.social_login
          };
          auth.loginUser(datsssa);
          if (response.data.data.is_profile_complete == 0) {
          } else {
            if (referenceNum) {
              Navigate(`/chat?reference=${referenceNum}`);
              return false;
            }
          }
        } catch (error) {
          toast.dismiss();
          setLoading(false);
          auth.logout();
          Navigate("/");
        }
      };
      getUser();
      setConditionfreemessage("")
    } else {
      setLoading(false);
      setConditionfreemessage("freeCondition")

    }
  }, [isLoading]);


  //  no index changes
  useEffect(() => {
    if (process.env.REACT_APP_URL_FRONT_TEST === "https://varnz-apps-frontend.webgarh.net") {
      const metaTags = document.getElementsByTagName("META");
      const metaArray = Array.from(metaTags); // Convert HTMLCollection to array
      metaArray.forEach((metaTag) => {
        metaTag.content = "noindex";
      });
      // <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
      document.querySelector('meta[name="viewport"]').setAttribute('content', "width=device-width, initial-scale=1");
    }

    setTimeout(() => {
      if (process.env.REACT_APP_URL_FRONT_TEST === "https://varnz-apps-frontend.webgarh.net") {
        const metaTags = document.getElementsByTagName("META");
        const metaArray = Array.from(metaTags); // Convert HTMLCollection to array
        metaArray.forEach((metaTag) => {
          metaTag.content = "noindex";
        });
        // <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        document.querySelector('meta[name="viewport"]').setAttribute('content', "width=device-width, initial-scale=1");
      }
    }, 500);
  });

  useEffect(() => {
    const { pathname } = location;
    if (pathname.startsWith("/invite/")) {
      const inviteCode = pathname.split("/")[2];
      const email = pathname.split("/")[3];
      if (auth.isLogin()) {
        Navigate("/chat");
      } else {
        Navigate("/signup", {
          state: {
            inviteCodegetbyurl: inviteCode,
            emailgetbyurl: email,
            invitetype: "invite",
          },
        });
      }
    }
  }, [location]);

  const navigate = useNavigate();  
  useEffect(() => {
    // Check if the pathname ends with a trailing slash
    if (pathname.endsWith('/')) {
      // Redirect to the pathname without the trailing slash
      navigate(pathname.slice(0, -1));
    }
  }, [pathname, navigate]);
  const handleNotificationData = (data) => {
    setNotificationNum(data);
  };
  const options = {
    timeout: 3000,
    position: positions.TOP_CENTER,
    offset: "30px",
    transition: transitions.SCALE,
    id: id
  };

  const genderwiseImage = (res) => {
    setganderImage(res);
  };

  useEffect(() => {
    function handleResize() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'false';

  if(isMaintenanceMode){
    return (
      <div className="App">
        {isMaintenanceMode && <MaintenancePage />}
      </div>
    );
  }


  if (isLoading) {
    return (
      <div className="loadingio-spinner-ellipsis-zwgxh366xl">
        <div className="ldio-iteqphrug9j">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Suspense fallback={<div class='loader-blog'>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--dot'></div>
          <div class='loader--text'></div>
        </div>}>
          <Reduxx store={storage}>
            <AuthContext.Provider value={{ UserDetailsAll, UserDetailsAll }}>
              <div
                className="main_kmf"
                style={{ height: `calc(${windowHeight}px - 1px)` }}
              >
                <div className="App">
                  <Provider id={id ? id : "dynamic-parent-" + "logout"} className={id ? id : "dynamic-parent-" + "logout"} template={AlertTemplate} {...options}>

                    <Routes>
                      <Route element={<UnAuthenticatedRoute Routes />}>
                        <Route
                          element={
                            <Layout data={NotificationNum} FressChatStore={1} Conditionfreemessage={Conditionfreemessage} />
                          }
                        >
                          <Route
                            path="/chatfree"
                            element={
                              <ChatComponent
                                freeChat="freeChat"
                                className="freechatclassname"
                                sendData={sendData}
                              />
                            }
                          />
                          <Route
                            path="/notificationfree"
                            element={
                              <Notificationfree
                              />
                            }
                          />
                        </Route>
                      </Route>

                      <Route element={<Layout2 />}>
                        <Route path="/signup" element={<SignUp
                          sendData={sendData} />} />
                        <Route path="/login" element={<Login sendDataId={sendData} />} />
                        <Route path="/edit" element={<Edit data={data} sendData={sendData} />} />
                        <Route path="/choose-layout" element={<ChooseLayout />} />
                        <Route path="/choose-layouts" element={<ChooseLayoutCorporate />} />
                        <Route path="/template-edit" element={<BusinessEditTemplate />} />
                        <Route path="/diwali" element={<Diwali data={data} sendData={sendData} />} />

                        <Route path="/bhai-dooj" element={<Bhai_dooj data={data} sendData={sendData} />} />

                        <Route path="/thanks-giving" element={<Thanksgiving data={data} sendData={sendData} />} />
                      {/*  <Route path="/ai-generated-thanksgiving-greeting-cards" element={<Thanksgiving data={data} sendData={sendData} />} />  */}


                        <Route path="/christmas" element={<Christmas data={data} sendData={sendData} />} />
                        {/* <Route path="/ai-generated-christmas-greeting-cards" element={<Christmas data={data} sendData={sendData} />} /> */}

                        <Route path="/ai-generated-labour-day-greeting-cards" element={<LabourDay data={data} sendData={sendData} />} />
                      {/*   <Route path="/ai-generated-rabindranath-tagore-jayanti-cards" element={<Tagore_jayanti data={data} sendData={sendData} />} /> */}
                          

                        {/* <Route path="/ai-generated-wedding-anniversary-greeting-cards" element={<WeddingAnniversary data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-baby-born-greeting-cards" element={<Babyborn data={data} sendData={sendData} />} />
                        <Route path="/lohri" element={<Lohri data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-ram-navami-greeting-cards" element={<RamNavami data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-laughter-day-cards" element={<WorldLaughterDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-no-diet-day-greeting-cards" element={<No_diet data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-athletics-day-greeting-cards" element={<Athlic_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-rabindranath-tagore-jayanti-cards" element={<Tagore_jayanti data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-national-technology-cards" element={<National_technology data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-international-nurses-day-cards" element={<Nurse_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-international-day-of-families-cards" element={<Family_cards data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-national-brother-day-cards" element={<Brother_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-africa-day-greeting-cards" element={<Afreica_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-lord-parshuram-jayanti-greeting-cards" element={<Lord_parsuram data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-environment-day-greeting-cards" element={<EnviromentDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-buddha-purnima-greeting-cards" element={<BuddaPurnima data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-akshaya-tritiya-cards" element={<AkshayaTritiya data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-mothers-day-greeting-cards" element={<Mother_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-new-year-greeting-cards" element={<NewYear data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-maha-shivratri-greeting-cards" element={<MahaShivRatri data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-holi-day-greeting-cards" element={<Holi data={data} sendData={sendData} />} /> */}


                        {/* <Route path="/ai-generated-work-anniversary-greeting-cards" element={<WorkAnniversary data={data} sendData={sendData} />} /> */}
                        {/* <Route path="/valentine-day-new" element={<ValantineDayNew data={data} sendData={sendData} />} /> */}
                        {/* <Route path="/ai-generated-navratri-greeting-cards" element={<Navratri data={data} sendData={sendData} />} /> */}
                        {/* <Route path="/ai-generated-valentines-day-greeting-cards" element={<ValentineDay data={data} sendData={sendData} />} /> */}
                        {/* <Route path="/ai-generated-hindi-diwas-greeting-cards" element={<HindiDiwas data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-mawlid-al-nabi-cards" element={<Id_e_Milad data={data} sendData={sendData} />} /> */}
                        {/* start comment dynamic page */}

                        {/* <Route path="/ai-generated-world-no-tobacco-day-greeting-cards" element={<TabacooDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-eid-greeting-cards" element={<Eid data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-bicycle-day-greeting-cards" element={<WorldBycycle data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-environment-day-greeting-cards" element={<EnviromentDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-national-day-of-sweden-greeting-cards" element={<SwedenDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-maharana-pratap-jayanti-cards" element={<Pratap_jatyanti data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-day-against-child-labour-cards" element={<ChildLabour data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-blood-donor-day-greeting-cards" element={<BloodDonorDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-fathers-day-greeting-cards" element={<Father_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-yoga-day-greeting-cards" element={<YogaDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-music-day-greeting-cards" element={<MusicDay data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-doctor-day-greeting-cards" element={<DoctorDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-canada-day-greeting-cards" element={<CanadaDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-usa-independence-day-greeting-cards" element={<Independence_day_usa data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-dalai-lama-birthday-day-greeting-cards" element={<DalaiLambaBirthday data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-puri-rath-yatra-greeting-cards" element={<PuriRathYatra data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-islamic-new-year-greeting-cards" element={<IslamicNewYear data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-muharram-greeting-cards" element={<MuharramDayofAshura data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-guru-purnima-greeting-cards" element={<GuruPurnima data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-friendship-day-greeting-cards" element={<Friendship_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-nag-panchami-greeting-cards" element={<NagPanchmi data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-youth-day-greeting-cards" element={<YouthDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-indian-independence-day-greeting-cards" element={<IndependenceDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-raksha-bandhan-cards" element={<RakshaBandhan data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-janmashtami-greeting-cards" element={<HappyJanamsahtmi data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-dahi-handi-greeting-cards" element={<DahiHandi data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-labor-day-greeting-cards" element={<Labor data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-onam-greeting-cards" element={<Onam data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-teacher-day-greeting-cards" element={<TeacherDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-ganesh-chaturthi-greeting-cards" element={<GaneshChathurthi data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-world-peace-day-cards" element={<World_peaceDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-bisexuality-day-cards" element={<Bisexuality_Day data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-mahatma-gandhi-jayanti-greeting-cards" element={<MahatmaGandi data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-navratri-greeting-cards" element={<Navratri data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-dussehra-greeting-cards" element={<Dussehra data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-sukkot-greeting-cards" element={<Sukkot data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-karwa-chauth-greeting-cards" element={<Karwa_Chauth data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-halloween-greeting-cards" element={<Halloween data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-diwali-greeting-cards" element={<Diwali data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-all-saints-day-greeting-cards" element={<All_Saints data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-bhai-dooj-greeting-cards" element={<Bhai_dooj data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-chhath-puja-greeting-cards" element={<Chhath_Puja data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-international-men-day-cards" element={<Mens_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-children-day-cards" element={<Childre_day data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-world-aids-day-cards" element={<Aids_day data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-bodhi-day-greeting-cards" element={<BodhiDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-human-rights-day-cards" element={<HumanRightsDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-hanukkah-greeting-cards" element={<Hanukkah data={data} sendData={sendData} />} /> */}

                        {/* end comment dynamic page */}




                        

                        
                        {/* <Route path="/ai-generated-friendship-day-greeting-cards" element={<FriendshipDay data={data} sendData={sendData} />} /> */}

                        

                        <Route path="/page/:ai_page" element={<AIPage data={data} sendData={sendData} />} />
                        <Route path="/ai-page-preview/:ai_page" element={<AIPagePreview data={data} sendData={sendData} />} />
                        <Route path="/new-year" element={<NewYear data={data} sendData={sendData} />} />

                        {/* <Route path="/republic-day" element={<RepublicDay data={data} sendData={sendData} />} /> */}
                        <Route path="/holi" element={<Holi data={data} sendData={sendData} />} />
                        <Route path="/mother-day" element={<Mother_day data={data} sendData={sendData} />} />

                        <Route path="/father-day" element={<Father_day data={data} sendData={sendData} />} />

                        <Route path="/friendship-day" element={<Friendship_day data={data} sendData={sendData} />} />

                        <Route path="/ai-generated-international-women-day-greeting-cards" element={<WomenDayNew data={data} sendData={sendData} />} />
                        <Route path="/women-day" element={<WomenDayNew data={data} sendData={sendData} />} />
                        <Route path="/water-day" element={<WaterDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-water-day-greeting-cards" element={<WaterDay data={data} sendData={sendData} />} />
                        <Route path="/earth-day" element={<EarthDay data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-earth-day-greeting-cards" element={<EarthDay data={data} sendData={sendData} />} />
                        <Route path="/maha-shivaratri" element={<MahaShivRatri data={data} sendData={sendData} />} />
                        <Route path="/easter-day" element={<Easterday data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-easter-day-greeting-cards" element={<Easterday data={data} sendData={sendData} />} />

                        <Route path="/eid" element={<Eid data={data} sendData={sendData} />} />
                        <Route path="/ram-navami" element={<RamNavami data={data} sendData={sendData} />} />

                        <Route path="/navratri" element={<Navratri data={data} sendData={sendData} />} />

                        {/* <Route path="/women-day-new" element={<WomenDayNew data={data} sendData={sendData} />} /> */}


                        <Route path="/birthday" element={<Birthday data={data} sendData={sendData} />} />
                        <Route path="/ai-generated-birthday-greeting-cards" element={<Birthday data={data} sendData={sendData} />} />

                        <Route path="/work-anniversary" element={<WorkAnniversary data={data} sendData={sendData} />} />
                        

                        <Route path="/wedding-anniversary" element={<WeddingAnniversary data={data} sendData={sendData} />} />

                        <Route path="/valentine-day" element={<ValentineDay data={data} sendData={sendData} />} />
                        

                        <Route path="/aicards" element={<Aicards data={data} sendData={sendData} />} />
                        <Route path="/2-click-ai-greeting-cards" element={<Aicards data={data} sendData={sendData} />} />
                        <Route path="/blog" element={<Blog data={data} sendData={sendData} />} />
                        <Route path="/blog/:blog_slug" element={<Blogdetails data={data} sendData={sendData} />} />
                        <Route path="/blog-preview/:blog_id" element={<BlogPreview data={data} sendData={sendData} />} />
                        <Route path="/blog/categories/:category_slug" element={<BlogCategory data={data} sendData={sendData} />} />
                        <Route path="/baby-born" element={<Babyborn data={data} sendData={sendData} />} />

                        <Route path="/business" element={<BusinessLandingPage data={data} sendData={sendData} />} />
                        <Route path="/quote" element={<QuoteLandingPage data={data} sendData={sendData} />} />
                        <Route path="/ai-quote-generator" element={<QuoteLandingPage data={data} sendData={sendData} />} />

                        <Route path="/poem" element={<PoemLandingPage data={data} sendData={sendData} />} />
                        <Route path="/ai-poem-generator" element={<PoemLandingPage data={data} sendData={sendData} />} />
                        <Route path="/sarcastic-letter" element={<SarcasticLandingPage data={data} sendData={sendData} />} />
                        {/* <Route path="/ai-generated-sarcastic-messages" element={<SarcasticLandingPage data={data} sendData={sendData} />} /> */}

                        <Route path="/joke" element={<JokeLandingPage data={data} sendData={sendData} />} />
                        <Route path="/ai-joke-generator" element={<JokeLandingPage data={data} sendData={sendData} />} />

                        <Route path="/ai-greeting-card-generator" element={<GreetingLandingPage data={data} sendData={sendData} />} />
                        <Route path="/collection-of-greetings" element={<PersonalPage data={data} sendData={sendData} />} />







                        <Route path="/varnz-greeting-card-design-studio" element={<InlineedittingNew sendData={sendData} />} />
                        <Route path="/share" element={<ShareImage sendData={sendData} />} />
                        <Route path="/forgot" element={<Forgot sendData={sendData} />} />
                        <Route path="/sitemap.xml" element={<Sitemap />} />

                        <Route element={<AuthGuard />}>
                          <Route
                            path="/createpassword"
                            element={<CreatePassword />}
                          />
                          <Route path="/otp" element={<Otp sendData={sendData} />} />
                          <Route
                            path="/Passwordupdate"
                            element={<Passwordupdate />}
                          />
                          <Route
                            path="/OtpForgotPassword"
                            element={<OtpForgotPassword sendData={sendData} />}
                          />
                        </Route>

                        <Route
                          path="/Sucesscompleteprofile"
                          element={<Sucesscompleteprofile />}
                        />
                      </Route>

                      <Route path="/home" element={<Homepage1
                        senData={senData1}
                        sendData={sendData}
                      />} />
                      <Route path="/" element={<Homepage1
                        senData={senData1}
                        sendData={sendData}
                      />} />


                      <Route element={<AuthenticatedRoute Routes />}>
                        <Route element={<Layout2 />}>
                          <Route
                            path="/Completeprofile"
                            element={<Completeprofile1 />}
                          />

                          {/* <Route path="/type" element={<UserType data={data} sendData={sendData} />} /> */}
                          <Route path="/type" element={<UserTypeNew data={data} sendData={sendData} />} />

                          {/* <Route path="/typenew" element={<UserTypeNew data={data} sendData={sendData} />} /> */}
                          <Route path="/multiform" element={<MultiStepForm data={data} sendData={sendData} />} />
                          <Route path="/settings" element={<BusinessProfile data={data} sendData={sendData} />} />
                          <Route path="/template-history" element={<SavedTemplates data={data} sendData={sendData} />} />
                          <Route path="/default-card" element={<DefaultTemplates data={data} sendData={sendData} />} />
                          <Route path="/SavedTemplateNew" element={<SavedTemplatesNew data={data} sendData={sendData} />} />

                        </Route>
                        <Route
                          element={
                            <Layout
                              data={NotificationNum}
                              ganderImage={ganderImage}
                            />
                          }
                        >
                          <Route
                            path="/chat"
                            element={<ChatComponent
                              sendData={sendData} chat="chat" />}
                          />
                          <Route path="/refer" element={<Invitation sendData={sendData} />} />
                          <Route path="/reminders" element={<Reminders sendData={sendData} />} />
                          <Route path="/friend" element={<Friend sendData={sendData} />} />
                          <Route
                            path="/profile"
                            element={<Profile genderwiseImage={genderwiseImage} />}
                          />
                          <Route
                            path="/notification"
                            element={
                              <Notification
                                onNotificationData={handleNotificationData}
                              />
                            }
                          />
                          <Route
                            path="/friendListMessage"
                            element={<FriendListMessage />}
                          />
                          <Route path="/customPrompt" element={<CustomPrompt />} />
                          <Route path="/templates" element={<Templates />} />

                          <Route path="/Favoritelist" element={<FavoriteList />} />
                          <Route path="/history" element={<PaymentHistory />} />
                          <Route path="/message-history" element={<MessageHistory />} />
                        </Route>
                      </Route>
                      <Route element={<Layout2 />}>
                        <Route path="*" element={<AIPage FressChatStore={1} />} />
                      </Route>
                    </Routes>
                  </Provider>
                </div>
              </div>
            </AuthContext.Provider>
          </Reduxx>
        </Suspense>
      </>
    );
  }
}
